import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import loader from '../../../../assets/images/loading-img.gif';
import CustomerDetailModal from '../../billing/standard/modals/other/CustomerDetailModal';
import { LuUser2 } from 'react-icons/lu';
import { FaIndianRupeeSign } from 'react-icons/fa6';
import { getOrderById, updateOrder } from '../../../../actions/order/OpenOrdersActions';
import { deepCopy } from '../../../../util/utility-helper';
import { MINI_BILL_CSS_FORMAT } from '../../../../components/reports/constants';
import CustomerReport from '../../../../components/reports/CustomerReport';

let isDataChangeFlag = false;
const SingleOrderDetailModal = ({ ...props }) => {
    const dispatch = useDispatch();

    const [singleOrderDetails, setSingleOrderDetail] = useState({});
    const [modalManage, setModalManage] = useState({ modalName: "", visible: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.showModal) {
            setLoading(true)
            getSingleOrderDetailById(props.selectedOrder);
        }
    }, [props.showModal, props.selectedOrder]);

    const getSingleOrderDetailById = async (orderDetail) => {
        if (orderDetail) {
            await dispatch(getOrderById(orderDetail.id))
                .then((response) => {
                    if (response.payload) {
                        let orderDetail = deepCopy(response.payload || {});
                        orderDetail.items && orderDetail.items.map(order => {
                            let itemPrice = order.price;
                            if (order.isPriceGstInc === 'Y') {
                                let itemGst = order.perGst ? (itemPrice * order.perGst / (100 + order.perGst)) : 0;
                                itemGst = itemGst.toFixed(2);
                                itemPrice = itemPrice - itemGst;
                            }
                            order.price = itemPrice;
                            return order;
                        })
                        setSingleOrderDetail({ ...orderDetail, subTotal: parseFloat(response.payload.orderTotal - response.payload.totalGst).toFixed(2) });
                    }
                    setLoading(false);
                })
        }
    };

    const handleModalManager = (modalName, isVisible) => {
        setModalManage({ ...modalName, modalName: modalName, visible: isVisible });
    };

    const printReport = () => {
        const miniBillCss = MINI_BILL_CSS_FORMAT;
        var divContents = document.getElementById("customerReport");
        let data = miniBillCss;
        var a = window.open('', '', 'height=500, width=500');
        if (a) {
            if (divContents) {
                a.document.write('<html>');
                a.document.write('<head>');
                a.document.write('<head>');
                a.document.write(data);
                a.document.write('</head>');
                a.document.write(divContents.innerHTML);
                a.document.write('</body></html>');
                a.document.close();
                a.print();
            }
        }
    };

    const onCustomerChange = async (newCustomerDetails) => {
        if (JSON.stringify(newCustomerDetails) !== JSON.stringify(singleOrderDetails.customer)) {
            setLoading(true);
            await dispatch(updateOrder({ id: singleOrderDetails.id, customer: newCustomerDetails }))
                .then(async (response) => {
                    if (response.payload && !response.payload.error) {
                        isDataChangeFlag = true
                        getSingleOrderDetailById(singleOrderDetails);
                        props.handleToastManager({ visible: true, toastMessage: "Customer Updated", variant: "Success" });
                    } else {
                        props.handleToastManager({ visible: true, toastMessage: "Error While Updaing Customer", variant: "Danger" });
                    }
                })
        }
    };


    return (
        <>
            <CustomerReport orderDetails={singleOrderDetails} />
            {modalManage.modalName === "customerDetailModal" && modalManage.visible === true ?
                <CustomerDetailModal showModal={modalManage.modalName === "customerDetailModal"} hideModal={() => handleModalManager("", false)} customerDetails={{ ...singleOrderDetails.customer || {}, orderDate: singleOrderDetails.orderDate }} changeCustomerDetails={onCustomerChange} />
                :
                <Modal backdrop="static" keyboard={false} className='modal-height' show={props.showModal} onHide={() => props.hideModal(isDataChangeFlag)} centered size='lg' scrollable>
                    <Modal.Header className='border-0 pb-0' closeButton>
                        <Row className='mx-0 g-3 w-100 align-items-center me-3'>
                            <Col md={12} lg={6} className='d-sm-flex d-md-flex flex-wrap d-block align-items-center pe-4'>
                                <Modal.Title className='fs-5 fw-bold me-auto text-warning'>{singleOrderDetails.invoiceNo ? <># {singleOrderDetails.invoiceNo}</> : null}</Modal.Title>
                            </Col>
                            <Col xs={6} md={6} lg={3}></Col>
                            <Col xs={6} md={6} lg={3}>
                                <Button className='light-btn w-100 text-truncate h-56 text-uppercase  d-flex align-items-center justify-content-center' variant="primary" onClick={() => handleModalManager('customerDetailModal', true)}>
                                    <LuUser2 className='fs-16 me-1' />Customer
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Header>
                    {!loading ?
                        <Modal.Body>
                            <Row className='g-3'>
                                <Col xs={12}>
                                    <Row className="g-2">
                                        <Col xs={6} sm={6} md={5} lg={3}>
                                            <Form.Label htmlFor="floatingInputCustom" className='d-flex align-items-center'>
                                                Customer Name<span className='ms-auto'>-</span>
                                            </Form.Label>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6}>
                                            <Form.Label htmlFor="floatingInputCustom">
                                                {singleOrderDetails?.customer?.name || "N/A"}
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                    <Row className="g-2">
                                        <Col xs={6} sm={6} md={5} lg={3}>
                                            <Form.Label htmlFor="floatingInputCustom" className='d-flex align-items-center'>
                                                Mobile<span className='ms-auto'>-</span>
                                            </Form.Label>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6}>
                                            <Form.Label htmlFor="floatingInputCustom">
                                                {singleOrderDetails?.customer?.mobileNumber || "N/A"}
                                            </Form.Label>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12}>
                                    <Table responsive className='inso-table'>
                                        <thead>
                                            <tr>
                                                <th className='text-start' width="60%">Item Name</th>
                                                <th className='text-center' width="20%">Qty</th>
                                                <th className='text-end' width="20%"><FaIndianRupeeSign /> Price</th>
                                                <th className='text-end' width="20%">S-total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {singleOrderDetails.items?.map((item, index) => (
                                                <tr key={index}>
                                                    <td className='text-start'>{item.prodAlias}</td>
                                                    <td className='text-center'>{item.qty}</td>
                                                    <td className='text-end'>{parseFloat(item.price).toFixed(2)}</td>
                                                    <td className='text-end'>{parseFloat(item.price * item.qty).toFixed(2)}</td>

                                                </tr>
                                            ))}
                                            <tr>
                                                <td className='text-end' colSpan='4'>
                                                    <Row className="g-0 ">
                                                        <Col sm={12} md={6} lg={4} className='ms-auto'>
                                                            <Row className="g-0 ">
                                                                <Col xs={6} className='text-start'>
                                                                    <span className='fs-14'>Sub-Total</span>
                                                                </Col>
                                                                <Col xs={6} className=' text-end'>
                                                                    <span className='fs-14'>{parseFloat(singleOrderDetails.subTotal).toFixed(2) || 0.00}</span>
                                                                </Col>
                                                            </Row>
                                                            <Row className="g-0 ">
                                                                <Col xs={6} className='text-start'>
                                                                    <span className='fs-14'>Tax</span>
                                                                </Col>
                                                                <Col xs={6} className=' text-end'>
                                                                    <span className='fs-14'>{parseFloat(singleOrderDetails.totalGst).toFixed(2) || 0.00}</span>
                                                                </Col>
                                                            </Row>
                                                            <Row className="g-0">
                                                                <Col xs={6} className='text-start'>
                                                                    <span className='fw-bold fs-16'>Total</span>
                                                                </Col>
                                                                <Col xs={6} className=' text-end'>
                                                                    <span className='fw-bold fs-16'>{parseFloat(singleOrderDetails.orderTotal).toFixed(2) || 0.00}</span>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Modal.Body >
                        : <div className="d-flex align-items-center justify-content-center" style={{ height: '400px' }} >
                            <img width='20%' src={loader} />
                        </div>}
                    <Modal.Footer className='border-0 pt-0'>
                        <Row className='g-3 w-100 justify-content-end'>
                            <Col xs={6} sm={6} md={3} lg={2}>
                                {singleOrderDetails.status !== 'CANCELLED' ?
                                    <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={printReport}>
                                        Print
                                    </Button> : null}
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal >

            }

        </>
    )
}

export default SingleOrderDetailModal;
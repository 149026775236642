import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { changeProductImage } from "../../../../actions/billdesk/BillDeskActions";
import { updateOrder } from "../../../../actions/order/OpenOrdersActions";
import proImg from "../../../../assets/images/upload.jpg"
import { BiPencil } from "react-icons/bi";
import { escapeStringRegexp } from "react-bootstrap-typeahead/types/utils";

const endPoint = process.env.REACT_APP_API_BASE_ENDPOINT;
const initalButtonState = { loading: false, disable: true }
const UploadTrackingNoModal = ({ ...props }) => {
    const dispatch = useDispatch();

    const [file, setFile] = useState({});
    const [buttonState, setButtonState] = useState({ ...initalButtonState });
    const [orderTrackUrl, setorderTrackUrl] = useState('');

    useEffect(() => {
        if (props.selectedOrder) {
            setButtonState({ ...initalButtonState })
            setorderTrackUrl(props.selectedOrder.orderTrackUrl || '')
            setFile({ ...file, fileSrc: props.selectedOrder.shipmentReceipt ? endPoint + props.selectedOrder.shipmentReceipt : null })
        } else {
            setorderTrackUrl('')
            setFile({})
        }
    }, [props.selectedOrder])

    const onFileChange = (event) => {
        setButtonState({ ...buttonState, disable: false })
        setFile({ ...file, fileSrc: URL.createObjectURL(event.target.files[0]), appendFile: event.target.files[0], })
    };

    const uploadTrackingNo = async () => {
        const formData = new FormData();
        formData.append('fileName', props.selectedOrder.id);
        formData.append('files', file.appendFile);
        setButtonState({ ...buttonState, loading: true });
        try {
            let resourcePath = null;
            if (file.appendFile) {
                resourcePath = await dispatch(changeProductImage(formData))
                    .then(async (response) => {
                        if ((response.payload && !response.payload.error)) {
                            return response.payload.resourcePath || null;
                        } else {
                            props.handleToastManager({ visible: true, toastMessage: "Error while uploading document", variant: "Danger" });
                        }
                    })

                await dispatch(updateOrder({ id: props.selectedOrder.id, shipmentReceipt: resourcePath, orderTrackUrl: orderTrackUrl || props.selectedOrder.orderTrackUrl }))
                    .then((orderUpdateRes) => {
                        if ((orderUpdateRes.payload && !orderUpdateRes.payload.error)) {
                            props.handleToastManager({ visible: true, toastMessage: "Document upload", variant: "Success" });
                            setTimeout(() => {
                                props.hideModal(true)
                            }, 500);
                        } else {
                            props.handleToastManager({ visible: true, toastMessage: "Failed to update order", variant: "Danger" });
                        }
                    })

            } else {
                await dispatch(updateOrder({ id: props.selectedOrder.id, orderTrackUrl: orderTrackUrl || "" }))
                    .then((orderUpdateRes) => {
                        if ((orderUpdateRes.payload && !orderUpdateRes.payload.error)) {
                            props.handleToastManager({ visible: true, toastMessage: "Document upload", variant: "Success" });
                            setTimeout(() => {
                                props.hideModal(true)
                            }, 500);
                        } else {
                            props.handleToastManager({ visible: true, toastMessage: "Failed to update order", variant: "Danger" });
                        }
                    })
            }



        } catch (err) {
        } finally {
            setButtonState({ ...initalButtonState })
        }
    };

    return (
        <>
            <Modal size="md" backdrop="static" keyboard={false} centered show={props.showModal} onHide={() => props.hideModal()}>
                <Modal.Header className='border-0 pb-0' closeButton>
                    <Modal.Title className='fs-5 fw-bold'>Upload Tracking No.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='g-3'>
                        <Col xs={12} >
                            <div className="pro-menu mx-auto mb-0" onClick={() => document.getElementById("uploadFile").click()}>
                                <div class="pro-overlay" >
                                    <BiPencil />
                                </div>
                                <img className="pro-image" src={file.fileSrc || proImg} alt="" />
                                <input type="file" name="file" accept=".jpg,.jpeg,.png" id="uploadFile" hidden={true} onChange={onFileChange}></input>
                            </div>
                        </Col>
                        <Col xs={12} >
                            <Form.Floating>
                                <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    name="orderTrackUrl"
                                    id="storeKey"
                                    value={orderTrackUrl}
                                    placeholder="Name"
                                    onChange={(event) => { setorderTrackUrl(event.target.value); setButtonState({ ...buttonState, disable: false }) }}
                                />
                                <Form.Label htmlFor="floatingInputCustom">Order Track Url</Form.Label>
                            </Form.Floating>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer className='border-0 pt-0'>
                    <Row className='g-3 w-100 justify-content-center'>
                        <Col md={3} lg={3} >
                            {!buttonState.loading ?
                                <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={uploadTrackingNo} disabled={buttonState.disable}>
                                    Save
                                </Button> :
                                <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        size="sm"
                                        animation="border"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>}
                        </Col>
                        <Col md={3} lg={3} >
                            <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={props.hideModal} >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal ></>
    )
}

export default UploadTrackingNoModal;
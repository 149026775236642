// WebSocketContext.js
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

const WebSocketContext = createContext();
const socketUrl = process.env.REACT_APP_SOCKET_ENDPOINT

export const useWebSocketContext = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }) => {
  const socketRef = useRef(null);
  const [message, setMessage] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  // Function to handle WebSocket connection
  const connectWebSocket = () => {
    socketRef.current = new WebSocket(socketUrl); // Replace with your server URL

    socketRef.current.onopen = () => {
      setIsConnected(true);
    };

    socketRef.current.onclose = () => {
      setIsConnected(false);
      setTimeout(() => {
        connectWebSocket(); // Retry connection after 1 second
      }, 1000);
    };

    socketRef.current.onmessage = (event) => {
      try {
        // Step 1: Parse the received string as JSON
        const parsedMessage = JSON.parse(event.data);
        // Step 2: Convert the `data` array back into a Uint8Array (for browser)
        // const uint8Array = new Uint8Array(parsedBuffer.data);

        // // Step 3: Decode the Uint8Array into a UTF-8 string
        // const textDecoder = new TextDecoder('utf-8');
        // const decodedString = textDecoder.decode(uint8Array);

        // // Step 4: Parse the decoded string into the final object
        // console.log(decodedString)
        // const finalObject = JSON.parse(decodedString);

        setMessage(parsedMessage); // Update the state with the parsed object
      } catch (err) {
        console.error('Failed to process WebSocket message:', err);
      }
    };


    socketRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  };

  // Reconnect WebSocket if the internet goes down
  useEffect(() => {
    if (!socketRef.current || socketRef.current.readyState === WebSocket.CLOSED) {
      connectWebSocket();
    }

    const handleOnline = () => {
      if (!socketRef.current || socketRef.current.readyState === WebSocket.CLOSED) {
        connectWebSocket();
      }
    };

    const handleOffline = () => {
      console.log('Internet connection lost');
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Send message to WebSocket server
  const sendMessage = (message) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(message));
    }
  };

  return (
    <WebSocketContext.Provider value={{ socketRef, message, sendMessage, isConnected }}>
      {children}
    </WebSocketContext.Provider>
  );
};
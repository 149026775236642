import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Dropdown, Form, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { FaPlus } from "react-icons/fa6";
import { TbEdit } from "react-icons/tb";
import { AiOutlineDelete } from "react-icons/ai";
import { LuSearch } from "react-icons/lu";
import { LiaUndoAltSolid } from "react-icons/lia";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { FiFilter } from "react-icons/fi";

const initialPageDetails = { currentPage: 1, pageSize: 10 };

const StorePane = ({ ...props }) => {
    const [storesToDisplay, setStoresToDisplay] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [pageDetails, setPageDetails] = useState({ ...initialPageDetails });
    const [storeSearch, setStoreSearch] = useState("");
    const [storeTypeFilter, setStoreTypeFilter] = useState("All"); // New state for store type filter

    useEffect(() => {
        if (props.clearSearch) {
            setStoreSearch('')
            setStoreList(props.storeList);
            handlePaginator(props.storeList, pageDetails.pageSize, pageDetails.currentPage);
        }
    }, [props.clearSearch,props.storeList]);

    useEffect(() => {
        setStoreList(props.storeList);
        handlePaginator(props.storeList, pageDetails.pageSize, pageDetails.currentPage);
    }, [props.storeList]);

    const handlePaginator = (storeList, pageSize, currentPage) => {
        const chunksRecord = [];
        storeList &&
            storeList.forEach((item) => {
                if (!chunksRecord.length || chunksRecord[chunksRecord.length - 1].length === pageSize)
                    chunksRecord.push([]);

                chunksRecord[chunksRecord.length - 1].push(item);
            });
        setStoresToDisplay(chunksRecord[currentPage - 1] === undefined ? [] : chunksRecord[currentPage - 1]);
    };

    const handlePagination = (pageType) => {
        if (pageType === "NEXT") {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage + 1 });
            handlePaginator(props.storeList, pageDetails.pageSize, pageDetails.currentPage + 1);
        } else {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage - 1 });
            handlePaginator(props.storeList, pageDetails.pageSize, pageDetails.currentPage - 1);
        }
    };

    const handlePageSize = (event) => {
        setPageDetails({ ...pageDetails, pageSize: parseInt(event.target.value) });
        handlePaginator(props.storeList, parseInt(event.target.value), pageDetails.currentPage);
    };

    const handleSearch = (event) => {
        setStoreSearch(event.target.value);
        filterStores(event.target.value, storeTypeFilter);
    };

    const handleStoreTypeFilter = (type) => {
        setStoreTypeFilter(type);
        filterStores(storeSearch, type);
    };

    const filterStores = (search, type) => {
        const filteredList = props.storeList.filter((store) => {
            const matchesSearch = store.name.toLowerCase().includes(search.toLowerCase());
            const matchesType = type === "All" || store.storeType === type;
            return matchesSearch && matchesType;
        });
        setStoreList(filteredList);
        handlePaginator(filteredList, pageDetails.pageSize, pageDetails.currentPage);
    };

    return (
        <Row className="g-3">
            <Col md={12}>
                <Row className="g-3">
                    <Col md={6}>
                        <Form.Floating className="search_bar">
                            <span className="input-group-text search_icon">
                                <LuSearch />
                            </span>
                            <Form.Control
                                className="search_input"
                                id="productSearch"
                                type="search"
                                autoComplete="off"
                                name="filterText"
                                placeholder="Search By Store Name"
                                value={storeSearch}
                                onChange={handleSearch}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Search By Store Name</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={3} lg={3} xl={2}>
                        <Button className="light-btn h-56 w-100" variant="primary" onClick={() => props.handleModalManager("addStoreModal", true)} disabled={storeList.length > 4}>
                            <FaPlus className="me-1 fs-16" />
                            Store
                        </Button>
                    </Col>
                    <Col md={3} lg={3} xl={2}>
                        <Dropdown>
                            <Dropdown.Toggle className="light-btn h-56 w-100" variant="primary" id="dropdown-basic">
                                <FiFilter className="me-1 fs-16" />
                                {storeTypeFilter === 'All' ? 'Store Type' : storeTypeFilter}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item active={storeTypeFilter === 'All'} onClick={() => handleStoreTypeFilter("All")}>All</Dropdown.Item>
                                <Dropdown.Item active={storeTypeFilter === 'ASSEMBLY'} key='ASSEMBLY' onClick={() => handleStoreTypeFilter('ASSEMBLY')}>ASSEMBLY</Dropdown.Item>
                                <Dropdown.Item active={storeTypeFilter === 'OFFLINE'} key='OFFLINE' onClick={() => handleStoreTypeFilter('OFFLINE')}>OFFLINE</Dropdown.Item>
                                <Dropdown.Item active={storeTypeFilter === 'ONLINE'} key='ONLINE' onClick={() => handleStoreTypeFilter('ONLINE')}>ONLINE</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </Col>
            <Col sm={12} md={12} lg={12} xxl={12}>
                <Table responsive className="inso-table">
                    <thead>
                        <tr>
                            <th className="text-start" width="20%">Name</th>
                            <th className="text-start" width="10%">Store Type</th>
                            <th className="text-center" width="10%">Address</th>
                            <th className="text-center" width="10%">Location</th>
                            <th className="text-center" width="10%">Status</th>
                            <th className="text-center" width="15%">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {storesToDisplay &&
                            storesToDisplay.map((item) => (
                                <tr key={item.id}>
                                    <td className="text-start" width="20%">
                                        {item.name}
                                    </td>
                                    <td className="text-start" width="10%">
                                        {item.storeType}
                                    </td>
                                    <td className="text-center" width="10%">
                                        {item.address}
                                    </td>
                                    <td className="text-center" width="10%">
                                        {item.location}
                                    </td>
                                    <td className="text-center" width="10%">
                                        {item.status === "DELETED" ? (
                                            <Button
                                                className="border-0 bg-transparent p-0 ms-3"
                                                onClick={() => {
                                                    props.handleModalManager("activeStoreConfirmModal", true);
                                                    props.setStoreDetails(item);
                                                }}
                                            >
                                                <LiaUndoAltSolid className="fs-16 text-dark " />
                                            </Button>
                                        ) : (
                                            <Badge bg="success">{item.status}</Badge>
                                        )}
                                    </td>
                                    <td className="text-center" width="10%">
                                        {item.status !== "DELETED" && (
                                            <>
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Edit Store</Tooltip>}>
                                                    <Button
                                                        className="border-0 bg-transparent p-0 ms-3"
                                                        onClick={() => {
                                                            props.handleModalManager("editStoreModal", true);
                                                            props.setStoreDetails(item);
                                                        }}
                                                    >
                                                        <TbEdit className="fs-16 text-dark " />
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Delete Store</Tooltip>}>
                                                    <Button
                                                        className="border-0 bg-transparent p-0 ms-3"
                                                        onClick={() => {
                                                            props.handleModalManager("deleteStoreConfirmModal", true);
                                                            props.setStoreDetails(item);
                                                        }}
                                                    >
                                                        <AiOutlineDelete className="fs-16 text-dark " />
                                                    </Button>
                                                </OverlayTrigger>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
                <Row className="g-0">
                    <Col md={12} className="table-pagination flex-wrap d-flex align-items-center justify-content-end">
                        <div className="d-flex align-items-center mb-3">
                            <span className="mx-3"> Items per page:</span>
                            <select className="form-select" aria-label="Floating label select example" onChange={handlePageSize}>
                                {props.pageSizeOptions.map((page, index) => (
                                    <option key={index} value={page.value}>
                                        {page.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                            <span className="mx-3">
                                {" "}
                                {pageDetails.currentPage +
                                    " - " +
                                    Math.ceil(storeList.length / pageDetails.pageSize) +
                                    " of " +
                                    storeList.length}
                            </span>
                            <Button
                                className="light-btn px-3"
                                variant="primary"
                                disabled={pageDetails.currentPage <= 1}
                                onClick={() => handlePagination("PREV")}
                            >
                                <IoChevronBackOutline className="fs-16" />
                            </Button>
                            <Button
                                className="light-btn px-3"
                                variant="primary"
                                disabled={pageDetails.currentPage * pageDetails.pageSize >= storeList.length}
                                onClick={() => handlePagination("NEXT")}
                            >
                                <IoChevronForwardOutline className="fs-16" />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default StorePane;

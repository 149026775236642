import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import loader from '../../../../../../assets/images/loading-img.gif';
import { BiPencil } from "react-icons/bi";
import { changeProductImage, deleteProduct, updateProduct } from "../../../../../../actions/billdesk/BillDeskActions";
import { getSelCategoryByStyle, validateFields } from "../../../../../../util/utility-helper";
import NumberField from "../../../../../../components/common/App/NumberField";
import proImg from '../../../../../../assets/images/upload.jpg';
import { uomData } from "../../../../../../util/constants";

const baseApiPoint = process.env.REACT_APP_API_BASE_ENDPOINT;
const initialState = {};
const initalButtonState = { loading: '', disable: 'save' };
const mandatoryFields = ['prodAlias', 'price', 'perGst', 'isPriceGstInc', 'uom'];

let isDataChangeFlag = false;

const EditProductModal = ({ ...props }) => {
    const dispatch = useDispatch();
    const [product, setProduct] = useState({ ...initialState });
    const [buttonState, setButtonState] = useState({ ...initalButtonState });
    const [subCategoryList, setSubCategoryList] = useState([]);
    const businessStoreData = useSelector((state) => state.billDesk.businessStoreData);

    useEffect(() => {
        if (props.showModal && props.currentSelProduct) {
            setButtonState({ ...initalButtonState });
            setProduct({ ...props.currentSelProduct, prodImg: props.currentSelProduct.icon ? baseApiPoint + props.currentSelProduct.icon : null });
        } else if (!props.showModal) {
            setSubCategoryList(props.subCategoryList)
            setProduct({ ...initialState });
        }
    }, [props.showModal, props.currentSelProduct]);

    useEffect(() => {
        if (props.subCategoryList) {
            setSubCategoryList(props.subCategoryList)
        }
    }, [props.subCategoryList])

    const openFileUploader = () => {
        document.getElementById("fileUploader")?.click();
    };

    const handleChange = (event) => {
        setProduct({ ...product, [event.target.name]: event.target.value });
        setButtonState({ ...buttonState, disable: validateFields(mandatoryFields, { ...product, [event.target.name]: event.target.value }) ? 'save' : '' })
        // setButtonState({ ...buttonState, disable: '' });
    };

    const handleCheckBox = (event) => {
        setProduct({ ...product, [event.target.name]: event.target.checked ? "Y" : "N" });
        setButtonState({ ...buttonState, disable: validateFields(mandatoryFields, { ...product, [event.target.name]: event.target.value }) ? 'save' : '' })
        // setButtonState({ ...buttonState, disable: '' });
    }

    const onMainCategoryChange = (event) => {
        // let updatedCategories = product.categories || [];
        // businessStoreData.forEach(parentCategory => {
        //     if (parseInt(parentCategory.id) === parseInt(event.target.value)) {
        //         if (parentCategory.categories && parentCategory.categories.length === 1) {
        //             if (!updatedCategories.find((cat) => cat.id === parentCategory.categories[0].id)) {
        //                 updatedCategories.push({ id: parentCategory.categories[0].id });
        //             }
        //         }
        //         setSubCategoryList(parentCategory.categories || []);
        //     }
        // });

        // setProduct({ ...product, categories: updatedCategories });
        // setButtonState({ ...buttonState, disable: '' });
        const selectedCatId = event.target.value;
        const selectedCat = businessStoreData.find(cat => parseInt(cat.id) === parseInt(selectedCatId));
        if (selectedCat) {
            setSubCategoryList(selectedCat.categories || []);
            if (Array.isArray(selectedCat.categories) && selectedCat.categories.length > 0) {

                setProduct(prevState => ({
                    ...prevState,
                    categories: [{ id: String(selectedCat.categories[0].id) }]
                }));
            }
            setButtonState({ ...buttonState, disable: '' });
        }
    };

    const onSubCategoryChange = (event) => {
        const categories = product.categories || [];
        if (!categories.find(e => parseInt(e.id) === parseInt(event.target.value))) {
            categories.push({ 'id': event.target.value });
            setProduct({ ...product, categories: categories });
            setButtonState({ ...buttonState, disable: '' });
        }
    };

    const onUpdateProduct = async () => {
        setButtonState({ ...buttonState, loading: 'save', disable: 'delete' });
        let productLcl = { ...product };
        if (productLcl.icon !== props.currentSelProduct.icon) {
            productLcl.icon = await uploadProductImg(productLcl);
        }
        try {

            await dispatch(updateProduct({ ...productLcl, image1: productLcl.icon ? 'Y' : 'N' }))
                .then((response) => {
                    if ((response.payload && !response.payload.error)) {
                        isDataChangeFlag = true;
                        props.handleToastManager({ visible: true, toastMessage: "Product Updated Successfully", variant: "Success" });
                    } else {
                        props.handleToastManager({ visible: true, toastMessage: "Error While Updating Product", variant: "Danger" });
                    }
                    setButtonState({ ...initalButtonState });
                })
        } catch (err) { }
    };

    const uploadProductImg = async (product) => {
        let resourcePath = null;
        const formData = new FormData()
        formData.append("files", product.icon);
        formData.append("fileName", product.id);
        try {
            await dispatch(changeProductImage(formData)).then(async (response) => {
                if ((response.payload && !response.payload.error)) {
                    resourcePath = response.payload.resourcePath;
                } else {
                    props.handleToastManager({ visible: true, toastMessage: "Upload Error", variant: "Danger" });
                }
            });
        } catch (err) { }
        return resourcePath;
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setProduct({ ...product, icon: file, prodImg: URL.createObjectURL(file) });
        setButtonState({ ...buttonState, disable: '' })
    };

    const onDeleteProduct = async () => {
        setButtonState({ ...buttonState, disable: 'save', loading: 'delete' });
        try {
            await dispatch(deleteProduct({ productId: product.id, categoryId: getSelCategoryByStyle(subCategoryList, 'bg-light-yellow')?.id }))
                .then((response) => {
                    if ((response.payload && !response.payload.error)) {
                        props.hideModal(true)
                        props.handleToastManager({ visible: true, toastMessage: "Product Deleted Successfully", variant: "Success" });
                    } else {
                        props.handleToastManager({ visible: true, toastMessage: "Error While Deleting Product", variant: "Danger" });
                    }
                    setButtonState({ ...initialState });
                })
        } catch (err) { }
    };

    return (
        <Modal size="lg" centered backdrop="static" keyboard={false} show={props.showModal} onHide={() => props.hideModal(isDataChangeFlag)}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Edit Product</Modal.Title>
            </Modal.Header>
            {buttonState !== 'main' ?
                <>
                    <Modal.Body>
                        <Row className="g-3">
                            <Col md={12} lg={3}>
                                <div className="pro-main mx-auto mx-lg-2" >
                                    <div className="pro-menu" onClick={openFileUploader}>
                                        <div className="pro-overlay">
                                            <BiPencil className='pro-icon' />
                                        </div>
                                        <img className="pro-image" src={product.prodImg || proImg} />
                                        <input type="file" name="files" accept=".jpg,.jpeg,.png" id="fileUploader" hidden={true} onChange={handleFileChange} />
                                    </div>
                                </div>
                            </Col>
                            <Col md={12} lg={9}>
                                <Row className="g-3">
                                    <Col md={6}>
                                        <Form.Floating className="">
                                            <Form.Control type="text" autoComplete='off' name="prodAlias" id="floatingInputCustom" placeholder="Product Name" value={product.prodAlias} defaultValue={product.prodAlias} onChange={handleChange} />
                                            <Form.Label htmlFor="floatingInputCustom">Product Name<span className="text-danger">*</span></Form.Label>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Floating className="">
                                            <NumberField autoComplete='off' name="price" id="floatingInputCustom" placeholder="Product Price" value={product.price} defaultValue={product.price} onChange={handleChange} decimals={2} />
                                            <Form.Label htmlFor="floatingInputCustom">Product Price<span className="text-danger">*</span></Form.Label>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={6} className="d-flex align-items-end">
                                        <Form.Floating className="w-100">
                                            <Form.Control type="text" autoComplete='off' name="hsn" id="floatingInputCustom" placeholder="Product Name" value={product.hsn} defaultValue={product.hsn} onChange={handleChange} />
                                            <Form.Label htmlFor="floatingInputCustom">HSN Code</Form.Label>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Check className="" type="switch" checked={product.isPriceGstInc === "Y"} name="isPriceGstInc" id="custom-switch" label="is GST Included?" onChange={handleCheckBox} />
                                        <Form.Floating className="">
                                            <NumberField autoComplete='off' name="perGst" id="floatingInputCustom" placeholder="Product Name" value={product.perGst} defaultValue={product.perGst} onChange={handleChange} />
                                            <Form.Label htmlFor="floatingInputCustom">GST<span className="text-danger">*</span></Form.Label>
                                        </Form.Floating>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6} className="">
                                <Form.Floating className="">
                                    <Form.Select aria-label="Category Select" name="uom" value={product.uom} onChange={handleChange}>
                                        <option selected disabled>UOM</option>
                                        {uomData.map((uom, indx) => (
                                            <option selected={uom.value === product.uom} key={indx} value={uom.value} >
                                                {uom.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Label htmlFor="floatingInputCustom">UOM<span className="text-danger">*</span></Form.Label>
                                </Form.Floating>
                            </Col>
                            {/* <Col md={6}>
                                <Form.Floating className="">
                                    <Form.Select  aria-label="Floating label select example" name="tags" onChange={handleChange}>
                                        <option disabled hidden={!product ? true : false} selected>Select Tag</option> :
                                        <option selected={product.tags !== undefined ? product.tags === "VEG" ? true : false : false} value="VEG" >Veg</option> :
                                        <option selected={product.tags !== undefined ? product.tags === "NONVEG" ? true : false : false} value="NONVEG">Non-Veg</option> :
                                    </Form.Select>
                                    <Form.Label htmlFor="floatingInputCustom">Select Tags</Form.Label>
                                </Form.Floating>
                            </Col> */}
                            <Col md={12}>
                                <h5 className="fs-6 mb-0 mt-3">Move Product to another category</h5>
                            </Col>
                            <Col md={6}>
                                <Form.Floating controlId="floatingSelect">
                                    <Form.Select aria-label="Floating label select example" name="mainCat" onChange={onMainCategoryChange}>
                                        <option disabled>Select Category</option>
                                        {businessStoreData && Array.isArray(businessStoreData) && businessStoreData.map((category) => {
                                            return (
                                                <option value={category.id} selected={props.selectedCategory ? category.id === props.selectedCategory.id ? true : false : false} >{category.catAlias}</option>
                                            )
                                        })}
                                    </Form.Select>
                                    <Form.Label htmlFor="floatingInputCustom">Select Category<span className="text-danger">*</span></Form.Label>
                                </Form.Floating>
                            </Col>
                            <Col md={6}>
                                <Form.Floating controlId="floatingSelect">
                                    <Form.Select aria-label="Floating label select example" name="subCat" onChange={onSubCategoryChange}>
                                        <option disabled>Select Sub Category</option>
                                        {subCategoryList.map((category) => {
                                            return (
                                                <option value={category.id} selected={category.id === getSelCategoryByStyle(subCategoryList, 'bg-light-yellow')?.id}>{category.catAlias}</option>
                                            )
                                        })}
                                    </Form.Select>
                                    <Form.Label htmlFor="floatingInputCustom">Select Sub Category<span className="text-danger">*</span></Form.Label>
                                </Form.Floating>
                            </Col>
                            <Col md={6}>
                                <Form.Floating className="">
                                    <NumberField autoComplete='off' name="displayOrder" id="floatingInputCustom" placeholder="Product Name" value={product.displayOrder} defaultValue={product.displayOrder} onChange={handleChange} />
                                    <Form.Label htmlFor="floatingInputCustom">Display Order</Form.Label>
                                </Form.Floating>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className='border-0 pt-0'>
                        <Row className='g-3 w-100 justify-content-end'>
                            <Col md={3} lg={2} >
                                {buttonState.loading !== 'save' ?
                                    <Button disabled={buttonState.disable === 'save'} className='light-btn w-100 bg-light-grey' variant="primary" name="Save" onClick={onUpdateProduct}>Save</Button>
                                    : <Button className='light-btn w-100 bg-light-grey' variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            size="sm"
                                            animation="border"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>}
                            </Col>
                            <Col md={3} lg={2} >
                                {buttonState.loading !== 'delete' ?
                                    <Button disabled={buttonState.disable === 'delete'} className='light-btn w-100 bg-light-grey' variant="primary" name="Delete" onClick={onDeleteProduct}>Delete</Button> :
                                    <Button className='light-btn w-100 bg-light-grey' variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            size="sm"
                                            animation="border"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>}
                            </Col>
                        </Row>
                    </Modal.Footer>
                </>
                :
                <div className="d-flex align-items-center justify-content-center" style={{ height: '400px' }} >
                    <img width='20%' src={loader} />
                </div>}
        </Modal >
    )
};

export default EditProductModal;
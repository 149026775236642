import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FloatingLabel,
  Card,
} from "react-bootstrap";
import BackFlash from "../common/App/BackFlash";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createSession, invalidateSession, isAuthenticated } from "../../config/appSession";
import { userLogin } from "../../actions/signIn/SignInActions";
import { getValueFromToken } from "../../util/utility-helper";
import terminalImg from "../../assets/images/payment-terminal.png";
import ShopImg from "../../assets/images/shop.png"
import ToastManager from "../common/App/NotificationManager";
import { fetchAllTerminals } from "../../actions/admin/AdminActions";
import { useWebSocketContext } from "../../util/WebSocketContext";

let isDataChangeFlag = false;
const UserAccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userRole = localStorage.getItem('USER_ROLE');
  const { sendMessage } = useWebSocketContext();

  const userAssignedStoreNdTerminal = localStorage.getItem('assignedStoreAndTerminals') ? JSON.parse(localStorage.getItem('assignedStoreAndTerminals')) : []

  const [step, setStep] = useState(1);
  const [inputDetails, setInputDetails] = useState({ storeId: getValueFromToken('storeId'), terminalId: getValueFromToken('terminalId') });
  const [terminals, setTerminals] = useState([]);
  const [changeDetailsFlag, setChangeDetailsFlag] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [defaultTerminalList, setDefaultTerminalList] = useState([]);

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/user/login');
    } else {
      if (userRole !== 'BUSINESS_ADMIN') {
        if (!localStorage.getItem('userCredentials')) {
          navigate('/home');
        }
      } else {
        navigate('/');
      }
    }
  }, [userRole]);

  useEffect(() => {
    setInputDetails({ ...inputDetails, storeId: getValueFromToken('storeId') || '', terminalId: getValueFromToken('terminalId') })
    if (getValueFromToken('storeId') && getValueFromToken('terminalId')) {
      setChangeDetailsFlag(false);
    }
    dispatch(fetchAllTerminals()).then((response) => {
      if (response.payload) {
        setDefaultTerminalList(response.payload)
      }
    })
  }, ['']);


  const handleChange = (event) => {
    isDataChangeFlag = true;
    setInputDetails({ ...inputDetails, [event.target.name]: event.target.value })
  };

  const handleNext = async () => {
    setStep(step + 1);
    const selectedStore = userAssignedStoreNdTerminal.find(e => String(e.id) === String(inputDetails.storeId));
    if (selectedStore) {
      setTerminals(selectedStore.terminals);

      // set the 1st terminal as the selected terminal by default
      if (selectedStore.terminals) {
        isDataChangeFlag = true;
        setInputDetails({ ...inputDetails, terminalId: selectedStore.terminals[0].id });
      }

    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleSubmit = async () => {

    if (isDataChangeFlag) {
      try {
        const userCredentials = localStorage.getItem('userCredentials') ? JSON.parse(localStorage.getItem('userCredentials')) : null;
        if (userCredentials) {
          await dispatch(userLogin({ ...userCredentials, ...inputDetails })).then((response) => {
            if (response.payload && response.payload.statusCode === 200) {
              userAssignedStoreNdTerminal.forEach(storeAndTerminal => {
                if (storeAndTerminal && storeAndTerminal.storeType === 'ONLINE') {
                  sendMessage(JSON.stringify(storeAndTerminal.storeKey))
                }
              });
              createSession(response.payload);
              localStorage.removeItem("userCredentials");
              navigate('/home');
            }
          })
        }
      } catch (err) {

      }
    } else {
      navigate('/user/login');
    }
  };

  const moveToHome = () => {
    if (getValueFromToken('storeId') && getValueFromToken('terminalId')) {
      navigate('/home');
    } else {
      setShowToast(true);
      setTimeout(() => {
        invalidateSession();
      }, 1000);
    }
  }

  return (
    <>
      <ToastManager toastManager={{ visible: showToast, variant: "Danger", toastMessage: "User not assign to the terminal. Please contact with admin." }} setToastManager={() => setShowToast(false)} />
      <Container fluid className="master-login">
        <Row className="g-0">
          <Col sm={6} lg={4} className="master-content position-relative">
            <div className="p-4">
              <Row className="g-0 py-3 text-center">
                <Col sm={12} lg={12}>
                  <h1>
                    <span className="text-warning">
                      <strong>Easy</strong>
                    </span>
                    opz
                  </h1>
                </Col>
              </Row>
              {changeDetailsFlag === true ?
                <Form >
                  {step === 1 && (
                    <Row className="g-0">
                      <Col sm={12} lg={12}>
                        <p className="lead">Please select your Store </p>
                      </Col>
                      <Col sm={12} lg={12}>
                        <Form.Group controlId="formStep1">
                          <FloatingLabel
                            controlId="floatingSelect"
                            label="Select the store"
                          >
                            <Form.Select aria-label="Floating label select example" name="storeId" onChange={handleChange} >
                              <option value="" disabled>Select Store</option>
                              {userAssignedStoreNdTerminal && userAssignedStoreNdTerminal.map((store, idx) => {
                                return (
                                  <option key={idx} selected={String(store.id) === String(inputDetails.storeId)} value={store.id} >{store.name + " (" + store.storeType + ")"}</option>
                                )
                              })}
                            </Form.Select>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  {step === 2 && (
                    <Row className="g-0">
                      <Col sm={12} lg={12}>
                        <p className="lead">Please select your Terminal </p>
                      </Col>
                      <Col sm={12} lg={12}>
                        <Form.Group controlId="formStep3">
                          <FloatingLabel
                            controlId="floatingSelect"
                            label="Choose the Terminal"
                          >
                            <Form.Select aria-label="Floating label select example" name="terminalId" onChange={handleChange} >
                              <option value="" disabled>Select Terminal</option>
                              {terminals && terminals.map((terminal, idx) => {
                                return (
                                  <option key={idx} value={terminal.id} selected={String(terminal.id) === String(inputDetails.terminalId)}>{terminal.name}</option>
                                )
                              })}

                            </Form.Select>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  <Row className="g-0 my-5">
                    <Col sm={6} lg={6} xs={6} className=" d-flex justify-content-start">
                      {step > 1 && (
                        <Button className="light-btn w-50 bg-light-blue border-0" variant="primary" onClick={handlePrevious}>
                          Previous
                        </Button>
                      )}
                    </Col>

                    <Col sm={6} lg={6} xs={6} className=" d-flex justify-content-end">
                      {step < 2 ? (
                        <Button className="light-btn w-50 bg-light-yellow border-0" variant="primary" onClick={handleNext} disabled={inputDetails.storeId === ''}>
                          Next
                        </Button>
                      ) : (
                        <Button
                          className="light-btn w-50 bg-light-yellow border-0"
                          variant="primary"
                          onClick={handleSubmit}
                          disabled={inputDetails.terminalId === ''}
                        >
                          Continue
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <div
                    className="position-absolute w-100 start-0 bottom-0 mt-3"
                    style={{ height: "9px" }}
                  >
                    {/* <ProgressBar variant="warning" now={(step / 4) * 100} /> */}
                  </div>
                </Form>
                :
                <>
                  <Row className="g-3 py-3 text-center">
                    <Col sm={6} lg={6} xs={6}>
                      <Card style={{ borderRadius: '10px' }}>
                        <Card.Img className="w-50 p-3 mt-2 m-auto" variant="top" src={ShopImg} />
                        <Card.Body>
                          <Card.Title>
                            <p>(Default Store)</p>
                            <strong>{userAssignedStoreNdTerminal.find(e => String(e.id) === String(getValueFromToken('storeId')))?.name}</strong>
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={6} lg={6} xs={6}>
                      <Card style={{ borderRadius: '10px' }}>
                        <Card.Img
                          className="w-50 p-3 mt-2 m-auto"
                          variant="top"
                          src={terminalImg}
                        />
                        <Card.Body>
                          <Card.Title>
                            <p>(Default Terminal)</p>
                            <strong>{defaultTerminalList.find(e => String(e.id) === String(getValueFromToken('terminalId')))?.name}</strong>
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="py-3">
                    <Col md={6} xs={7}>
                      <Button className="light-btn w-100 bg-light-blue border-0" variant="primary" onClick={() => setChangeDetailsFlag(true)} >  Change</Button>
                    </Col>
                    <Col md={6} xs={5}>
                      <Form >
                        <Button
                          className="light-btn w-100 bg-light-yellow border-0"
                          variant="primary"
                          onClick={moveToHome}
                        >
                          Continue
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </>}
            </div>
          </Col>
        </Row>
      </Container>
      <BackFlash />
    </>
  );
};

export default UserAccess;

import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createSubCategory } from "../../../../../../actions/billdesk/BillDeskActions";
import { getSelCategoryByStyle } from "../../../../../../util/utility-helper";
import NumberField from "../../../../../../components/common/App/NumberField";

let isDataChangeFlag = false;
const initialState = { catAlias: "", catType: "FINISHED", icon: "", displayOrder: "", status: "ACTIVE", syncStatus: "N", parentCategoryId: "" };

const AddSubCategoryModal = ({ ...props }) => {
    const dispatch = useDispatch()
    const [category, setCategory] = useState({ ...initialState });
    const [buttonState, setButtonState] = useState('');

    useEffect(() => {
        if (props.showModal) {
            const selMainCategory = getSelCategoryByStyle(props.categoryList, 'bg-light-yellow');
            if (selMainCategory) {
                setCategory({ ...initialState, parentCategoryId: selMainCategory.id });
            }
        }
    }, [props.showModal, props.categoryList])

    const createNewSubCategory = async () => {
        setButtonState('loading');
        try {
            await dispatch(createSubCategory({ ...category }))
                .then((response) => {
                    if (response.payload && !response.payload.error) {
                        props.hideModal(true);
                        props.handleToastManager({ visible: true, toastMessage: "Sub Category created successfully", variant: 'Success' });
                    } else {
                        // Display the error message from the response
                        const errorMessage = response.payload?.error || "Error While Adding Sub-Category";
                        props.handleToastManager({ visible: true, toastMessage: errorMessage, variant: 'Danger' });
                    }
                    setButtonState('')
                })
        } catch (err) { }
    };

    const handleChange = (event) => {
        setCategory({ ...category, [event.target.name]: event.target.value });
    };

    return (
        <Modal size="md" backdrop="static" keyboard={false} centered show={props.showModal} onHide={() => props.hideModal(isDataChangeFlag)}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Add Sub Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col md={12}>
                        <Form.Floating className="">
                            <Form.Control type="text" autoComplete='off' name="catAlias" id="floatingInputCustom" placeholder="Product Name" value={category.catAlias} onChange={handleChange} />
                            <Form.Label htmlFor="floatingInputCustom">Sub Category Name<span className="text-danger">*</span></Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={12}>
                        <Form.Floating className="">
                            <NumberField autoComplete='off' name="displayOrder" id="floatingInputCustom" placeholder="Product Name" value={category.displayOrder} onChange={handleChange} />
                            <Form.Label htmlFor="floatingInputCustom">Display Order</Form.Label>
                        </Form.Floating>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-0 pt-0'>
                <Row className='g-2 w-100 justify-content-end'>
                    <Col md={3} lg={3} >
                        {buttonState !== 'loading' ?
                            <Button disabled={category.catAlias.length === 0} className='light-btn w-100 bg-light-grey' variant="primary" name="Save" onClick={createNewSubCategory}>Save</Button> :
                            <Button className='light-btn w-100 bg-light-grey' variant="primary" disabled>
                                <Spinner as="span" size="sm" animation="border" role="status" aria-hidden="true" />
                            </Button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    )
}

export default AddSubCategoryModal;
import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { createSubCategory } from "../../../../../actions/billdesk/BillDeskActions";
import { useDispatch } from "react-redux";
import NumberField from "../../../../../components/common/App/NumberField";
import { validateFields } from "../../../../../util/utility-helper";

const intialState = { catAlias: "", catType: '', icon: "", displayOrder: "", status: 'ACTIVE', syncStatus: 'N', parentCategoryId: "", }
const mandatoryFields = ['catAlias', 'parentCategoryId'];
const initalButtonState = { disable: true, loading: false }

const AddSubCategory = ({ showModal, hideModal, handleToastManager, selectedCategory, productList, selCatType }) => {
    const dispatch = useDispatch();

    const [currentSubCategory, setCurrentSubCategory] = useState({});

    const [buttonState, setButtonState] = useState({ ...initalButtonState });

    useEffect(() => {
        if (showModal) {
            setButtonState({ ...initalButtonState })
            setCurrentSubCategory({ ...intialState, catType: selCatType, parentCategoryId: selectedCategory?.id });
        }
    }, [showModal, selectedCategory, selCatType]);

    const onHandleChange = (event) => {
        const { name, value } = event.target;
        setCurrentSubCategory(prevState => ({
            ...prevState,
            [name]: value
        }));
        setButtonState({ ...buttonState, disable: validateFields(mandatoryFields, { ...currentSubCategory, [name]: value }) });
    };


    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            setButtonState({ ...buttonState, loading: true });
            await dispatch(createSubCategory(currentSubCategory))
                .then((response) => {
                    if ((response.payload && !response.payload.error)) {
                        handleToastManager({ visible: true, toastMessage: 'Sub Category created successfully', variant: "Success" });
                        setButtonState({ ...buttonState, loading: false });
                        setTimeout(() => {
                            hideModal(true);
                        }, 1000)
                    } else {
                        // Display the error message from the response
                        const errorMessage = response.payload?.error || "Error While Adding Sub-Category";
                        handleToastManager({ visible: true, toastMessage: errorMessage, variant: 'Danger' });
                    }
                })
        } catch (error) {

        } finally {
            setButtonState({ ...buttonState, loading: false });
        }
    };

    return (
        <Modal size="md" centered backdrop="static" keyboard={false} show={showModal} onHide={hideModal}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Add Sub Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col md={12}>
                        <Form.Floating>
                            <Form.Select
                                aria-label="Floating label select example"
                                name="parentCategoryId"
                                value={currentSubCategory.parentCategoryId || ""}
                                onChange={onHandleChange}
                            >
                                <option value="" selected disabled>Select Category</option>
                                {productList.map(st => (
                                    <option key={st.id} value={st.id}>
                                        {st.catAlias}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Label htmlFor="floatingSelect">Category Name<span className="text-danger">*</span></Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={12}>
                        <Form.Floating className="">
                            <Form.Control
                                type="text"
                                autoComplete='off'
                                name="catAlias"
                                id="floatingInputCustom"
                                placeholder="Sub Category Name"
                                value={currentSubCategory.catAlias}
                                onChange={onHandleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Sub Category Name<span className="text-danger">*</span></Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={12} className="">
                        <Form.Floating className="">
                            <NumberField
                                autoComplete='off'
                                name="displayOrder"
                                id="floatingInputCustom"
                                placeholder="Display Order"
                                value={currentSubCategory.displayOrder}
                                onChange={onHandleChange}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Display Order</Form.Label>
                        </Form.Floating>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-0 pt-0'>
                <Row className='g-3 w-100 justify-content-end'>
                    <Col md={3} lg={3}>
                        {!buttonState.loading ?
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={onSubmit} disabled={buttonState.disable}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}

export default AddSubCategory;

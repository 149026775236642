import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Card, Image, Form, OverlayTrigger, Tooltip, Popover, Collapse } from 'react-bootstrap';
import loadingIcon from '../../../../assets/images/loading-img.gif';
import dine from './../../../../assets/images/dineblack.png';
import pick from './../../../../assets/images/packingblack.png';
import delivery from './../../../../assets/images/deliveryblack.png';
import drive from './../../../../assets/images/drive-thru.png';
import moment from 'moment';
import OrderTypeModal from './modals/other/OrderTypeModal';
import PaymentTypeModal from './modals/other/PaymentTypeModal';
import CustomerDetailModal from './modals/other/CustomerDetailModal';
import AddCategoryModal from './modals/category/AddCategoryModal';
import AddSubCategoryModal from './modals/category/AddSubCategoryModal';
import AddProductModal from './modals/product/AddProductModal';
import EditCategoryModal from './modals/category/EditCategoryModal';
import EditSubCategoryModal from './modals/category/EditSubCategoryModal';
import Cart from './widgets/OrderCart';
import SubCategory from './widgets/SubCategoryPane';
import EditProductModal from './modals/product/EditProductModal';
import { LuSearch, LuUser2 } from 'react-icons/lu';
import { FaIndianRupeeSign, FaPlus } from 'react-icons/fa6';
import { IoChevronBack, IoChevronDown, IoChevronForward, IoChevronUp } from 'react-icons/io5';
import { findStoreId, isAuthenticated } from '../../../../config/appSession';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createOrder, getGroupStoreData } from '../../../../actions/billdesk/BillDeskActions';
import { addDelay, deepCopy, getProductsInCategories, getSelCategoryByStyle } from '../../../../util/utility-helper';
import plusIcon from '../../../../assets/images/plus.png';
import ProductCardPane from './widgets/ProductCardPane';
import CategoryPane from './widgets/CategoryPane';
import ExpandCategoryPane from './widgets/ExpandCategoryPane';
import Loader from '../../../../components/common/App/Loader';
import ToastManager from '../../../../components/common/App/NotificationManager';
import ManageQuantityModal from './modals/other/ManageQuantityModal';
import { addNewOrderWithToken, refreshNewOrderProcess } from '../../../../slice/BillDeskSlice';
import KitchenReport from '../../../../components/reports/KitchenReports';
import { MINI_BILL_CSS_FORMAT } from '../../../../components/reports/constants';
import TokenOrderDetailModal from '../../open-orders/modals/TokenOrderDetailModal';
import SingleOrderDetailModal from '../../open-orders/modals/SingleOrderDetailModal';

let selectedProduct = null;
let selectedItem = null;
let isOrderConfirmed = false;
let isOrderConfirmedWithPrint = false;
let selectedOrderType = '';
let inProgressOrder = {};
let allProducts = [];
let currentPlacedOrder = null

const storeStateCode = "03";
const initialOrderState = {
  id: null, tempInvoiceNo: null, amountPaid: 0, deliveryStatus: null, orderStage: "NEW", syncStatus: "N",
  tags: "", paymentStatus: "UNPAID", grandTotal: 0, deliveryMethod: "", paymentMethod: "CASH",
  orderDate: moment().format('YYYY-MM-DD HH:mm:ss'), orderType: null, orderTotal: 0, status: "OPEN",
  totalGst: 0, subTotal: 0, customer: { name: "CASH", location: "NA", mobileNumber: "", stateCode: storeStateCode, syncStatus: "N" },
  items: [], discountType: null, terminal: "", totalItems: 0, comments: ''
};
const viewPageSize = { mainCategory: 10, product: 8, subCategory: 10 }
const initalePaginationState = { main: { pageNumber: 1, pageCount: 1 }, sub: { pageNumber: 1, pageCount: 1 } };
const initialToastState = { visible: false, toastMessage: '', variant: "" };
const initialModalMangerState = { modalName: "", visible: false };
const initalOverlayTriggerState = { message: "", type: "" };
const initialProductSearchState = { searchText: "", productCode: "" };

const BillDesk = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /** redux stores */
  const businessStoreData = useSelector((state) => state.billDesk.businessStoreData);
  const enableCrudOperation = useSelector((state) => state.billDesk.enableCrudOperation);
  const newOrderWIthToken = useSelector((state) => state.billDesk.newOrderWithToken);
  const refreshOrder = useSelector((state) => state.billDesk.addNewOrder);

  /** component states */
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [currentOrder, setCurrentOrder] = useState({ ...initialOrderState });
  const [expandCategoryView, setExpandCategoryView] = useState(false);
  const [modalManger, setModalManger] = useState({ ...initialModalMangerState });
  const [loaderState, setLoaderState] = useState('');
  const [pagination, setPagination] = useState({ ...initalePaginationState });
  const [toastMessage, setToastMessage] = useState({ ...initialToastState });
  const [overlayTrigger, setOverlayTrigger] = useState({ ...initalOverlayTriggerState });
  const [productSearch, setProductSearch] = useState({ ...initialProductSearchState });

  useEffect(() => {
    checkAuthentication();
  }, []);

  useEffect(() => {
    if (newOrderWIthToken) {
      setCurrentOrder({ ...initialOrderState, ...newOrderWIthToken, items: [], customer: newOrderWIthToken?.customer || initialOrderState.customer });
      dispatch(addNewOrderWithToken(null));
    }
  }, [newOrderWIthToken]);

  useEffect(() => {
    if (refreshOrder) {
      setLoaderState('main');
      setTimeout(() => {
        setLoaderState('');
        setCurrentOrder({ ...initialOrderState, items: [] });
        dispatch(refreshNewOrderProcess(false));
      }, 500);
    }
  }, [refreshOrder]);

  /** 
   * check authentication and get store data 
  */
  const checkAuthentication = async () => {
    if (isAuthenticated()) {
      await dispatch(refreshNewOrderProcess(true))
      getStoreData();
    } else {
      navigate('/user/login');
    }
  };

  /** 
   * function for get store data by using store id 
  */
  const getStoreData = async () => {
    await dispatch(getGroupStoreData({ storeId: findStoreId(), filterType: 'FINISHED' })).then((resp) => {
      if (resp.payload && !resp.payload.error) {
        const storeData = resp.payload || [];
        allProducts = getProductsInCategories(resp.payload);
        const selCategory = getSelCategoryByStyle(categoryList, 'bg-light-yellow');
        if (expandCategoryView) {
          createExpandCategoryLayout(storeData, storeData.filter(e => e.id === selCategory?.id).length > 0 ? selCategory : null);
        } else {
          let lclPagination = { ...pagination };
          if (lclPagination.main.pageNumber > Math.ceil(storeData.length / viewPageSize.mainCategory)) {
            lclPagination.main.pageNumber = Math.ceil(storeData.length / viewPageSize.mainCategory);
          }
          createCategoryLayout(storeData, storeData.filter(e => e.id === selCategory?.id).length > 0 ? selCategory : null, lclPagination);
        }
      } else {
        createCategoryLayout([], [], initalePaginationState);
      }
    });
  };

  /**
   * format the category list and change the color of the button 
   * @param {Array} storeData 
   * @param {object} selCategory 
   * @param {object} pagination 
   */
  const createCategoryLayout = (storeData, selCategory, pagination) => {
    const data = deepCopy(storeData);
    const transformedArray = [];
    let batch = [];
    let batchSize = 2;
    let isFirstIterate = false;
    for (let i = 0; i < data.length; i++) {
      if (i >= (pagination.main.pageNumber - 1) * viewPageSize.mainCategory && i < pagination.main.pageNumber * viewPageSize.mainCategory) {
        if (selCategory) {
          if (data.length > 0 && data[i].id === selCategory?.id) {
            data[i].className = 'bg-light-yellow';
          }
        } else {
          if (data && data.length > 0) {
            if (!isFirstIterate) {
              data[i].className = 'bg-light-yellow';
              isFirstIterate = true;
            }
          }
        }
        batch.push(data[i]);
        if (batch.length === batchSize) {
          transformedArray.push(batch);
          batch = [];
          batchSize = batchSize === 2 ? 3 : 2;
        }
      }
    }
    if (batch.length > 0) {
      transformedArray.push(batch);
    }
    setCategoryList(transformedArray);
    setLoaderState('');
    if (transformedArray && transformedArray.length > 0) {
      let mainCategory = selCategory || transformedArray[0][0];
      if (mainCategory) {
        mainCategory = storeData.find(e => e.id === mainCategory.id);
        if (mainCategory) {
          const prevSelSubCatgegory = getSelCategoryByStyle(subCategoryList, 'bg-light-yellow');
          let selSubCategory = null;
          if (prevSelSubCatgegory) {
            if (mainCategory.categories) {
              selSubCategory = mainCategory.categories.find(e => e.id === prevSelSubCatgegory.id) || null;
            }
          }
          pagination = { ...pagination, sub: { ...pagination.sub, pageNumber: 1 } };
          initalizePageCount(pagination, storeData, mainCategory.categories || []);
          createSubCategoryLayout(mainCategory, selSubCategory, pagination);
        }
      }
    } else {
      createSubCategoryLayout([], [], pagination);
      initalizePageCount(pagination, [], []);
    }
  };

  /**
   *  format sub-category-list and change the color of the button 
   * @param {object} selCategory 
   * @param {object} selSubCategory 
   * @param {object} pagination
   */
  const createSubCategoryLayout = (selCategory, selSubCategory, pagination) => {
    if (selCategory) {
      let selectMainCategory = deepCopy(selCategory);
      if (selectMainCategory.categories && selectMainCategory.categories.length > 0) {
        let isFirstIterate = false;
        const subCatStore = [];
        for (let i = 0; i < selectMainCategory.categories.length; i++) {
          if (i >= (pagination.sub.pageNumber - 1) * viewPageSize.subCategory && i < pagination.sub.pageNumber * viewPageSize.subCategory) {
            if (selSubCategory) {
              if (selectMainCategory.categories.length > 0 && selectMainCategory.categories[i].id === selSubCategory?.id) {
                selectMainCategory.categories[i].className = 'bg-light-yellow';
              }
            } else {
              if (!isFirstIterate) {
                selectMainCategory.categories[i].className = 'bg-light-yellow';
                isFirstIterate = true;
              }
            }
            subCatStore.push(selectMainCategory.categories[i]);
          }
        }
        setSubCategoryList(subCatStore);
        createProductLayout(subCatStore, getSelCategoryByStyle(subCatStore, 'bg-light-yellow'));
      } else {
        setSubCategoryList([]);
        createProductLayout([], null);
      }
    }
  };

  /** function for expand and normal category layout */
  const onOpenExpandButton = () => {
    const selCategory = getSelCategoryByStyle(categoryList, 'bg-light-yellow');
    if (!expandCategoryView) {
      createExpandCategoryLayout(businessStoreData, selCategory);
    } else {
      for (let i = 0; i < businessStoreData.length; i++) {
        if (businessStoreData[i].id === selCategory?.id) {
          let pageNumber = 1;
          let pageSize = viewPageSize.mainCategory;
          for (let j = 0; j < businessStoreData.length; j++) {
            if (j === pageSize) {
              break;
            } else if (j !== i) {
              if (j + 1 === pageSize) {
                pageSize = pageSize + viewPageSize.mainCategory;
                pageNumber = pageNumber + 1;
              }
            } else {
              break;
            }
          }
          createCategoryLayout(businessStoreData, selCategory, { ...pagination, main: { ...pagination.main, pageNumber: pageNumber } });
        }
      }
    };
    setExpandCategoryView(!expandCategoryView);
  };


  /**
   * format product list by page size
   * @param {Array} subCategories 
   * @param {object} selSubCategory 
   */
  const createProductLayout = (subCategories, selSubCategory) => {
    if (subCategories && subCategories.length > 0) {
      const foundProductsIndex = subCategories.findIndex(e => e.id === selSubCategory?.id);
      if (foundProductsIndex > -1) {
        if (selSubCategory.products) {
          const localProducts = [...selSubCategory.products];
          for (var i = 0; i < viewPageSize.product - selSubCategory.products.length % viewPageSize.product; ++i) {
            localProducts.push(null);
          }
          setProductList(localProducts);
        } else {
          setProductList([]);
        }
      }
    } else {
      setProductList([]);
    }
  };

  /**
   * format category list by expanded page size
   * @param {Array} storeData 
   * @param {object} selCategory 
   */
  const createExpandCategoryLayout = (storeData, selCategory) => {
    const categories = deepCopy(storeData);
    const arrangedCategories = [];
    if (selCategory) {
      const foundCategoryIndex = categories.findIndex(e => e.id === selCategory.id);
      if (foundCategoryIndex > -1) {
        categories[foundCategoryIndex].className = "bg-light-yellow";
      }
    } else {
      if (categories && categories.length > 0) {
        categories[0].className = 'bg-light-yellow';
      }
    }
    for (let i = 0; i < categories.length; i += 2) {
      const subList = [];
      subList.push(deepCopy(categories[i]));
      if (i + 1 < categories.length) {
        subList.push(categories[i + 1]);
      }
      arrangedCategories.push(subList);
    }
    setLoaderState('');
    setCategoryList(arrangedCategories);
    if (categories && categories.length > 0) {
      const prevSelSubCatgegory = getSelCategoryByStyle(subCategoryList, 'bg-light-yellow');
      let selSubCategory = null;
      const foundCategoryIndex = categories.findIndex(e => e.id === selCategory.id);
      if (prevSelSubCatgegory) {
        selSubCategory = categories[foundCategoryIndex > -1 ? foundCategoryIndex : 0].categories.find(e => e.id === prevSelSubCatgegory.id);
      }
      createSubCategoryLayout(categories[foundCategoryIndex > -1 ? foundCategoryIndex : 0], selSubCategory, pagination);
    }
  };

  const handleProductSearch = async (event) => {
    if (event.code === "Enter") {
      //show loading or processing
      setLoaderState('sub');
      if (productSearch.searchText) {
        const newProductList = [];
        await Promise.all(allProducts.map(products => {
          if (String(products.prodAlias).toLocaleLowerCase().includes(String(productSearch.searchText).toLocaleLowerCase())) {
            newProductList.push(products);
          }
          return products
        })).then(() => {
          setProductList(newProductList);
          setTimeout(() => {
            setLoaderState('');
          }, 1000);
        })
      } else {
        createProductLayout(subCategoryList, getSelCategoryByStyle(subCategoryList, 'bg-light-yellow'));
        setTimeout(() => {
          setLoaderState('');
        }, 1000);
      }
    }
  };

  const onSearchProductByCode = (event) => {
    if (event.code === "Enter") {
      //show loading or processing
      setLoaderState('sub')
      if (productSearch.productCode) {
        const prodArr = allProducts;
        prodArr && prodArr.forEach(prod => {
          if (String(prod.barcode) === String(productSearch.productCode) || String(prod.shortCode) === String(productSearch.productCode)) {
            addToCart(prod);
          }
        });
        setProductSearch({ ...productSearch, productCode: "" });
        setTimeout(() => {
          setLoaderState('');
        }, 1000);
      } else {
        createProductLayout(subCategoryList, getSelCategoryByStyle(subCategoryList, 'bg-light-yellow'));
        setTimeout(() => {
          setLoaderState('');
        }, 1000);
      }
    }
  };

  /**
   * works on when user click on sub category button and check if crudOperation is (on) then the edit sub-category modal is open
   * @param {object} selSubCategory 
   */
  const performSubCatgoryAction = (selSubCategory) => {
    if (selSubCategory) {
      setProductSearch({ ...initialProductSearchState });
      const selMainCategory = getSelCategoryByStyle(categoryList, 'bg-light-yellow');
      if (selMainCategory) {
        createSubCategoryLayout(selMainCategory, selSubCategory, pagination);
        if (enableCrudOperation) {
          handleModalManager('editSubCategory', true);
        }
      }
    }
  };

  /**
   * works on when user click on category button and check if crudOperation is (on) then the edit category modal is open
   * @param {object} category 
   */
  const performCategoryAction = (category) => {
    if (category) {
      setProductSearch({ ...initialProductSearchState })
      if (expandCategoryView) {
        createExpandCategoryLayout(businessStoreData, category);
      } else {
        createCategoryLayout(businessStoreData, category, { ...pagination, sub: { ...pagination.sub, pageNumber: 1 } });
      }
      if (enableCrudOperation) {
        handleModalManager('editCategory', true);
      }
    }
  };

  /**
   * works on when user click on product card and check if crudOperation is (on) then the edit product modal is open
   * @param {object} selProduct 
   */
  const performProductAction = (selProduct) => {
    if (enableCrudOperation) {
      selectedProduct = selProduct;
      handleModalManager('editProductModal', true);
    } else {
      addToCart(selProduct);
    }
  };

  /**
   * addToCart function for add items and calculate total amount of selected items
   * @param {object} currentProduct 
   */
  const addToCart = (currentProduct) => {
    let cartItems = currentOrder.items;
    if (currentProduct) {
      const foundIndex = cartItems.findIndex(e => e.product.id === currentProduct.id);
      if (foundIndex > -1) {
        let subTotal = 0;
        let gstAmount = 0;
        if (currentProduct.isPriceGstInc === 'Y') {
          const itemGst = cartItems[foundIndex].perGst ? (cartItems[foundIndex].unitPrice * cartItems[foundIndex].perGst / (100)).toFixed(2) : 0;
          subTotal = parseFloat((cartItems[foundIndex].unitPrice - itemGst) * (cartItems[foundIndex].qty + 1)).toFixed(2);
          gstAmount = itemGst * (cartItems[foundIndex].qty + 1);
        } else {
          const itemGst = currentProduct.perGst ? (currentProduct.price * currentProduct.perGst / 100).toFixed(2) : 0;
          subTotal = parseFloat(cartItems[foundIndex].unitPrice * (cartItems[foundIndex].qty + 1)).toFixed(2);
          gstAmount = parseFloat(itemGst * (cartItems[foundIndex].qty + 1)).toFixed(2);
        }
        cartItems[foundIndex] = { ...cartItems[foundIndex], qty: cartItems[foundIndex].qty + 1, subTotal: subTotal, gstAmount: gstAmount };
      } else {
        let subTotal = 0;
        let gstAmount = 0;
        if (currentProduct.isPriceGstInc === 'Y') {
          const itemGst = currentProduct.perGst ? (currentProduct.price * currentProduct.perGst / (100)).toFixed(2) : 0;
          subTotal = parseFloat(currentProduct.price - itemGst).toFixed(2);
          gstAmount = parseFloat(itemGst).toFixed(2);
        } else {
          const itemGst = currentProduct.perGst ? (currentProduct.price * currentProduct.perGst / 100).toFixed(2) : 0;
          subTotal = parseFloat(currentProduct.price).toFixed(2);
          gstAmount = parseFloat(itemGst).toFixed(2);
        }
        const newItem = {
          product: { id: currentProduct.id }, name: currentProduct.prodAlias, qty: 1,
          subTotal: subTotal, gstAmount: gstAmount, price: currentProduct.price, unitPrice: currentProduct.price,
          perGst: currentProduct.perGst, hsn: currentProduct.hsn, uom: currentProduct.uom,
          itemNumber: currentProduct.itemNumber, discount: 0, isPriceGstInc: currentProduct.isPriceGstInc
        };
        cartItems.splice(0, 0, newItem);
      }
      calculateOrderTotal({ ...currentOrder, items: cartItems });
    }
  };

  /**
   * function for increment & decrement the qty manually
   * @param {object} cartItem 
   * @param {string} adjustmentType 
   */
  const adjustItemQtyManualy = (cartItem, adjustmentType) => {
    let cartItems = currentOrder.items;
    let orderTotal = 0;
    if (cartItems) {
      cartItems.map((item, idx) => {
        if (cartItem.product.id === item.product.id) {
          if (adjustmentType === 'PLUS') {
            item.qty = item.qty + 1;
            item = calculateSubTotalAndTax(cartItem, item);
          } else if (adjustmentType === 'MINUS') {
            if (item.qty > 1) {
              item.qty = item.qty - 1;
              item = calculateSubTotalAndTax(cartItem, item);
            } else {
              cartItems.splice(idx, 1);
            }
          }
        }
        orderTotal = orderTotal + item.subtotal;
        return item;
      })
    }
    calculateOrderTotal({ ...currentOrder, items: cartItems });
  };

  /**
   * calcualte total amount and gst of current orders in item cart
   * @param {Object} order 
   */
  const calculateOrderTotal = (order) => {
    let calculatedTotal = { orderTotal: 0, totalGst: 0, subTotal: 0, totalItems: 0 };
    order.items && order.items.forEach(orderItem => {
      calculatedTotal = {
        ...calculatedTotal, subTotal: parseFloat(calculatedTotal.subTotal) + parseFloat(orderItem.subTotal), totalGst: parseFloat(calculatedTotal.totalGst) + parseFloat(orderItem.gstAmount),
        orderTotal: ((parseFloat(orderItem.subTotal) + parseFloat(orderItem.gstAmount)) + parseFloat(calculatedTotal.orderTotal)).toFixed(2), totalItems: orderItem.qty + calculatedTotal.totalItems
      };
    });
    setCurrentOrder({ ...order, ...calculatedTotal });
  };

  /**
   * pagination for category layout
   * @param {string} pageType 
   */
  const handleMainCategoryPagination = (pageType) => {
    if (pageType === "NEXT") {
      createCategoryLayout(businessStoreData, null, { ...pagination, sub: { ...pagination.sub, pageNumber: 1 }, main: { ...pagination.main, pageNumber: pagination.main.pageCount > pagination.main.pageNumber ? pagination.main.pageNumber + 1 : pagination.main.pageNumber } });
    } else {
      createCategoryLayout(businessStoreData, null, { ...pagination, sub: { ...pagination.sub, pageNumber: 1 }, main: { ...pagination.main, pageNumber: pagination.main.pageNumber > 1 ? pagination.main.pageNumber - 1 : pagination.main.pageNumber } });
    }
  };

  /**
   * pagination for sub-category layout
   * @param {string} pageType 
   */
  const handleSubCategoryPagination = (pageType) => {
    if (pageType === "NEXT") {
      const selMainCategory = getSelCategoryByStyle(categoryList, 'bg-light-yellow');
      createSubCategoryLayout(selMainCategory, null, { ...pagination, sub: { ...pagination.sub, pageNumber: pagination.sub.pageCount > pagination.sub.pageNumber ? pagination.sub.pageNumber + 1 : pagination.sub.pageNumber } });
      setPagination({ ...pagination, sub: { ...pagination.sub, pageNumber: pagination.sub.pageCount > pagination.sub.pageNumber ? pagination.sub.pageNumber + 1 : pagination.sub.pageNumber } });
    } else {
      const selMainCategory = getSelCategoryByStyle(categoryList, 'bg-light-yellow');
      createSubCategoryLayout(selMainCategory, null, { ...pagination, sub: { ...pagination.sub, pageNumber: pagination.sub.pageNumber - 1 } });
      setPagination({ ...pagination, sub: { ...pagination.sub, pageNumber: pagination.sub.pageNumber - 1 } });
    }
  }

  /**
   * define page count with store data records
   * @param {object} pagination 
   * @param {Array} mainCategories 
   * @param {Array} subCategories 
   */
  const initalizePageCount = (pagination, mainCategories, subCategories) => {
    setPagination({
      ...pagination,
      main: { ...pagination.main, pageCount: Math.ceil(mainCategories.length / viewPageSize.mainCategory) },
      sub: { ...pagination.sub, pageCount: Math.ceil(subCategories.length / viewPageSize.subCategory) }
    });
  };

  /**
   * common function for manage modals
   * @param {string} modalName 
   * @param {boolean} isVisble 
   */
  const handleModalManager = (modalName, isVisble) => {
    setModalManger({ ...modalManger, modalName: modalName, visible: isVisble });
  };

  /**
   * common function for toast manage
   * @param {object} toastStatus 
   */
  const handleToastManager = (toastStatus) => {
    setToastMessage({ ...toastMessage, ...toastStatus });
  };

  /**
   * function for close modal and fetch store data
   * @param {boolean} isDataChange 
   */
  const onHideModals = (isDataChange) => {
    if (isDataChange) {
      setLoaderState('sub');
      getStoreData();
    }
    handleModalManager('', false);
  };

  /**
   * calculate subtotal and tax of item when adding to the cart
   * @param {object} cartItem 
   * @param {object} productItm 
   * @returns 
   */
  const calculateSubTotalAndTax = (cartItem, productItm) => {
    if (cartItem.isPriceGstInc === 'Y') {
      const itemGst = cartItem.perGst ? (cartItem.price * cartItem.perGst / 100).toFixed(2) : 0;
      productItm.subTotal = parseFloat((cartItem.unitPrice - itemGst) * cartItem.qty).toFixed(2);
      productItm.gstAmount = parseFloat(itemGst * cartItem.qty).toFixed(2);
    } else {
      const itemGst = cartItem.perGst ? (cartItem.unitPrice * cartItem.perGst / 100).toFixed(2) : 0;
      productItm.subTotal = parseFloat(cartItem.unitPrice * cartItem.qty).toFixed(2);
      productItm.gstAmount = parseFloat(itemGst * cartItem.qty).toFixed(2);
    }
    return productItm;
  };

  /**
   * function for place order without report and check if order type selected then payment type modal is open otherwise order type modal is open
   */
  const onPlaceOrder = () => {
    if (currentOrder.items && currentOrder.items.length > 0) {
      isOrderConfirmed = true;
      isOrderConfirmedWithPrint = false;
      if (currentOrder.deliveryMethod === "") {
        handleModalManager("orderTypeModal", true);
      } else {
        if (currentOrder.deliveryMethod === 'DELIVERY') {
          if (!currentOrder.deliveryAddress) {
            setOverlayTrigger({ ...overlayTrigger, message: "Delivery Address need to add while order type is delivery.", type: "placeOrder" });
          } else {
            handleModalManager("paymentTypeModal", true);
          }
        } else {
          handleModalManager("paymentTypeModal", true);
        }
      }
    } else {
      setOverlayTrigger({ ...overlayTrigger, message: "Please add atleast one item to cart before Save.", type: "placeOrder" });
    }
    setTimeout(() => {
      setOverlayTrigger({ ...initalOverlayTriggerState });
    }, 3000);
  };

  /**
  * function for place order with report and check if order type selected then payment type modal is open otherwise order type modal is open
  */
  const onPlaceOrderWithReport = () => {
    if (currentOrder.items && currentOrder.items.length > 0) {
      isOrderConfirmedWithPrint = true;
      if (currentOrder.deliveryMethod === "") {
        handleModalManager("orderTypeModal", true);
      } else {
        if (currentOrder.deliveryMethod === "DELIVERY") {
          if (!currentOrder.deliveryAddress) {
            setOverlayTrigger({ ...overlayTrigger, message: "Delivery Address need to add while order type is delivery.", type: "placeOrderOrPrint" });
          }
        } else {
          handleModalManager("paymentTypeModal", true);
        }
      }
    } else {
      setOverlayTrigger({ ...overlayTrigger, message: "Please add atleast one item to cart before Save.", type: "placeOrderOrPrint" });
    }
    setTimeout(() => {
      setOverlayTrigger({ ...initalOverlayTriggerState });
    }, 3000);
  };

  /**
   * when user select the payment details so the create order api call
   * @param {object} orderDetails 
   */
  const onPaymentProcessDone = async (orderDetails) => {
    if (orderDetails) {
      setLoaderState('sub');
      await dispatch(createOrder({ ...orderDetails, terminal: JSON.parse(localStorage.getItem('currentTerminal') || {}).id }))
        .then((response) => {
          if ((response.payload && !response.payload.error)) {
            inProgressOrder = response.payload.deliveryMethod !== 'DINE' ? { ...response.payload, items: orderDetails.items, customer: orderDetails.customer } : [{ ...response.payload, items: orderDetails.items, customer: orderDetails.customer }];
            if (isOrderConfirmedWithPrint) {
              addDelay(500).then(() => {
                printReport()
              })
            }
            setCurrentOrder({ ...initialOrderState, items: [] });
            handleToastManager({ visible: true, toastMessage: 'Order has been created successfully', variant: "Success" })
            currentPlacedOrder = { ...response.payload, tag: orderDetails.deliveryMethod === 'DINE' ? orderDetails.tags : undefined };
            if (response.payload.paymentStatus === 'PAID') {
              setTimeout(() => {

                if (selectedOrderType === 'DINE') {
                  handleModalManager("tokenOrderDetailModal", true);
                } else {
                  handleModalManager("singleOrderDetailModal", true);
                }
              }, 500);
            }
          } else {
            handleToastManager({ visible: true, toastMessage: 'Error While Creating Record.', variant: "Danger" })
          }
          setTimeout(() => {
            setLoaderState('');
          }, 500);
        })
    }
  };

  /**
   * manullay qty change function
   * @param {object} orderDetail 
   */
  const onManualQtyChange = (orderDetail) => {
    let items = deepCopy(currentOrder.items);
    if (items.length !== 0) {
      if (parseInt(orderDetail.qty || 0) !== 0) {
        items.map(item => {
          if (item.name === orderDetail.name) {
            item.qty = parseInt(orderDetail.qty);
            item = calculateSubTotalAndTax(orderDetail, item);
          }
          return item;
        });
        calculateOrderTotal({ ...currentOrder, items });
      } else {
        const index = items.findIndex(itm => itm.name === selectedItem.name);
        if (index !== -1) {
          items.splice(index, 1);
        }
        calculateOrderTotal({ ...currentOrder, items });
      }
    }
  };

  const onOrderTypeChange = () => {
    setLoaderState('sub')
    setTimeout(() => {
      handleModalManager('', false);
      setLoaderState('');
    }, 500);
  };

  const printReport = () => {
    try {
      var divContents = null;
      divContents = document.getElementById("kitchenReport").innerHTML;
      let data = MINI_BILL_CSS_FORMAT;
      var a = window.open('', '', 'height=500, width=500');
      a.document.write('<html>');
      a.document.write('<head>');
      a.document.write('<head>');
      a.document.write(data);
      a.document.write('</head>');
      a.document.write(divContents);
      a.document.write('</body></html>');
      a.document.close();
      a.print();
    } catch (err) { }
  };

  /**
   * define modal props.
   */
  const editProductModalProps = { showModal: (modalManger.modalName === 'editProductModal' && modalManger.visible === true), hideModal: (isDataChange) => onHideModals(isDataChange), handleToastManager: handleToastManager, subCategoryList: subCategoryList, currentSelProduct: selectedProduct, selectedCategory: getSelCategoryByStyle(categoryList, 'bg-light-yellow') }

  const addProductModalProps = { showModal: (modalManger.modalName === "addProductModal" && modalManger.visible === true), hideModal: (isDataChange) => onHideModals(isDataChange), subCategoryList: subCategoryList, handleToastManager: handleToastManager };

  const addSubCategoryModalProps = { showModal: (modalManger.modalName === 'createSubCategory' && modalManger.visible === true), hideModal: (isDataChange) => onHideModals(isDataChange), categoryList: categoryList, handleToastManager: handleToastManager };

  const addCategoryModalProps = { showModal: (modalManger.modalName === 'createCategory' && modalManger.visible === true), hideModal: (isDataChange) => onHideModals(isDataChange), handleToastManager: handleToastManager };

  const editCategoryModalProps = { showModal: (modalManger.modalName === 'editCategory' && modalManger.visible === true), hideModal: (isChangeFlag) => { onHideModals(isChangeFlag); selectedProduct = false }, categoryList: categoryList, handleToastManager: handleToastManager };

  const editSubCategoryModalProps = { showModal: (modalManger.modalName === 'editSubCategory' && modalManger.visible === true), hideModal: (isDataChange) => onHideModals(isDataChange), handleToastManager: handleToastManager, currentMainCategory: getSelCategoryByStyle(categoryList, 'bg-light-yellow') || {}, currentSubCategory: getSelCategoryByStyle(subCategoryList, 'bg-light-yellow') || {} };

  const qtyManualChangeModalProps = { showModal: (modalManger.modalName === 'qtyChangeModal' && modalManger.visible === true), hideModal: () => handleModalManager('', false), selectedItem: selectedItem, changeQtyManually: onManualQtyChange };

  const customerDetailModalProps = { showModal: (modalManger.modalName === 'customerDetailModal' && modalManger.visible === true), hideModal: () => handleModalManager('', false), customerDetails: { ...currentOrder.customer, orderDate: currentOrder.orderDate }, changeCustomerDetails: (customerDetails) => setCurrentOrder({ ...currentOrder, customer: customerDetails }) };

  const orderTypeModalProps = { showModal: (modalManger.modalName === 'orderTypeModal' && modalManger.visible === true), hideModal: () => handleModalManager('', false), orderDetails: currentOrder, changeOrderDetails: (orderDetails) => { setCurrentOrder(orderDetails); selectedOrderType = orderDetails.deliveryMethod; isOrderConfirmed || isOrderConfirmedWithPrint ? handleModalManager('paymentTypeModal', true) : handleModalManager('', false) } };

  const paymentTypeModalProps = { showModal: (modalManger.modalName === "paymentTypeModal" && modalManger.visible === true), hideModal: () => handleModalManager('', false), orderDetails: currentOrder, onPaymentDone: (orderDetails) => onPaymentProcessDone(orderDetails), allowPartialPayment: true };

  const kitchenReportsProps = { orderType: selectedOrderType, orderDetails: inProgressOrder, tokenNo: inProgressOrder && Array.isArray(inProgressOrder) && inProgressOrder[0]?.tags };

  const tokenOrderDetailModalProps = { showModal: (modalManger.modalName === 'tokenOrderDetailModal' && modalManger.visible === true), hideModal: onHideModals, selectedOrder: currentPlacedOrder, onOrderTypeChange: onOrderTypeChange, handleToastManager: setToastMessage, };

  const singleOrderDetailModalProps = { showModal: (modalManger.modalName === 'singleOrderDetailModal' && modalManger.visible === true), hideModal: onHideModals, selectedOrder: currentPlacedOrder, onOrderTypeChange: onOrderTypeChange, handleToastManager: setToastMessage };


  const popover = (
    <Popover className='notificationPopover' id="popover-basic">
      <Popover.Body>
        <Row>
          <Col md={12} className=''>
            <span> {overlayTrigger.message}</span>
          </Col>
        </Row>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {
        loaderState !== 'main' ?
          <>
            <ToastManager toastManager={toastMessage} setToastManager={setToastMessage} />
            <Row className="p-wrap-box mx-0 g-0 ">
              <Col md={9} className={`position-relative ${loaderState === 'sub' ? "pe-none" : ""}`}>
                <Loader loaderState={loaderState === 'sub'} />
                <Row className='g-0'>
                  <Col xs={2} sm={3} md={3} lg={2} className=''>
                    <Card className='leftProduct-div rounded-0 border-0 shadow-none'>
                      <Card.Body className='p-3 leftProduct-div-empty'>
                        <Row className="g-3">
                          <SubCategory subCatList={subCategoryList} handleSubCategoryClick={performSubCatgoryAction} pageSize={viewPageSize.subCategory} />
                        </Row>
                      </Card.Body>
                      <Card.Footer className='p-3 bg-transparent'>
                        <Row className="g-0">
                          <Col md={12}>
                            <Row className='g-3'>
                              <Col md={6} className="">
                                <OverlayTrigger placement='top' overlay={<Tooltip>Down <IoChevronDown className='fs-16' /></Tooltip>}>
                                  <Button disabled={pagination.sub.pageCount === 0 || pagination.sub.pageNumber === 1} className="light-btn w-100 text-truncate h-56" variant="primary" onClick={() => handleSubCategoryPagination('PREV')}><IoChevronDown className='fs-16' /></Button>
                                </OverlayTrigger>
                              </Col>
                              <Col md={6} className="">
                                <OverlayTrigger placement='top' overlay={<Tooltip>Up <IoChevronUp className='fs-16' /></Tooltip>}>
                                  <Button disabled={(pagination.sub.pageCount === 0 || (pagination.sub.pageNumber === pagination.sub.pageCount))} className="light-btn w-100 text-truncate h-56" variant="primary" onClick={() => handleSubCategoryPagination('NEXT')}><IoChevronUp className='fs-16' /></Button>
                                </OverlayTrigger>
                              </Col>
                              <Col md={12}>
                                <OverlayTrigger placement='top' overlay={<Tooltip><FaPlus className='fs-16' /> Sub Category</Tooltip>}>
                                  <Button disabled={!enableCrudOperation} className='light-btn w-100 text-truncate h-56' variant="primary" onClick={() => handleModalManager('createSubCategory', true)} ><FaPlus className='fs-16' /> Sub Category</Button>
                                </OverlayTrigger>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </Card>
                  </Col>
                  <Col xs={10} sm={9} md={9} lg={10} className=''>
                    <Card className='Product-div rounded-0 border-top-0 border-bottom-0 shadow-none'>
                      <Card.Body className='p-3 Product-div-empty'>
                        <Row className="g-0 mx-0" >
                          <Col md={12} className='mb-3'>
                            <Row className="g-3" >
                              <Col md={5} className=''>
                                <Form.Floating className="search_bar">
                                  <span class="input-group-text search_icon" ><LuSearch /></span>
                                  <Form.Control className="search_input" id="productSearch" type="text" autoComplete='off' name="searchText" placeholder="CASH" value={productSearch.searchText} onChange={(event) => setProductSearch({ ...productSearch, [event.target.name]: event.target.value })} onKeyDown={handleProductSearch} />
                                  <Form.Label htmlFor="floatingInputCustom">Search Product</Form.Label>
                                </Form.Floating>
                              </Col>
                              <Col md={5} className=''>
                                <Form.Floating className="search_bar">
                                  <span class="input-group-text search_icon" ><LuSearch /></span>
                                  <Form.Control className="search_input" id="Code Search" type="text" autoComplete='off' name="productCode" placeholder="CASH" value={productSearch.productCode} onChange={(event) => setProductSearch({ ...productSearch, [event.target.name]: event.target.value })} onKeyDown={onSearchProductByCode} />
                                  <Form.Label htmlFor="floatingInputCustom">Search By Bar Code / Short Code</Form.Label>
                                </Form.Floating>
                              </Col>
                              <Col md={2}>
                                <OverlayTrigger placement='top' overlay={<Tooltip><FaPlus className='fs-16' /> New Product</Tooltip>}>
                                  <Button disabled={!enableCrudOperation} className='light-btn w-100 text-truncate h-56' variant="primary" onClick={() => handleModalManager('addProductModal', true)}>
                                    <FaPlus className='fs-16' /> New Product
                                  </Button>
                                </OverlayTrigger>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12}>
                            <div className="inner-p">
                              <ProductCardPane productList={productList} handleItemClick={performProductAction} isLoading={loaderState} />
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer className='p-3 pt-0 Product-div-cat'>
                        <Row className="g-0">
                          <Col md={12}>
                            <Button className='expand_btn' onClick={onOpenExpandButton} aria-controls="example-collapse-text"
                              aria-expanded={expandCategoryView}>
                              {expandCategoryView === true ? <IoChevronDown className='fs-5' /> : <IoChevronUp className='fs-5' />}
                            </Button>
                            <Row className="g-3">
                              {expandCategoryView !== true ?
                                <Col md={12}>
                                  <Row className="g-3">
                                    <Col md={10}>
                                      <Row className="g-3">
                                        <CategoryPane handleClick={performCategoryAction} categoryList={categoryList} />
                                      </Row>
                                    </Col>
                                    <Col md={2}>
                                      <Row className="g-0">
                                        <Col md={12}>
                                          <Row className='g-3'>
                                            <Col md={6} className="">
                                              <OverlayTrigger placement='top' overlay={<Tooltip><IoChevronBack className='fs-16' /> Previous</Tooltip>}>
                                                <Button disabled={pagination.main.pageCount === 0 || pagination.main.pageNumber === 1} className='light-btn w-100 h-56' variant="primary" onClick={() => handleMainCategoryPagination('PREV')}><IoChevronBack className='fs-16' /></Button>
                                              </OverlayTrigger>
                                            </Col>
                                            <Col md={6} className="">
                                              <OverlayTrigger placement='top' overlay={<Tooltip>Next <IoChevronForward className='fs-16' /></Tooltip>}>
                                                <Button disabled={pagination.main.pageCount === 0 || (pagination.main.pageNumber === pagination.main.pageCount)} className='light-btn w-100 h-56' variant="primary" onClick={() => handleMainCategoryPagination('NEXT')}><IoChevronForward className='fs-16' /></Button>
                                              </OverlayTrigger>
                                            </Col>
                                            <Col md={12} >
                                              <OverlayTrigger placement='top' overlay={<Tooltip><FaPlus className='fs-16' /> New Category</Tooltip>}>
                                                <Button disabled={!enableCrudOperation} className='light-btn w-100 text-truncate h-56' variant="primary" onClick={() => handleModalManager('createCategory', true)} ><FaPlus className='fs-16' /> Category</Button>
                                              </OverlayTrigger>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                                : null}
                              <Collapse in={expandCategoryView}>
                                <div id="example-collapse-text">
                                  <Col md={12} className='cat_height' style={{ height: pagination.main.pageCount < 5 ? 'auto' : '500px' }}>
                                    <Row className="g-3">
                                      <ExpandCategoryPane categoryList={categoryList} handleClick={performCategoryAction} />
                                    </Row>
                                  </Col>
                                </div>
                              </Collapse>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col md={3}>
                <Card className='cart-div rounded-0 border-0 shadow-none bg-transparent'>
                  <Card.Header className='p-3 pb-0 bg-transparent border-0'>
                    <Row className="g-3">
                      <Col md={6} >
                        <OverlayTrigger placement='top' overlay={<Tooltip>{currentOrder?.customer?.name}</Tooltip>}>
                          <Button className='light-btn w-100 text-truncate h-56 text-uppercase' variant="primary" onClick={() => handleModalManager('customerDetailModal', true)}>
                            <span className='d-flex align-items-center justify-content-center'>
                              <LuUser2 className='me-2 fs-16' />
                              <span className='d-block'>{currentOrder?.customer?.name.length > 16 ? currentOrder?.customer?.name.substring(0, 16) + "..." : currentOrder?.customer?.name}</span>
                            </span>
                          </Button>
                        </OverlayTrigger>
                      </Col>
                      <Col md={6} >
                        <OverlayTrigger placement='top' overlay={<Tooltip>{currentOrder?.deliveryMethod.length === 0 ? "ORDER TYPE" : currentOrder?.deliveryMethod}</Tooltip>}>
                          <Button className='light-btn w-100 text-truncate h-56 text-uppercase' variant="primary" onClick={() => { handleModalManager('orderTypeModal', true); isOrderConfirmed = false; isOrderConfirmedWithPrint = false; }} >
                            <span className='d-flex align-items-center justify-content-center'>
                              <Image className='me-2' width={16} src={currentOrder?.deliveryMethod === "DINE" ? dine : currentOrder?.deliveryMethod === "PICK" ? pick : currentOrder?.deliveryMethod === "DELIVERY" ? delivery : currentOrder.deliveryMethod === "DRIVE" ? drive : plusIcon} />
                              <span className='d-block'>{currentOrder?.deliveryMethod.length === 0 ? "ORDER TYPE" : currentOrder?.deliveryMethod}</span>
                            </span>
                          </Button>
                        </OverlayTrigger>
                      </Col>
                    </Row>
                    <hr />
                  </Card.Header>
                  <Card.Body className='p-3 empty-cart'>
                    <Row className="g-0 ">
                      <Col md={12}>
                        <span className='fs-12 ms-2'>Total Items ({currentOrder.totalItems})</span>
                      </Col>
                      <Col md={12}>
                        <Cart items={currentOrder.items} qtyManage={adjustItemQtyManualy} changeQtyManual={(orderItem) => { handleModalManager('qtyChangeModal', true); selectedItem = orderItem }} />
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className='p-3 bg-white border-0'>
                    <div className='invoice-bottom'>
                      <Row className="g-0 ">
                        <Col md={6} className='text-start'>
                          <span className='fs-14'>Sub-Total</span>
                        </Col>
                        <Col md={6} className=' text-end'>
                          <span className='fs-14'><FaIndianRupeeSign className='' />{parseFloat(currentOrder.subTotal).toFixed(2)}</span>
                        </Col>
                      </Row>
                      <Row className="g-0 ">
                        <Col md={6} className='text-start'>
                          <span className='fs-14'>Tax</span>
                        </Col>
                        <Col md={6} className=' text-end'>
                          <span className='fs-14'><FaIndianRupeeSign className='' />{parseFloat(currentOrder.totalGst).toFixed(2)}</span>
                        </Col>
                      </Row>
                      <Row className="g-0 mb-3">
                        <Col md={6} className='text-start'>
                          <h5 className='fw-bold fs-4'>Total</h5>
                        </Col>
                        <Col md={6} className=' text-end'>
                          <span className='fw-bold fs-4'><FaIndianRupeeSign className='' />{parseFloat(currentOrder.orderTotal).toFixed(2)}</span>
                        </Col>
                      </Row>
                      <Row className="g-3">
                        <Col md={5} className=''>
                          <OverlayTrigger placement="top" overlay={popover} delay={{ show: 250, hide: 600 }} trigger={currentOrder.items.length === 0 ? 'click' : ''} show={overlayTrigger.type === 'placeOrder'}>
                            <Button className='light-btn w-100 text-truncate h-56 bg-light-yellow' variant="primary" name='placeOrder' onClick={onPlaceOrder}>Place Order</Button>
                          </OverlayTrigger>
                        </Col>
                        <Col md={7} className=''>
                          <OverlayTrigger placement="top" overlay={popover} delay={{ show: 250, hide: 600 }} show={overlayTrigger.type === 'placeOrderOrPrint'} >
                            <Button className='light-btn w-100 text-truncate h-56 bg-light-yellow' variant="primary" name='placeOrderWithPrint' onClick={onPlaceOrderWithReport}>Place Order & Print</Button>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
              <Col md={12} className="text-center bg-dark text-light">
                <small><span className='fst-italic'>Powered by</span> INSONIX</small>
              </Col>
            </Row >
          </> :
          <>
            <Image className='loading-img' src={loadingIcon} alt="logo" />
          </>
      }
      <ManageQuantityModal {...qtyManualChangeModalProps} />
      <EditProductModal {...editProductModalProps} />
      <AddProductModal {...addProductModalProps} />
      <AddSubCategoryModal {...addSubCategoryModalProps} />
      <AddCategoryModal {...addCategoryModalProps} />
      <EditCategoryModal {...editCategoryModalProps} />
      <EditSubCategoryModal {...editSubCategoryModalProps} />
      <CustomerDetailModal {...customerDetailModalProps} />
      <OrderTypeModal {...orderTypeModalProps} />
      <PaymentTypeModal {...paymentTypeModalProps} />
      <KitchenReport {...kitchenReportsProps} />
      <TokenOrderDetailModal {...tokenOrderDetailModalProps} />
      <SingleOrderDetailModal {...singleOrderDetailModalProps} />
    </>
  )
}

export default BillDesk;
import React, { useEffect, useState } from "react";
import moment from "moment";

const billDateFormat = process.env.REACT_APP_BILL_FORMAT;
const gstNumber = process.env.REACT_APP_GST_NUMBER;

const CustomerReport = ({ orderDetails }) => {

    const [orderData, setOrderData] = useState({});

    const business = localStorage.getItem('BUSINESS') ? JSON.parse(localStorage.getItem('BUSINESS')) : {}

    useEffect(() => {
        if (orderDetails) {
            calculateOrderTax();
        }
    }, [orderDetails]);

    const calculateOrderTax = () => {
        let newData = orderDetails;
        let cgst = 0;
        let sgst = 0;
        if (newData && newData.items) {
            newData.items.map(item => {
                let itemPrice = item.price;
                if (item.isPriceGstInc === 'Y') {
                    let itemGst = item.perGst ? (itemPrice * item.perGst / (100 + item.perGst)) : 0;
                    itemGst = itemGst.toFixed(2);
                    itemPrice = itemPrice - itemGst;
                }
                item.price = itemPrice;
                return item;
            })
        }
        cgst = parseFloat(newData?.totalGst?.toFixed(2)) / 2;
        sgst = parseFloat(newData?.totalGst?.toFixed(2)) / 2;
        setOrderData({ ...newData, sgst, cgst, subTotal: parseFloat(newData.orderTotal - newData.totalGst).toFixed(2) });
    };

    return (
        Object.keys(orderDetails).length !== 0 ?
            <div id="customerReport" style={{ display: 'inline' }} hidden={true} >
                <div class="invoice-box">
                    <table class="table-head" cellpadding="0" cellspacing="0">
                        <tr>
                            <td>
                                <strong style={{ fontSize: 28 }}>{business.name}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Main Road,<br />
                                Opp. Nidhan Wala Mod. V.P.O. Dagru<br />
                                Mobile No. - {business.phoneNumber} <br />
                                <strong>Order Type : {orderData.deliveryMethod}</strong><br />
                                <strong>GST : {business.gstNumber}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-border">
                                <strong>Invoice No : {orderData?.invoiceNo}</strong><br />
                                Date : {moment(new Date(orderData.orderDate)).format(billDateFormat === 'DT' ? 'DD-MM-YYYY hh:mm:ss' : 'DD-MM-YYYY')} <br />
                                {orderData.customer !== undefined ?
                                    <>
                                        <label>
                                            Name : {orderData.customer?.name}
                                        </label>
                                        <br />
                                        <label>
                                            Mobile Number: {orderData.customer?.mobileNumber ? orderData.customer?.mobileNumber : "N/A"}
                                        </label>
                                    </> :
                                    null
                                }
                            </td>
                        </tr>
                    </table>
                    <table class="table-body" cellpadding="0" cellspacing="0">

                        <tr class="heading">
                            <td>Item</td>
                            <td>Qty</td>
                            <td>Rate</td>
                            <td>Amount</td>
                        </tr>

                        {orderData !== null && orderData.id !== undefined && orderData.items ?
                            orderData.items.map(item => {
                                return (
                                    < tr class="details">
                                        <td>{item.prodAlias || item.name}</td>
                                        <td>{item.qty}</td>
                                        <td>{parseFloat(item.price).toFixed(2)}</td>
                                        <td>{(parseFloat(item.price) * parseFloat(item.qty)).toFixed(2)}</td>
                                    </tr>
                                )
                            })
                            : null}
                    </table>
                    <table class="table-footer" cellpadding="0" cellspacing="0">
                        <tr class="total-item tr-p">
                            <td>  </td>
                            <td></td>
                            <td>
                                <strong>Sub Total:</strong>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <strong>{parseFloat(orderData?.subTotal).toFixed(2)}</strong>
                            </td>
                        </tr>
                        {orderData?.customer && orderData?.customer?.stateCode === "03" ?
                            <>
                                <tr class="total-item tr-p">
                                    <td>  </td>
                                    <td></td>
                                    <td>
                                        CGST:
                                    </td>
                                    <td></td>
                                    <td>{(orderData.totalGst / orderData.subTotal * 50).toFixed(2)}%</td>
                                    <td>
                                        {parseFloat(orderData?.cgst).toFixed(2)}
                                    </td>
                                </tr>
                                <tr class="total-item tr-p">
                                    <td> </td>
                                    <td></td>
                                    <td>
                                        SGST:
                                    </td>
                                    <td></td>
                                    <td>{(orderData.totalGst / orderData.subTotal * 50).toFixed(2)}%</td>
                                    <td>
                                        {parseFloat(orderData?.sgst).toFixed(2)}
                                    </td>
                                </tr>
                            </>
                            : <tr class="total-item tr-p">
                                <td> </td>
                                <td></td>
                                <td>
                                    IGST:
                                </td>
                                <td></td>
                                <td>5%</td>
                                <td>
                                    {parseFloat(orderData?.sgst).toFixed(2)}
                                </td>
                            </tr>}
                        <tr>
                            <td colSpan='6' style={{ borderBottom: '1px solid #000', padding: '0px' }}></td>
                        </tr>
                        <tr class="total-item">
                            <td></td>
                            <td> </td>
                            <td>
                                <strong>Grand Total:</strong>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <strong>{parseFloat(orderData?.orderTotal).toFixed(2)}</strong>
                            </td>
                        </tr>
                    </table>
                    {orderDetails.deliveryMethod === 'DELIVERY' ?
                        <table class="detail-table" cellpadding="5" cellspacing="0">
                            <tr>
                                <td colspan="2" className="top">
                                    Delivery Details
                                </td>
                            </tr>
                            <tr>
                                <td >Address:</td>
                                <td>{orderData.deliveryAddress?.line1 || "N/A"}</td>
                            </tr>
                            <tr>
                                <td >Contact Person:</td>
                                <td>{orderData.deliveryAddress?.personName || "N/A"}</td>
                            </tr>
                            <tr>
                                <td >Contact Number:</td>
                                <td >{orderData.deliveryAddress?.mobileNumber || "N/A"}</td>
                            </tr>
                            <tr className="last-row">
                                <td >Items to Deliver:</td>
                                <td >
                                    <ul>
                                        {orderData?.items?.map(item => (
                                            <li key={item.id}>
                                                {item.qty} x {item.prodAlias || item.name}
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                            </tr>

                        </table> : null}

                    <table class="last-t" cellpadding="0" cellspacing="0">
                        <tr>
                            <td>
                                <strong>THANK YOU VISIT AGAIN HAVE A NICE DAY</strong>
                            </td>
                        </tr>
                    </table>
                </div>
            </div >
            : null
    )
}

export default CustomerReport
import moment from "moment";
import { SESSION_KEYS } from "./constants";

export const deepCopy = (data) => {
    return JSON.parse(JSON.stringify(data));
}

export const addDelay = async (time) => {
    await new Promise(resolve => setTimeout(resolve, time ? time : 0));
};

export const getValueFromToken = (value) => {
    return parseJwt(localStorage.getItem(SESSION_KEYS.USER)) ? parseJwt(localStorage.getItem(SESSION_KEYS.USER))[value] : null
}

function parseJwt(token) {
    if (token) {

        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }
};

export const getProductsInCategories = (catData = []) => {
    let newArr = [];
    catData.forEach(data => {
        if (data.categories) {
            data.categories && data.categories.forEach(ca => {
                if (ca.products) {
                    ca.products && ca.products.forEach(pa => {
                        newArr.push(pa);
                    })
                }
            })
        }
    });
    return newArr;
};

export const errorMessage = (message) => {
    if (message.localeCompare('Request failed with status code 401') === 0) {
        return 'Unauthorized to access';
    } else if (message.localeCompare('Network Error') === 0) {
        return 'Failed to Connect';
    } else {
        return '';
    }
};

export const getSelCategoryByStyle = (categoryList, style) => {
    let selCategory = null
    categoryList && categoryList.forEach(list => {
        if (Array.isArray(list)) {
            list.forEach(category => {
                if (category.className === style) {
                    selCategory = category;
                }
            })
        } else {
            if (list.className === style) {
                selCategory = list;
            }
        }
    })
    return selCategory
}

export const standardDateFormat = (date, dateFormat) => {
    let newDate = null;
    if (String(dateFormat).includes('HH:mm:ss')) {
        const splitDate = String(date).split('T');
        if (splitDate) {
            newDate = moment(new Date(date)).format(dateFormat.split('HH:mm:ss')[0]) + " " + (splitDate[1] ? splitDate[1].substring(0, 8) : "")
        }
    } else {
        newDate = moment(new Date(date)).format(dateFormat)
    }
    return newDate;
}

export const validateFields = (mandatoryFields = [], data = {}) => {
    let flag = false;
    mandatoryFields.forEach(fields => {
        if ((data[fields] === undefined || data[fields] === null || data[fields] === '') || JSON.stringify(data).includes(`"${fields}":""`)) {
            flag = true;
        }
    });
    return flag;
}

export const validateEmail = (email = '') => {
    if (email.includes('@gmail.com')) {
        return true;
    }
    return false
}

export const validateURL = (text) => {
    const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // Protocol (optional)
        "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|" + // Domain name
        "localhost|" + // OR localhost
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IPv4
        "(\\:\\d+)?(\\/[-a-zA-Z0-9@:%._\\+~#=]*)*" + // Port and path (optional)
        "(\\?[;&a-zA-Z0-9%_.~+=-]*)?" + // Query string (optional)
        "(\\#[-a-zA-Z0-9_]*)?$", // Fragment (optional)
        "i"
    );

    return urlPattern.test(text);
};
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import { TbEdit } from "react-icons/tb";
import { AiOutlineDelete } from "react-icons/ai";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { LuSearch } from "react-icons/lu";
import { FaPlus } from "react-icons/fa6";
import { FiFilter } from "react-icons/fi";

const initialPageDetails = { currentPage: 1, pageSize: 10 };

const TerminalPane = ({ ...props }) => {
    const [terminalToDisplay, setTerminalToDisplay] = useState([]);
    const [pageDetails, setPageDetails] = useState({ ...initialPageDetails });
    const [memberSearch, setMemberSearch] = useState("");
    const [terminals, setTerminals] = useState([]);
    const [storeFilter, setStoreFilter] = useState("All"); // New state for store filter

    useEffect(() => {
        if (props.clearSearch) {
            setMemberSearch('');
            setTerminals(props.terminalList);
            handlePaginator(props.terminalList, pageDetails.pageSize, pageDetails.currentPage);
        }
    }, [props.clearSearch, props.terminalList]);

    useEffect(() => {
        setTerminals(props.terminalList);
        handlePaginator(props.terminalList, pageDetails.pageSize, pageDetails.currentPage);
    }, [props.terminalList]);

    const handlePaginator = (terminalList, pageSize, currentPage) => {
        const chunksRecord = [];
        terminalList &&
            terminalList.forEach((item) => {
                if (!chunksRecord.length || chunksRecord[chunksRecord.length - 1].length === pageSize)
                    chunksRecord.push([]);
                chunksRecord[chunksRecord.length - 1].push(item);
            });
        setTerminalToDisplay(chunksRecord[currentPage - 1] === undefined ? [] : chunksRecord[currentPage - 1]);
    };

    const handlePagination = (pageType) => {
        if (pageType === "NEXT") {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage + 1 });
            handlePaginator(terminals, pageDetails.pageSize, pageDetails.currentPage + 1);
        } else {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage - 1 });
            handlePaginator(terminals, pageDetails.pageSize, pageDetails.currentPage - 1);
        }
    };

    const handlePageSize = (event) => {
        setPageDetails({ ...pageDetails, pageSize: parseInt(event.target.value) });
        handlePaginator(terminals, parseInt(event.target.value), pageDetails.currentPage);
    };

    const handleSearch = (event) => {
        const searchValue = event.target.value.toLowerCase();
        setMemberSearch(searchValue);
        filterTerminals(searchValue, storeFilter);
    };

    const handleStoreFilter = (storeName) => {
        setStoreFilter(storeName);
        filterTerminals(memberSearch, storeName);
    };

    const filterTerminals = (search, storeName) => {
        const filteredList = props.terminalList.filter((terminal) => {
            const matchesSearch = terminal.name.toLowerCase().includes(search.toLowerCase());
            const matchesStore =
                storeName === "All" ||
                (props.storeList.find((store) => store.id === terminal.storeId)?.name === storeName);
            return matchesSearch && matchesStore;
        });
        setTerminals(filteredList);
        handlePaginator(filteredList, pageDetails.pageSize, pageDetails.currentPage);
    };

    return (
        <Row className="g-3">
            <Col md={12}>
                <Row className="g-3">
                    <Col md={6}>
                        <Form.Floating className="search_bar">
                            <span className="input-group-text search_icon">
                                <LuSearch />
                            </span>
                            <Form.Control
                                className="search_input"
                                id="productSearch"
                                type="search"
                                autoComplete="off"
                                name="filterText"
                                placeholder="Search By Terminal Name"
                                value={memberSearch}
                                onChange={handleSearch}
                            />
                            <Form.Label htmlFor="floatingInputCustom">Search By Terminal Name</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={3} lg={3} xl={2} className="">
                        <Button
                            className="light-btn h-56 w-100"
                            variant="primary"
                            onClick={() => props.handleModalManager("addTerminalModal", true)}
                        >
                            <FaPlus className="me-1 fs-16" />
                            Terminal
                        </Button>
                    </Col>
                    <Col md={3} lg={3} xl={2} className="">
                        <Dropdown>
                            <Dropdown.Toggle className="light-btn h-56 w-100" variant="primary" id="store-filter-dropdown">
                                <FiFilter className="me-1 fs-16" />
                                {storeFilter === 'All' ? 'Store Name' : storeFilter}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleStoreFilter("All")} active={storeFilter === 'All'}>All</Dropdown.Item>
                                {props.storeList && props.storeList.map((store) => (
                                    <Dropdown.Item key={store.id} active={storeFilter === store.name} onClick={() => handleStoreFilter(store.name)}>
                                        {store.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </Col>
            <Col md={12}>
                <Table responsive className="inso-table">
                    <thead>
                        <tr>
                            <th className="text-start" width="40%">Name</th>
                            <th className="text-start" width="40%">Store Name</th>
                            <th className="text-center" width="20%">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {terminalToDisplay &&
                            terminalToDisplay.map((item) => {
                                return (
                                    <tr key={item.id}>
                                        <td className="text-start">{item.name}</td>
                                        <td className="text-start">
                                            {props.storeList
                                                ? props.storeList.find((e) => e.id === item.storeId)?.name || "-"
                                                : "-"}
                                        </td>
                                        <td className="text-center">
                                            <OverlayTrigger placement="top" overlay={<Tooltip>Edit Terminal</Tooltip>}>
                                                <Button
                                                    className="border-0 bg-transparent p-0"
                                                    onClick={() => {
                                                        props.handleModalManager("editTerminalModal", true);
                                                        props.setTerminalDetails(item);
                                                    }}
                                                >
                                                    <TbEdit className="fs-16 text-dark " />
                                                </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top" overlay={<Tooltip>Delete Terminal</Tooltip>}>
                                                <Button
                                                    className="border-0 bg-transparent p-0 ms-3"
                                                    onClick={() => {
                                                        props.handleModalManager("deleteTerminalConfirmModal", true);
                                                        props.setTerminalDetails(item);
                                                    }}
                                                >
                                                    <AiOutlineDelete className="fs-16 text-dark " />
                                                </Button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
                <Row className="g-0">
                    <Col
                        md={12}
                        className="table-pagination flex-wrap d-flex align-items-center justify-content-end"
                    >
                        <div className="d-flex align-items-center mb-3">
                            <span className="mx-3"> Items per page:</span>
                            <select
                                className="form-select"
                                aria-label="Floating label select example"
                                onChange={handlePageSize}
                            >
                                {props.pageSizeOptions.map((page, index) => (
                                    <option key={index} value={page.value}>
                                        {page.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                            <span className="mx-3">
                                {pageDetails.currentPage +
                                    " - " +
                                    Math.ceil(terminals.length / pageDetails.pageSize) +
                                    " of " +
                                    terminals.length}
                            </span>
                            <Button
                                className="light-btn px-3"
                                variant="primary"
                                disabled={pageDetails.currentPage === 1}
                                onClick={() => handlePagination("PREV")}
                            >
                                <IoChevronBackOutline className="fs-16" />
                            </Button>
                            <Button
                                className="light-btn px-3 ms-3"
                                variant="primary"
                                disabled={
                                    terminals?.length < pageDetails.pageSize ||
                                    pageDetails.currentPage === Math.ceil(terminals.length / pageDetails.pageSize)
                                }
                                onClick={() => handlePagination("NEXT")}
                            >
                                <IoChevronForwardOutline className="fs-16" />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default TerminalPane;

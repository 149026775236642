import React, { useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { LuSearch } from "react-icons/lu";
// Import as a module in your JS
import 'react-bootstrap-typeahead/css/Typeahead.css';

const AutoCompleter = ({ data, onChange, preSelectedIndex, onInputChange, extraLabel, placeholder, name, multiple, clearSearch, setClearSearch, disabled }) => {

    const [selectedOption, setSelectedOption] = useState([]);
    const [searchInput, setSearchInput] = useState([])
    const autoCompleterRef = React.createRef();

    useEffect(() => {
        if (preSelectedIndex !== undefined && preSelectedIndex !== null) {
            if (data && data.length > 0 && data[preSelectedIndex]) {
                setSelectedOption([data[preSelectedIndex]])
            }
        }
    }, [preSelectedIndex, data]);

    useEffect(() => {
        if (clearSearch) {
            setSelectedOption([]);
            setSearchInput([]);
            setClearSearch(false)
        }
    }, [clearSearch]);


    const onItmSelect = (itm = []) => {
        if (itm.length > 0) {
            onChange({ selItm: itm[0] });
        }
        setSelectedOption(itm)
    };

    const handleChange = (e) => {
        onInputChange(e);
        setSearchInput([e])
    };

    return (
        <div className="main_search_bar">
            <span class="search_icon"><LuSearch /></span>
            <Typeahead
                ref={autoCompleterRef}
                multiple={multiple}
                disabled={disabled || false}
                className="type-input"
                onInputChange={handleChange}
                onChange={onItmSelect}
                id="basic-typeahead-single"
                labelKey={(option) => `${option[name]}${extraLabel ? `(${option[extraLabel] || ""})` : ''}`}
                options={data}
                placeholder={placeholder}
                clearButton
                selected={selectedOption}
                inputProps={{
                    value: searchInput,          // Bind input state
                }}

            />
        </div>
    )
}

export default AutoCompleter;
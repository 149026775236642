import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { BiPencil } from "react-icons/bi";
import { IoIosCloseCircleOutline } from "react-icons/io";
import uploadHoimg from "../../../../../../assets/images/upload.jpg";
import { deleteMedia, getStoreMediaByStoreId } from "../../../../../../actions/admin/AdminActions";
import { useDispatch } from "react-redux";
import { deepCopy } from "../../../../../../util/utility-helper";
const baseUrlPoint = process.env.REACT_APP_API_BASE_ENDPOINT;
let storeMedia = { mediaPromotions: [], mediaCruisers: [] };
const StoreEcommerceMedia = (props) => {
    const dispatch = useDispatch();

    const selectedFileRef = useRef(null)

    const [promotions, setPromotions] = useState([]);
    const [cruisers, setCruisers] = useState([]);

    useEffect(() => {
        if (props.store) {
            storeMedia = { mediaCruisers: [], mediaPromotions: [] };
            getStoreMedia(props.store?.id);
        }
    }, [props.store]);

    const getStoreMedia = async (storeId) => {
        await dispatch(getStoreMediaByStoreId(storeId)).then((response) => {
            handlePromotionsList(response?.payload && response?.payload?.promotions || []);
            handleCruisers(response?.payload && response?.payload?.cruisers || [])
        });
    };


    const handlePromotionsList = (promList) => {
        const emptyList = [];
        for (let i = 0; i < 5; i++) {
            if (promList.length > i) {
                emptyList.push({ fileSrc: baseUrlPoint + promList[i], src: promList[i] });
            } else {
                emptyList.push({ fileSrc: null });
            }
        }
        setPromotions(emptyList);
    };

    const handleCruisers = (crusList) => {
        const emptyList = [];
        for (let i = 0; i < 5; i++) {
            if (crusList.length > i) {
                emptyList.push({ fileSrc: baseUrlPoint + crusList[i], src: crusList[i] });
            } else {
                emptyList.push({ fileSrc: null });
            }
        }
        setCruisers(emptyList);
    };

    const openFileUploader = (fileType) => {
        document.getElementById(fileType).click();
    };

    const onFileChange = async (event, fileType) => {
        const file = event.target.files[0];
        if (!file) return;

        let updatedMediaList, setMediaList;

        // Determine which list to work with based on fileType
        if (fileType === 'mediaPromotions') {
            updatedMediaList = deepCopy(promotions);
            setMediaList = setPromotions;
        } else {
            updatedMediaList = deepCopy(cruisers);
            setMediaList = setCruisers;
        }

        // Find the first available index (empty slot)
        const firstAvailableIndex = updatedMediaList.findIndex((fileItem) => fileItem.fileSrc === null);


        const targetIndex = selectedFileRef.current !== null ? selectedFileRef.current : firstAvailableIndex;

        // Ensure there's an available slot
        if (targetIndex !== -1) {
            // Update the media list at the target index
            updatedMediaList[targetIndex] = {
                ...updatedMediaList[targetIndex],
                fileSrc: URL.createObjectURL(file),
                file: file,
            };

            // Update state with the modified list
            setMediaList(updatedMediaList);

            // Update storeMedia
            const index = storeMedia[fileType].findIndex(
                (e) => e.fileName === `${fileType}_${targetIndex}`
            );

            if (index > -1) {
                storeMedia[fileType][index] = {
                    file: file,
                    fileName: `${fileType}_${targetIndex}`,
                };
            } else {
                storeMedia[fileType].push({
                    file: file,
                    fileName: `${fileType}_${targetIndex}`,
                });
            }

            // Call the parent handler with updated media
            props.handleStoreMedia(storeMedia);
        } else {
            console.warn('No available slot for the file.');
        }
    };


    // const uploadMedia = async () => {
    //     storeMedia['mediaPromotions'].forEach(async prom => {
    //         const formData = new FormData();
    //         formData.append("files", prom.file);
    //         formData.append("fileName", prom.fileName);
    //         await dispatch(uploadResource({ mediaType: selectedFileRef?.current.mediaType, formData: formData })).then((response) => {
    //             if (response.payload && !response.payload.error) {
    //                 getStoreMedia(props.store.id);
    //                 selectedFileRef.current = null;
    //                 props.handleToastManager({ visible: true, toastMessage: "Media Uploaded.", variant: "Success" });
    //             } else {
    //                 props.handleToastManager({ visible: true, toastMessage: response.payload.error, variant: "Danger" });
    //             }
    //         })
    //     })
    // }

    const handleDeleteFile = async (media, mediaType, index) => {
        if (media.src) {
            const splitedPath = media.src?.split("/");
            await dispatch(deleteMedia({ mediaType: mediaType, storeId: props.store.id, endPath: splitedPath[4], })).then((response) => {
                if (response.payload && !response.payload.error) {
                    getStoreMedia(props.store?.id);
                } else {

                }
            });
        } else {
            const foundIndex = storeMedia[mediaType].findIndex(e => String(String(e.fileName).split('_')[1]) === String(index));
            if (foundIndex > -1) {
                storeMedia[mediaType].splice(foundIndex, 1);
                props.handleStoreMedia(storeMedia);
                if (mediaType === 'mediaPromotions') {
                    const index = promotions.findIndex(e => e.fileSrc === media.fileSrc);
                    let promClone = [...promotions];
                    if (index > -1) {
                        promClone[index] = { ...promClone[index], fileSrc: null };
                        setPromotions(promClone);
                    }
                } else {
                    const index = cruisers.findIndex(e => e.fileSrc === media.fileSrc);
                    let cruClone = [...cruisers];
                    if (index > -1) {
                        cruClone[index] = { ...cruClone[index], fileSrc: null };
                        setCruisers(cruClone);
                    }
                }
            }
        }
    };

    return (
        <>
            <Col md={12}>
                <Card className="border shadow-0" style={{ borderRadius: '10px' }}>
                    <Card.Body className="px-0 py-3">
                        <Row className="mx-0">
                            <Col md={12}>
                                <h5 className="fs-6 mb-3"> Promotion Banners</h5>
                            </Col>
                            <Col md={12} className="d-flex flex-wrap align-items-start px-0">
                                {promotions.map((prom, index) => {
                                    return (<div key={index} className="pro-main mx-auto mx-lg-2">
                                        <div className="pro-menu" onClick={() => { openFileUploader('promFile'); selectedFileRef.current = prom.fileSrc ? index : null }}>
                                            <div className="pro-overlay">
                                                <BiPencil className="pro-icon" />
                                            </div>
                                            <img className="pro-image" src={prom.fileSrc || uploadHoimg} />
                                            <input
                                                type="file"
                                                accept=".jpg,.jpeg,.png"
                                                name="files"
                                                id="promFile"
                                                hidden={true}
                                                onChange={(event) => onFileChange(event, 'mediaPromotions')}
                                            />
                                        </div>
                                        {prom.fileSrc ? (
                                            <div className="pro-trash" onClick={() => handleDeleteFile(prom, 'mediaPromotions', index)}>
                                                <IoIosCloseCircleOutline />
                                            </div>
                                        ) : null}

                                    </div>
                                    )
                                })}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={12}>
                <Card className="border shadow-0" style={{ borderRadius: '10px' }}>
                    <Card.Body className="px-0 py-3">
                        <Row className="mx-0">
                            <Col md={12}>
                                <h4 className="fs-6 mb-3"> Product Banners </h4>
                            </Col>
                            <Col md={12} className="d-flex flex-wrap align-items-start px-0">
                                {cruisers.map((crus, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="pro-main mx-auto mx-lg-2"
                                        >
                                            <div className="pro-menu" onClick={() => { openFileUploader('crusFile'); selectedFileRef.current = crus.fileSrc ? index : null }}>
                                                <div className="pro-overlay">
                                                    <BiPencil className="pro-icon" />
                                                </div>
                                                <img
                                                    className="pro-image"
                                                    src={crus.fileSrc ? crus.fileSrc : uploadHoimg}
                                                />
                                                <input
                                                    type="file"
                                                    accept=".jpg,.jpeg,.png"
                                                    name="files"
                                                    id="crusFile"
                                                    hidden={true}
                                                    onChange={(event) => onFileChange(event, 'mediaCruisers', index)}

                                                />
                                            </div>
                                            {crus.fileSrc ? (
                                                <div
                                                    className="pro-trash"
                                                    onClick={() => handleDeleteFile(crus, 'mediaCruisers', index)}
                                                >
                                                    <IoIosCloseCircleOutline />
                                                </div>
                                            ) : null}
                                        </div>
                                    );
                                })}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </>
    )
}

export default StoreEcommerceMedia;
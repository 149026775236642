import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Image, Modal, Row, Table } from 'react-bootstrap';
import loader from '../../../../assets/images/loading-img.gif';
import { LuUser2 } from 'react-icons/lu';
import { FaIndianRupeeSign } from 'react-icons/fa6';
import dine from '../../../../assets/images/dineblack.png';
import pick from '../../../../assets/images/packingblack.png';
import delivery from '../../../../assets/images/deliveryblack.png';
import drive from '../../../../assets/images/drive-thru.png';
import plusIcon from '../../../../assets/images/plus.png';
import { useDispatch } from 'react-redux';
import { getOrderById, updateOrder } from '../../../../actions/order/OpenOrdersActions';
import CustomerDetailModal from '../../billing/standard/modals/other/CustomerDetailModal';
import OrderTypeModal from '../../billing/standard/modals/other/OrderTypeModal';
import ConfirmDialog from '../../../../components/common/App/ConfirmDialog';
import PaymentType from '../../billing/standard/modals/other/PaymentTypeModal';
import { deepCopy } from '../../../../util/utility-helper';
import KitchenReport from '../../../../components/reports/KitchenReports';
import { MINI_BILL_CSS_FORMAT } from '../../../../components/reports/constants';

let isDataChangeFlag = false;

const SingleOrderDetailModal = ({ ...props }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false)
    const [singleOrderDetails, setSingleOrderDetails] = useState({});
    const [modalManage, setModalManage] = useState({ modalName: '', visible: false });
    const [paymentButtonState, setPaymentButtonState] = useState({ payButton: { value: 0.00, disable: false }, unPayButton: { value: 0.00, disable: false } });

    useEffect(() => {
        if (props.showModal) {
            setLoading(true);
            getSingleOrderDetailById(props.selectedOrder);
        }
    }, [props.showModal, props.selectedOrder]);

    const getSingleOrderDetailById = async (orderDetail) => {
        if (orderDetail && orderDetail.id) {
            await dispatch(getOrderById(orderDetail.id))
                .then((response) => {
                    if (response.payload) {
                        let orderDetail = deepCopy(response.payload || {});
                        orderDetail.items && orderDetail.items.map(order => {
                            let itemPrice = order.price;
                            if (order.isPriceGstInc === 'Y') {
                                let itemGst = order.perGst ? (itemPrice * order.perGst / (100 + order.perGst)) : 0;
                                itemGst = itemGst.toFixed(2);
                                itemPrice = itemPrice - itemGst;
                            }
                            order.price = itemPrice;
                            return order;
                        })
                        setSingleOrderDetails({ ...orderDetail, subTotal: parseFloat(response.payload.orderTotal - response.payload.totalGst).toFixed(2) });
                        calculatePaymentStatus(response.payload);
                    }
                    setLoading(false);
                });
        }
    };

    const calculatePaymentStatus = (orderDetail) => {
        let buttonState = { ...paymentButtonState };
        if (orderDetail.paymentStatus === 'PARTIAL') {
            const totalPay = parseFloat(orderDetail.orderTotal - orderDetail.amountPaid).toFixed(2);
            if (totalPay > 0) {
                buttonState = { ...buttonState, payButton: { ...buttonState.payButton, disable: false, value: totalPay }, unPayButton: { ...buttonState.unPayButton, disable: false, value: parseFloat(orderDetail.orderTotal - totalPay).toFixed(2) } };
            } else {
                buttonState = { ...buttonState, payButton: { ...buttonState.payButton, disable: true, value: 0.00 }, unPayButton: { ...buttonState.unPayButton, disable: false, value: parseFloat(orderDetail.amountPaidail).toFixed(2) } };
            }
        } else if (orderDetail.paymentStatus === 'PAID') {
            buttonState = { ...buttonState, payButton: { ...buttonState.payButton, disable: true, value: 0.00 }, unPayButton: { ...buttonState.unPayButton, disable: false, value: parseFloat(orderDetail.amountPaid).toFixed(2) } };
        } else {
            buttonState = { ...buttonState, payButton: { ...buttonState.payButton, disable: false, value: parseFloat(orderDetail.orderTotal).toFixed(2) }, unPayButton: { ...buttonState.unPayButton, disable: true, value: parseFloat(0.00).toFixed(2) } };
        }
        setPaymentButtonState({ ...buttonState, });
    };

    const handleModalManager = (modalName, visible) => {
        setModalManage({ ...modalManage, modalName, visible })
    };

    const payOrder = async (prevPaymentStatus) => {
        if (prevPaymentStatus === 'UNPAID') {
            handleModalManager('paymentTypeModal', true);
        } else {
            const newPaymentType = (singleOrderDetails.orderTotal - (parseInt(singleOrderDetails.amountPaid) + parseInt(paymentButtonState.payButton.value))) === 0 ? "PAID" : "PARTIAL"
            await dispatch(updateOrder({ id: singleOrderDetails.id, paymentStatus: newPaymentType, amountPaid: (parseFloat(paymentButtonState.payButton.value) + parseFloat(singleOrderDetails.amountPaid)).toFixed(2) }))
                .then(async (response) => {
                    if ((response.payload && !response.payload.error)) {
                        isDataChangeFlag = true;
                        await getSingleOrderDetailById(singleOrderDetails);
                        props.handleToastManager({ visible: true, toastMessage: "Payment Updated.", variant: "Success" });
                    } else {
                        props.handleToastManager({ visible: true, toastMessage: "Error While Updaing Payment.", variant: "Danger" });
                    }
                })
        }
    };

    const onPaymentProcessDone = async (orderDetail) => {
        await dispatch(updateOrder({ ...orderDetail }))
            .then((response) => {
                if ((response.payload && !response.payload.error)) {
                    isDataChangeFlag = true;
                    getSingleOrderDetailById(singleOrderDetails)
                    props.handleToastManager({ visible: true, toastMessage: "Payment Updated.", variant: "Success" });
                } else {
                    props.handleToastManager({ visible: true, toastMessage: "Error While Updaing Payment.", variant: "Dangrt" });
                }
            })
    }

    const unPayOrder = async (isConfirm) => {
        if (isConfirm === 'YES') {
            await dispatch(updateOrder({ id: singleOrderDetails.id, paymentStatus: "UNPAID" }))
                .then(async (response) => {
                    if ((response.payload && !response.payload.error)) {
                        isDataChangeFlag = true;
                        await getSingleOrderDetailById(singleOrderDetails);
                        props.handleToastManager({ visibe: true, toastMessage: "Payment Updated.", variant: 'Success' })
                    } else {
                        props.handleToastManager({ visibe: true, toastMessage: "Error While Updaing Payment.", variant: 'Success' })
                    }
                    handleModalManager('', false);
                })
        } else {
            handleModalManager('', false)
        }
    }

    const onChangeCustomer = async (customerDetail) => {
        if (JSON.stringify(customerDetail) !== JSON.stringify(singleOrderDetails.customer)) {
            setLoading(true);
            await dispatch(updateOrder({ id: singleOrderDetails.id, customer: customerDetail }))
                .then(async (response) => {
                    if (response.payload && !response.payload.error) {
                        isDataChangeFlag = true;
                        getSingleOrderDetailById(singleOrderDetails)
                        props.handleToastManager({ visible: true, toastMessage: "Customer Updated.", variant: "Success" });
                    } else {
                        props.handleToastManager({ visible: true, toastMessage: "Error While Updaing Customer.", variant: "Danger" });
                    }
                })
        }
    };

    const onChangeOrderType = async (orderDetail) => {
        if (JSON.stringify(orderDetail) !== JSON.stringify(singleOrderDetails)) {
            setLoading(true);
            await dispatch(updateOrder({ id: singleOrderDetails.id, comments: orderDetail.comments || singleOrderDetails.comments, deliveryMethod: orderDetail.deliveryMethod, deliveryAddress: orderDetail.deliveryAddress || undefined, tags: orderDetail.tags || undefined, orderStage: "WIP" }))
                .then(async (response) => {
                    if ((response.payload && !response.payload.error)) {
                        isDataChangeFlag = true;
                        handleModalManager('', false);
                        props.handleToastManager({ visible: true, toastMessage: orderDetail.deliveryMethod !== singleOrderDetails.deliveryMethod ? "Order Type Changed." : orderDetail.deliveryAddress !== singleOrderDetails.deliveryAddress ? "Details Changed." : "", variant: "Success" });
                        setTimeout(() => {
                            props.onOrderTypeChange(orderDetail.deliveryMethod)
                        }, 1000)
                    } else {
                        props.handleToastManager({ visible: true, toastMessage: "Error While Updaing Order Type.", variant: "Danger" });
                    }
                })
        }
    };

    const changeOrderStatus = async (isConfirm) => {
        if (isConfirm === 'YES') {
            await dispatch(updateOrder({ id: singleOrderDetails.id, status: modalManage.modalName === "completeConfirm" ? "COMPLETE" : "CANCEL", orderStage: modalManage.modalName === 'completeConfirm' ? 'COMP' : "REJ" }))
                .then((response) => {
                    if ((response.payload && !response.payload.error)) {
                        isDataChangeFlag = true;
                        setTimeout(() => {
                            props.hideModal(true);
                        }, 1000);
                        props.handleToastManager({ visible: true, toastMessage: "Status Updated.", variant: "Success" })
                        handleModalManager('', false);
                    } else {
                        props.handleToastManager({ visible: true, toastMessage: "Error While Updating Status.", variant: "Danger" })
                    }
                });

        } else {
            handleModalManager('', false);
        }
    };

    const changeOrderStatusOnPartial = async (paymentType) => {
        await dispatch(updateOrder({ id: singleOrderDetails.id, status: "COMPLETE", orderStage: "COMP", adjustmentType: paymentType, paymentStatus: paymentType === "DISCOUNT" ? "PAID" : undefined, }))
            .then((response) => {
                if ((response.payload && !response.payload.error)) {
                    isDataChangeFlag = true;
                    setTimeout(() => {
                        props.hideModal(true);
                    }, 1000);
                    props.handleToastManager({ visible: true, toastMessage: "Status Updated.", variant: "Success" })
                    handleModalManager('', false);
                } else {
                    props.handleModalManager({ visible: true, toastMessage: "Error While Updating Status.", variant: "Danger" })
                }
            });
    };

    const printReport = () => {
        const miniBillCss = MINI_BILL_CSS_FORMAT;
        var divContents = document.getElementById("kitchenReport");
        let data = miniBillCss;
        var a = window.open('', '', 'height=500, width=500');
        if (a) {
            if (divContents) {
                a.document.write('<html>');
                a.document.write('<head>');
                a.document.write('<head>');
                a.document.write(data);
                a.document.write('</head>');
                a.document.write(divContents.innerHTML);
                a.document.write('</body></html>');
                a.document.close();
                a.print();
            }
        }
    };

    const customerDetailModalProps = { showModal: (modalManage.modalName === "customerDetailModal" && modalManage.visible === true), hideModal: () => handleModalManager('customerDetailModal', false), customerDetails: { ...singleOrderDetails.customer, orderDate: singleOrderDetails.orderDate } || {}, changeCustomerDetails: onChangeCustomer };
    const orderTypeModalProps = { showModal: (modalManage.modalName === "orderTypeModal" && modalManage.visible === true), orderDetails: singleOrderDetails, changeOrderDetails: onChangeOrderType, hideModal: () => handleModalManager('', false) };
    const paymentTypeModal = { showModal: (modalManage.modalName === "paymentTypeModal" && modalManage.visible === true), orderDetails: singleOrderDetails, hideModal: () => handleModalManager('', false), onPaymentDone: onPaymentProcessDone, allowPartialPayment: false };
    const confirmModalProps = { showModal: ((modalManage.modalName === "unpaidConfirm" || modalManage.modalName === "completeConfirm" || modalManage.modalName === 'cancelOrderConfirm') && modalManage.visible === true), hideModal: () => handleModalManager('', false), handleClick: modalManage.modalName === "unpaidConfirm" ? unPayOrder : changeOrderStatus, message: modalManage.modalName === 'unpaidConfirm' ? ' Are you sure you want to Un-Pay the Order?' : modalManage.modalName === 'cancelOrderConfirm' ? 'Are you sure you want to cancel the order?' : 'Are you sure you want to complete the order?' };
    const kitchenReportsProps = { orderType: singleOrderDetails.deliveryMethod, orderDetails: singleOrderDetails, tokenNo: undefined };

    return (
        <>
            <KitchenReport {...kitchenReportsProps} />
            {customerDetailModalProps.showModal ?
                <CustomerDetailModal  {...customerDetailModalProps} /> :
                orderTypeModalProps.showModal ?
                    <OrderTypeModal {...orderTypeModalProps} /> :
                    paymentTypeModal.showModal ?
                        <PaymentType {...paymentTypeModal} /> :
                        confirmModalProps.showModal ?
                            <ConfirmDialog {...confirmModalProps} /> :
                            modalManage.modalName === 'partialConfirmComplete' && modalManage.visible === true ?
                                <Modal size="md" backdrop="static" keyboard={false} centered show={(modalManage.modalName === 'partialConfirmComplete' && modalManage.visible === true)} onHide={() => handleModalManager('', false)}>
                                    <Modal.Header className='border-0 pb-0' closeButton />
                                    <Modal.Body>
                                        <Row className="g-3">
                                            <Col md={12} className="text-center">
                                                Do you want to consider balance as?
                                            </Col>
                                        </Row>
                                    </Modal.Body >
                                    <Modal.Footer className='border-0 pt-0'>
                                        <Row className='g-3 w-100 justify-content-center'>
                                            <Col md={3} lg={3} >
                                                <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={() => changeOrderStatusOnPartial("DISCOUNT")}>Discount</Button>
                                            </Col>
                                            <Col md={3} lg={3} >
                                                <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={() => changeOrderStatusOnPartial("CREDIT")}>Credit</Button>
                                            </Col>
                                        </Row>
                                    </Modal.Footer>
                                </Modal> :
                                <Modal backdrop="static" keyboard={false} className='modal-height' show={props.showModal} onHide={() => props.hideModal(isDataChangeFlag)} centered size='lg' scrollable>
                                    <Modal.Header className='border-0 pb-0' closeButton>
                                        <Row className='mx-0 g-3 w-100 align-items-center me-3'>
                                            <Col md={12} lg={6} className='d-sm-flex d-md-flex flex-wrap d-block align-items-center pe-4'>
                                                <Modal.Title className='fs-5 fw-bold me-auto text-warning'>#{singleOrderDetails.tempInvoiceNo}</Modal.Title>
                                            </Col>
                                            <Col xs={6} md={6} lg={3}>
                                                <Button className='light-btn w-100 text-truncate h-56 text-uppercase  d-flex align-items-center justify-content-center' variant="primary" onClick={() => handleModalManager('customerDetailModal', true)}>
                                                    <LuUser2 className='fs-16 me-1' />Customer
                                                </Button>
                                            </Col>
                                            <Col xs={6} md={6} lg={3}>
                                                <Button className='light-btn w-100 text-truncate h-56 text-uppercase  d-flex align-items-center justify-content-center' variant="primary" onClick={() => handleModalManager('orderTypeModal', true)} >
                                                    <Image className='me-2' width={16} src={singleOrderDetails.deliveryMethod === "DINE" ? dine : singleOrderDetails.deliveryMethod === "PICK" ? pick : singleOrderDetails.deliveryMethod === "DELIVERY" ? delivery : singleOrderDetails.deliveryMethod === "DRIVE" ? drive : plusIcon} />
                                                    <span className='d-block'>{singleOrderDetails.deliveryMethod === undefined ? "ORDER TYPE" : singleOrderDetails.deliveryMethod}</span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Modal.Header>
                                    {!loading ?
                                        <Modal.Body>
                                            <Row className='g-3'>
                                                <Col xs={12}>
                                                    <Row className="g-2">
                                                        <Col xs={6} sm={3} md={3} lg={3}>
                                                            <Form.Label htmlFor="floatingInputCustom" className='d-flex align-items-center'>
                                                                Customer Name<span className='ms-auto'>-</span>
                                                            </Form.Label>
                                                        </Col>
                                                        <Col xs={6} sm={3} md={3} lg={3}>
                                                            <Form.Label htmlFor="floatingInputCustom">
                                                                {singleOrderDetails?.customer?.name || "N/A"}
                                                            </Form.Label>
                                                        </Col>
                                                        {/*  {singleOrderDetails.comments ?
                                                            <>
                                                                <Col xs={6} sm={3} md={3} lg={3}>
                                                                    <Form.Label htmlFor="floatingInputCustom" className='d-flex align-items-center'>
                                                                        Sender Name<span className='ms-auto'>-</span>
                                                                    </Form.Label>
                                                                </Col>
                                                                <Col xs={6} sm={3} md={3} lg={3}>
                                                                    <Form.Label htmlFor="floatingInputCustom">
                                                                        {singleOrderDetails.comments ? String(singleOrderDetails.comments).split(',') ? String(singleOrderDetails.comments).split(',').length > 0 && (singleOrderDetails.comments).split(',')[0] : "-" : "-"}
                                                                    </Form.Label>
                                                                </Col>
                                                            </>
                                                            : null}*/}
                                                    </Row>
                                                    <Row className="g-2">
                                                        <Col xs={6} sm={3} md={3} lg={3}>
                                                            <Form.Label htmlFor="floatingInputCustom" className='d-flex align-items-center'>
                                                                Customer Mobile<span className='ms-auto'>-</span>
                                                            </Form.Label>
                                                        </Col>
                                                        <Col xs={6} sm={3} md={3} lg={3}>
                                                            <Form.Label htmlFor="floatingInputCustom">
                                                                {singleOrderDetails?.customer?.mobileNumber || "N/A"}
                                                            </Form.Label>
                                                        </Col>
                                                        {/*
                                                        {singleOrderDetails.comments ?
                                                            <>
                                                                <Col xs={6} sm={3} md={3} lg={3}>
                                                                    <Form.Label htmlFor="floatingInputCustom" className='d-flex align-items-center'>
                                                                        Sender Mobile<span className='ms-auto'>-</span>
                                                                    </Form.Label>
                                                                </Col>
                                                                <Col xs={6} sm={3} md={3} lg={3}>
                                                                    <Form.Label htmlFor="floatingInputCustom">
                                                                        {singleOrderDetails.comments ? String(singleOrderDetails.comments).split(',') ? String(singleOrderDetails.comments).split(',').length === 2 && String(singleOrderDetails.comments).split(',')[1] : "-" : "-"}
                                                                    </Form.Label>
                                                                </Col>
                                                            </> : null}*/}
                                                    </Row>
                                                </Col>
                                                <Col xs={12}>
                                                    <Table responsive className='inso-table'>
                                                        <thead>
                                                            <tr>
                                                                <th className='text-start' width="60%">Item Name</th>
                                                                <th className='text-center' width="20%">Qty</th>
                                                                <th className='text-end' width="20%"><FaIndianRupeeSign /> Price</th>
                                                                <th className='text-end' width="20%">S-total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {singleOrderDetails.items?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td className='text-start'>{item.prodAlias}</td>
                                                                    <td className='text-center'>{item.qty}</td>
                                                                    <td className='text-end'>{parseFloat(item.price).toFixed(2)}</td>
                                                                    <td className='text-end'>{parseFloat(item.price * item.qty).toFixed(2)}</td>
                                                                </tr>
                                                            ))}
                                                            <tr>
                                                                {/* <td className='text-end' colSpan='3'>
                                                                        Total: <span className='ms-2'>{singleOrderDetails.orderTotal || 0.00}</span>
                                                                    </td> */}
                                                                <td className='text-end' colSpan='4'>
                                                                    <Row className="g-0 ">
                                                                        <Col sm={12} md={6} lg={4} className='ms-auto'>
                                                                            <Row className="g-0 ">
                                                                                <Col xs={6} className='text-start'>
                                                                                    <span className='fs-14'>Sub-Total</span>
                                                                                </Col>
                                                                                <Col xs={6} className=' text-end'>
                                                                                    <span className='fs-14'>{parseFloat(singleOrderDetails.subTotal).toFixed(2) || 0.00}</span>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="g-0 ">
                                                                                <Col xs={6} className='text-start'>
                                                                                    <span className='fs-14'>Tax</span>
                                                                                </Col>
                                                                                <Col xs={6} className=' text-end'>
                                                                                    <span className='fs-14'>{parseFloat(singleOrderDetails.totalGst).toFixed(2) || 0.00}</span>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="g-0">
                                                                                <Col xs={6} className='text-start'>
                                                                                    <span className='fw-bold fs-16'>Total</span>
                                                                                </Col>
                                                                                <Col xs={6} className=' text-end'>
                                                                                    <span className='fw-bold fs-16'>{parseFloat(singleOrderDetails.orderTotal).toFixed(2) || 0.00}</span>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </Modal.Body>

                                        : <div className="d-flex align-items-center justify-content-center" style={{ height: '400px' }} >
                                            <img width='20%' src={loader} />
                                        </div>}
                                    <Modal.Footer className='border-0 pt-0'>
                                        <Row className='g-3 w-100 justify-content-end'>
                                            <Col xs={6} sm={6} md={6} lg={2}>
                                                <Button className='light-btn w-100 bg-light-grey' disabled={singleOrderDetails.paymentStatus === 'UNPAID'} variant="primary" onClick={() => singleOrderDetails.paymentStatus === 'PARTIAL' ? handleModalManager('partialConfirmComplete', true) : handleModalManager('completeConfirm', true)} >
                                                    Complete
                                                </Button>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={3}>
                                                <Button
                                                    onClick={() => handleModalManager('unpaidConfirm', true)}
                                                    disabled={paymentButtonState.unPayButton.disable}
                                                    className='light-btn w-100 bg-light-red text-white' variant="primary" >
                                                    Un-Pay <FaIndianRupeeSign className='' />{parseFloat(paymentButtonState.unPayButton.value).toFixed(2)}
                                                </Button>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={3}>
                                                <Button
                                                    disabled={paymentButtonState.payButton.disable}
                                                    className='light-btn w-100 bg-light-green text-white' variant="primary" onClick={() => payOrder(singleOrderDetails.paymentStatus)}>
                                                    Pay <FaIndianRupeeSign className='' />{parseFloat(paymentButtonState.payButton.value).toFixed(2)}
                                                </Button>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={2}>
                                                <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={printReport}>
                                                    Print
                                                </Button>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={2}>
                                                <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={() => handleModalManager('cancelOrderConfirm', true)} >
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Modal.Footer>
                                </Modal >
            }
        </>
    )
}

export default SingleOrderDetailModal;
import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap"
import { useDispatch } from "react-redux";
import NumberField from "../../../../components/common/App/NumberField";
import { updateInventory } from "../../../../actions/inventory/InventoryActions";

let isDataChangeFlag = false;
const EditAssemblyProductModal = ({ ...props }) => {
    const dispatch = useDispatch();

    const [loaderState, setLoaderState] = useState({ updateState: false, });
    const [assemblyProduct, setAssemblyProduct] = useState({});
    const [initialProduct, setInitialProduct] = useState({});

    useEffect(() => {
        if (props.showModal) {
            setAssemblyProduct({ ...props.selectedAssembly });
            setInitialProduct({ ...props.selectedAssembly });
        }
    }, [props.showModal, props.selectedAssembly]);

    const handleChange = (event) => {
        setAssemblyProduct({ ...assemblyProduct, [event.target.name]: event.target.value })
    };

    const updateProductAssemblyDetails = async () => {
        setLoaderState({ ...loaderState, updateState: true });
        const payload = { ...assemblyProduct, 
            storeId: props.storeId, 
            warningLimit: assemblyProduct?.warningLimit ? assemblyProduct?.warningLimit : "0", 
            criticalLimit: assemblyProduct?.criticalLimit ? assemblyProduct?.criticalLimit : "0" 
        }
        await dispatch(updateInventory(payload)).then((response) => {
            if ((response.payload && !response.payload.error)) {
                isDataChangeFlag = true;
                props.handleToastManager({ visible: true, toastMessage: 'Product Updated.', variant: "Success" })
            } else {
                props.handleToastManager({ visible: true, toastMessage: 'Erorr While Updating Product.', variant: "Danger" })
            }
            setLoaderState({ ...loaderState, updateState: false });
        })
    };

    const isDataChanged = () => {
        // Check if the current state of the product is different from the initial values
        return JSON.stringify(assemblyProduct) !== JSON.stringify(initialProduct);
    };

    return (
        <Modal size="md" centered backdrop="static" keyboard={false} show={props.showModal} onHide={() => props.hideModal(isDataChangeFlag)}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Edit Inventory Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col md={12}>
                        <Form.Floating className="">
                            <Form.Control type="text" autoComplete='off' name="prodAlias" id="floatingInputCustom" placeholder="Product Price" value={assemblyProduct.prodAlias} defaultValue={assemblyProduct.prodAlias} onChange={(event) => setAssemblyProduct({ ...assemblyProduct, [event.target.name]: event.target.value })} disabled={true} />
                            <Form.Label htmlFor="floatingInputCustom">Product Name</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={12}>
                        <Form.Floating className="">
                            <NumberField decimals={2} autoComplete='off' name="warningLimit" id="floatingInputCustom" placeholder="Warning Limit" value={assemblyProduct.warningLimit} defaultValue={assemblyProduct.warningLimit} onChange={handleChange} />
                            <Form.Label htmlFor="floatingInputCustom">Warning Limit</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={12} className="">
                        <Form.Floating className="">
                            <NumberField decimals={2} autoComplete='off' name="criticalLimit" id="floatingInputCustom" placeholder="Critical Limit" value={assemblyProduct.criticalLimit} defaultValue={assemblyProduct.criticalLimit} onChange={handleChange} />
                            <Form.Label htmlFor="floatingInputCustom">Critical Limit</Form.Label>
                        </Form.Floating>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-0 pt-0'>
                <Row className='g-3 w-100 justify-content-end'>
                    <Col md={3} lg={3}>
                        {loaderState.updateState === false ?
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={updateProductAssemblyDetails} disabled={!isDataChanged()}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>}
                    </Col>

                </Row>
            </Modal.Footer>
        </Modal >
    )
}

export default EditAssemblyProductModal;
import React from "react";
import { standardDateFormat } from "../../../../util/utility-helper";
import { Badge, Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import loader from '../../../../assets/images/loading-img.gif';
import { TbEdit } from "react-icons/tb";
import { PiExportBold } from "react-icons/pi";

const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const OrderTable = ({ ...props }) => {
    return (
        <Table responsive className='inso-table'>
            <thead>
                <tr>
                    <th className='text-start' width='10%'>#Invoice</th>
                    <th className='text-center' width='12%'>Order Date</th>
                    <th className='text-center' width='12%'>Order Type</th>
                    <th className='text-center' width='15%'>Customer/Mobile</th>
                    <th className='text-center' width='12%'>Order Total</th>
                    <th className='text-center' width='12%'>Status</th>
                    <th className='text-center' width='12%'>Payment Status</th>
                    <th className='text-center' width='12%'>Action</th>
                    <th className="text-center position-relative" >
                        <Button className="mt-1 px-2 pt-0 py-1 position-absolute export-th-btn" variant="secondary" type="button" onClick={props.exportToExcel}>
                            <PiExportBold className="fs-16 " />
                        </Button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {props.loading !== true ?
                    props.orderList.length !== 0 ?
                        props.orderList.map((items, index) => {
                            return (
                                <tr key={index}>
                                    <td className='text-start'>{items.invoiceNo}</td>
                                    <td className='text-center'>{standardDateFormat(items.orderDate, dateFormat + 'HH:mm:ss')}</td>
                                    {items.deliveryMethod === "DINE" ?
                                        <td className='text-center'>Dining</td> :
                                        items.deliveryMethod === "DELIVERY" ?
                                            <td className='text-center'>Delivery</td> :
                                            items.deliveryMethod === "PICK" ?
                                                <td className='text-center'>Pick Up</td> :
                                                items.deliveryMethod === "DRIVE" ?
                                                    <td className='text-center'>Drive</td> :
                                                    null}
                                    <td className='text-center'>{items?.customer?.name || items?.customer?.mobileNumber ? <>{items?.customer?.name + "/" + items?.customer?.mobileNumber}</> : "N/A"}</td>
                                    <td className='text-center'>{items.orderTotal}</td>
                                    <td className='text-center'>
                                        {items.status === "PLACED" ?
                                            <Badge bg="warning">Placed</Badge> :
                                            items.status === "COMPLETED" ?
                                                <Badge bg="success">Completed</Badge> :
                                                items.status === "CANCELLED" ?
                                                    <Badge bg="danger">Cancelled</Badge> : null}
                                    </td>
                                    <td className='text-center'>
                                        {items.paymentStatus === "UNPAID" || items.paymentStatus === "PARTIAL" ?
                                            <Badge bg="danger">Credit</Badge> : <Badge bg="success">Paid</Badge>}
                                    </td>
                                    <td colSpan="2" className='text-center'>
                                        <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
                                            <Button
                                                className="border-0 bg-transparent p-0"
                                                onClick={() => props.getSelectedRow(items)}
                                            >
                                                <TbEdit className="fs-16 text-dark " />
                                                {/* <LuEye className="fs-16 text-dark " /> */}
                                            </Button>
                                        </OverlayTrigger>
                                    </td>
                                </tr>
                            )
                        }) :
                        props.orderList.length === 0 ?
                            <tr>
                                <td colSpan="12" className="text-center">No order available</td>
                            </tr> : null
                    :
                    <tr  >
                        <td colSpan='12'>
                            <div className="d-flex align-items-center justify-content-center" style={{ height: '508px' }} >
                                <img width='20%' src={loader} />
                            </div>
                        </td>
                    </tr>
                }
            </tbody>
        </Table>
    )
}
export default OrderTable
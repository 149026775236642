import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Nav, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { fetchTerminalData, updateStore, uploadResource } from "../../../../../actions/admin/AdminActions";
import { getAllDeliveryMethods, getPaymentMethods } from "../../../../../actions/billdesk/BillDeskActions";
import BasicEditStorePane from "./widgets/BasicEditStorePane";
import SettingEditStorePane from "./widgets/SettingEditStorePane";
import StoreEcommerceMedia from "./widgets/StoreEcommerceMedia";
import { validateFields } from "../../../../../util/utility-helper";

const initalButtonState = { loading: false, disable: true };
let storeMedia = { mediaPromotions: [], mediaCruisers: [] };
let isStoreMediaChange = false;
let isStoreLogoChange = false;
const endPoint = process.env.REACT_APP_API_BASE_ENDPOINT;
const mandotoryFields = ['storeType', 'name', 'coverageRadius', 'address', 'location', 'zipCode', 'phoneNumber', 'deliveryMethods', 'paymentMethods','locationLong','locationLat'];
const EditStoreModal = ({ showModal, hideModal, handleToastManager, selectedStore, fetchStoreList, storeList }) => {
    const dispatch = useDispatch();
    const [addStoreData, setAddStoreData] = useState({});
    const [buttonState, setButtonState] = useState({ ...initalButtonState });
    const [deliveryMethods, setDeliveryMethods] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [userAssignTerminal, setUserAssignTerminal] = useState([]);
    const [activeTab, setActiveTab] = useState('Basic');

    const currentMandatoryFields = (addStoreData.storeType) === 'ASSEMBLY'
        ? mandotoryFields.filter((field) => field !== 'deliveryMethods' && field !== 'paymentMethods')
        : mandotoryFields;

    const handleTabSelect = (key) => {
        setActiveTab(key);
    };

    useEffect(() => {
        if (showModal) {
            setAddStoreData({ ...selectedStore, storeLogo: selectedStore.logo ? endPoint + selectedStore.logo : null })
            setButtonState({ ...initalButtonState });
            setActiveTab('Basic');
            fetchTerminalDetails(selectedStore);
            getDeliveryMethods();
            fetchPaymentMethods();
        }
    }, [showModal, selectedStore]);

    const fetchTerminalDetails = async (selectedStore) => {
        if (selectedStore) {
            await dispatch(fetchTerminalData(selectedStore.id)).then((response) => {
                if (response.payload && !response.payload.error) {
                    setUserAssignTerminal(response.payload);
                }
            })
        }
    };

    const getDeliveryMethods = async () => {
        await dispatch(getAllDeliveryMethods())
            .then((response) => {
                if (response.payload) {
                    setDeliveryMethods(response.payload);
                }
            });
    };

    const fetchPaymentMethods = async () => {
        await dispatch(getPaymentMethods()).then((response) => {
            if (response.payload && !response.payload.error) {
                setPaymentMethods(response.payload);
            }
        })
    };

    const onSubmit = async () => {
        setButtonState({ ...buttonState, loading: true });
        let newStoreData = { ...addStoreData };
        if (isStoreLogoChange) {
            const formData = new FormData();
            formData.append('files', newStoreData.logo);
            formData.append('fileName', newStoreData.id + 'store' + "_" + newStoreData.storeKey)
            await dispatch(uploadResource({ formData: formData, mediaType: "storeLogo" })).then((response) => {
                if (response.payload && response.payload) {
                    newStoreData.logo = response.payload.resourcePath;
                }
            })
        }
        await dispatch(updateStore({ ...newStoreData }))
            .then((response) => {
                if (response.payload && !response.payload.error) {
                    fetchStoreList();
                    hideModal(true);
                    handleToastManager({ visible: true, toastMessage: 'Store Updated.', variant: "Success" });
                } else {
                    handleToastManager({ visible: true, toastMessage: response.payload.error, variant: "Danger" });
                }
                if (isStoreMediaChange === true) {
                    handleStoreEcomMedia()
                }
                setButtonState({ ...buttonState, loading: false });
            })
    };

    const handleChange = (event) => {
        setAddStoreData({ ...addStoreData, [event.target.name]: event.target.value });
        setButtonState((prevState) => ({
            ...prevState,
            disable: validateFields(currentMandatoryFields, { ...addStoreData, [event.target.name]: event.target.value }),
        }));
    };

    const handleMethodChange = (event) => {
        const { name, value } = event.target;
        let newStoreData = { ...addStoreData };
        if (String(newStoreData[name]).includes(value)) {
            newStoreData[name] = String(newStoreData[name]).replace(String(newStoreData[name]).includes('-' + value) ? '-' + value : value, '')
        } else {
            if (newStoreData[name] !== '') {
                newStoreData[name] = String(newStoreData[name]).concat('-' + value);
            } else {
                newStoreData[name] = value;
            }
        }
        setAddStoreData({ ...newStoreData })
        setButtonState((prevState) => ({
            ...prevState,
            disable: validateFields(currentMandatoryFields, newStoreData),
        }));
    };

    const generateStoreKey = () => {
        setButtonState((prevState) => ({
            ...prevState,
            disable: validateFields(currentMandatoryFields, { ...addStoreData, storeKey: addStoreData.storeType !== 'ASSEMBLY' ? String(addStoreData.name).substring(0, 3).toUpperCase() : null }),
        }));
        setAddStoreData({ ...addStoreData, storeKey: addStoreData.storeType !== 'ASSEMBLY' ? String(addStoreData.name).substring(0, 3).toUpperCase() : null });
    };

    const handleStoreMedia = (newStoreMedia) => {
        storeMedia = newStoreMedia;
        isStoreMediaChange = true;
        setButtonState({
            ...buttonState,
            disable: validateFields(currentMandatoryFields, addStoreData)
        })
    };

    const handleStoreEcomMedia = () => {
        isStoreMediaChange = false;
        storeMedia['mediaPromotions'].forEach(async prom => {
            const formData = new FormData();
            formData.append("files", prom.file);
            formData.append("fileName", addStoreData.storeKey + "_" + prom.fileName);
            await dispatch(uploadResource({ mediaType: 'mediaPromotions', formData: formData })).then((response) => {
                if (response.payload && !response.payload.error) {
                    fetchStoreList();
                    handleToastManager({ visible: true, toastMessage: "Media Uploaded.", variant: "Success" });
                } else {
                    handleToastManager({ visible: true, toastMessage: response.payload.error, variant: "Danger" });
                }
            })
        })
        storeMedia['mediaCruisers'].forEach(async prom => {
            const formData = new FormData();
            formData.append("files", prom.file);
            formData.append("fileName", addStoreData.storeKey + "_" + prom.fileName);
            await dispatch(uploadResource({ mediaType: 'mediaCruisers', formData: formData })).then((response) => {
                if (response.payload && !response.payload.error) {
                    fetchStoreList();
                    handleToastManager({ visible: true, toastMessage: "Media Uploaded.", variant: "Success" });
                } else {
                    handleToastManager({ visible: true, toastMessage: response.payload.error, variant: "Danger" });
                }
            })
        })
    };

    const handleStoreLogo = (event, action) => {
        if (action === 'CHANGE') {
            const file = event.target.files[0];
            if (file) {
                isStoreLogoChange = true;
                setAddStoreData({ ...addStoreData, storeLogo: URL.createObjectURL(file), logo: file });
                setButtonState((prevState) => ({
                    ...prevState,
                    disable: validateFields(currentMandatoryFields, { ...addStoreData, storeLogo: URL.createObjectURL(file), logo: file }),
                }));
            }
        } else {
            setAddStoreData({ ...addStoreData, storeLogo: null, logo: null });
            setButtonState((prevState) => ({
                ...prevState,
                disable: validateFields(currentMandatoryFields, { ...addStoreData, storeLogo: null, logo: null }),
            }));
        }

    };

    return (
        <Modal size="lg" centered backdrop="static" keyboard={false} show={showModal} onHide={hideModal}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Edit Store</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3" >
                    <Col md={12}>
                        <Nav className="card-tabs" activeKey={activeTab} onSelect={handleTabSelect}>
                            <Nav.Item>
                                <Nav.Link eventKey="Basic">Basic</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Setting">Setting</Nav.Link>
                            </Nav.Item>
                            {addStoreData.storeType === 'ONLINE' ?
                                <Nav.Item>
                                    <Nav.Link eventKey="StoreEcomMedia">Store Ecommerce Media</Nav.Link>
                                </Nav.Item> : null}
                        </Nav>
                    </Col>
                    {activeTab === 'Basic' && (
                        <BasicEditStorePane addStoreData={addStoreData} handleChange={handleChange} onStoreNameChange={generateStoreKey} userAssignTerminal={userAssignTerminal} handleStoreLogo={handleStoreLogo} />
                    )}
                    {activeTab === 'Setting' && (
                        <SettingEditStorePane deliveryMethods={deliveryMethods} paymentMethods={paymentMethods} addStoreData={addStoreData} storeList={storeList} handleChange={handleChange} handleMethodChange={handleMethodChange} />
                    )}
                    {activeTab === 'StoreEcomMedia' && (
                        <StoreEcommerceMedia store={addStoreData} handleToastManager={handleToastManager} handleStoreMedia={handleStoreMedia} />
                    )}
                </Row>
            </Modal.Body>
            <Modal.Footer className="border-0 pt-0">
                <Row className="g-3 w-100 justify-content-end">
                    <Col md={3} lg={2} className="m-0">
                        {!buttonState.loading ?
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={onSubmit} disabled={buttonState.disable}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    )
}

export default EditStoreModal;
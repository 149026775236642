import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import NumberField from "../../../../../../components/common/App/NumberField";
import { BiPencil } from "react-icons/bi";
import proImg from '../../../../../../assets/images/upload.jpg';
import { IoIosCloseCircleOutline } from "react-icons/io";

const BasicAddStorePane = ({ addStoreData, handleChange, onStoreNameChange, handleStoreLogo }) => {
    const uploadLogo = () => {
        document.getElementById('addStoreLogo').click();
    };
    return (
        <>
            <Row className="w-100 mt-3 g-1">
                <Col sm={12} md={3}>
                    <div className="w-100 d-flex flex-wrap align-items-start">
                        <div className="pro-main mx-auto mx-lg-2 mb-0" >
                            <div className="pro-menu" onClick={uploadLogo}>
                                <div className="pro-overlay">
                                    <BiPencil className='pro-icon' />
                                </div>
                                <img className="pro-image" src={addStoreData.storeLogo || proImg} />
                                <input type="file" i accept=".jpg,.jpeg,.png" name="files" id="addStoreLogo" hidden={true} onChange={(event) => handleStoreLogo(event, 'CHANGE')} />
                            </div>
                            {addStoreData.storeLogo ?
                                <div className="pro-trash" onClick={() => handleStoreLogo('DELETE')}><IoIosCloseCircleOutline /></div> : null}
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={9}>
                    <Row className="g-3">
                        <Col md={6}>
                            <Form.Floating>
                                <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    name="name"
                                    id="nameInput"
                                    placeholder="Name"
                                    value={addStoreData.name}
                                    onChange={handleChange}
                                    onBlur={onStoreNameChange}
                                />
                                <Form.Label htmlFor="floatingInputCustom">Name<span className="text-danger">*</span></Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={6}>
                            <Form.Floating>
                                <Form.Select
                                    name="storeType"
                                    id="storeType"
                                    placeholder="Store Type"
                                    value={addStoreData.storeType}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled >Select Store Type</option>
                                    <option value="OFFLINE" selected={addStoreData.storeType === 'OFFLINE'}>OFFLINE</option>
                                    <option value="ASSEMBLY" selected={addStoreData.storeType === 'ASSEMBLY'}>ASSEMBLY</option>
                                    <option value="ONLINE" selected={addStoreData.storeType === 'ONLINE'}>ONLINE</option>
                                </Form.Select>
                                <Form.Label htmlFor="storeType">Store Type<span className="text-danger">*</span></Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={6}>
                            <Form.Floating>
                                <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    name="storeKey"
                                    id="storeKey"
                                    placeholder="Name"
                                    value={addStoreData.storeKey}
                                    maxLength={3}
                                    disabled={addStoreData.storeType === 'ASSEMBLY'}
                                    onChange={handleChange}
                                />
                                <Form.Label htmlFor="floatingInputCustom">Store Key<span className="text-danger">*</span></Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={6}>
                            <Form.Floating className="">
                                <NumberField
                                    type="text"
                                    autoComplete="off"
                                    name="coverageRadius"
                                    id="coverageRadius"
                                    placeholder="CoverageRadius"
                                    value={addStoreData.coverageRadius}
                                    onChange={handleChange}
                                />
                                <Form.Label htmlFor="floatingInputCustom">Coverage Radius<span className="text-danger">*</span></Form.Label>
                            </Form.Floating>
                        </Col>

                    </Row>
                </Col>
            </Row>

            <Col md={12}>
                <Form.Floating className="">
                    <Form.Control
                        type="text"
                        autoComplete="off"
                        name="address"
                        id="Address"
                        placeholder="Address"
                        value={addStoreData.address}
                        onChange={handleChange}
                    />
                    <Form.Label htmlFor="floatingInputCustom">Address<span className="text-danger">*</span></Form.Label>
                </Form.Floating>
            </Col>
            <Col md={4}>
                <Form.Floating className="">
                    <Form.Control
                        type="text"
                        autoComplete="off"
                        name="location"
                        id="Location"
                        placeholder="Location"
                        value={addStoreData.location}
                        onChange={handleChange}
                    />
                    <Form.Label htmlFor="floatingInputCustom">Location<span className="text-danger">*</span></Form.Label>
                </Form.Floating>
            </Col>
            <Col md={4}>
                <Form.Floating className="">
                    <NumberField
                        type="text"
                        autoComplete="off"
                        name="locationLat"
                        id="locationLat"
                        placeholder="Latitude"
                        decimals={8}
                        value={addStoreData.locationLat}
                        onChange={handleChange}
                    />
                    <Form.Label htmlFor="floatingInputCustom">Latitude<span className="text-danger">*</span></Form.Label>
                </Form.Floating>
            </Col>
            <Col md={4}>
                <Form.Floating className="">
                    <NumberField
                        type="text"
                        autoComplete="off"
                        name="locationLong"
                        id="locationLong"
                        placeholder="Longitude"
                        decimals={8}
                        value={addStoreData.locationLong}
                        onChange={handleChange}
                    />
                    <Form.Label htmlFor="floatingInputCustom">Longitude<span className="text-danger">*</span></Form.Label>
                </Form.Floating>
            </Col>
            <Col md={4}>
                <Form.Floating className="">
                    <NumberField
                        type="text"
                        autoComplete="off"
                        name="pinCode"
                        id="pinCode"
                        placeholder="pinCode"
                        value={addStoreData.pinCode}
                        onChange={handleChange}
                        maxLength={6}
                    />
                    <Form.Label htmlFor="floatingInputCustom">PIN Code<span className="text-danger">*</span></Form.Label>
                </Form.Floating>
            </Col>
            <Col md={4}>
                <Form.Floating className="">
                    <NumberField
                        autoComplete="off"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="PhoneNumber"
                        value={addStoreData.phoneNumber}
                        onChange={handleChange}
                        maxLength={10}
                    />
                    <Form.Label htmlFor="floatingInputCustom">Phone Number<span className="text-danger">*</span></Form.Label>
                </Form.Floating>
            </Col>
        </>
    );
};

export default BasicAddStorePane;

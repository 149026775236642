import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner, InputGroup } from "react-bootstrap";
import { BiPencil } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { addExistingProduct, changeProductImage, createProduct, getAllProducts, updateProduct } from "../../../../../actions/billdesk/BillDeskActions";
import proImg from '../../../../../assets/images/upload.jpg';
import videoImg from '../../../../../assets/images/play-button.png';
import NumberField from "../../../../../components/common/App/NumberField";
import { IoIosCloseCircleOutline } from "react-icons/io";
import AutoCompleter from "../../../../../components/common/App/AutoCompleter";
import { uomData } from "../../../../../util/constants";
import { validateURL } from "../../../../../util/utility-helper";

const intialProductState = {
    prodAlias: "", price: "", hsn: "", isPriceGstInc: "N", perGst: "", uom: "", image1: "N", image2: "N", image3: "N", image4: "N", image5: "N",
    itemNumber: "", description: "", categories: '', tags: "", displayOrder: "", status: "ACTIVE", syncStatus: "N", icon: "", uploadSwitch: 'N', onlineSwitch: 'N',
};
let currentSelImg = null;
const initialFileListState = [
    { 'fileName': null, "files": null, icon: null },
    { 'fileName': null, "files": null, icon: null },
    { 'fileName': null, "files": null, icon: null },
    { 'fileName': null, "files": null, icon: null },
    { 'fileName': null, "files": null, icon: null }
]
const mandatoryFields = ['prodAlias', 'price', 'perGst', 'isPriceGstInc', 'uom', 'categories'];

const AddProduct = ({ showModal, hideModal, selectedCategory, categoryList, handleToastManager, selProductType }) => {
    const dispatch = useDispatch();

    const [currentProduct, setCurrentProduct] = useState({ ...intialProductState, productType: selProductType });
    const [video, setVideo] = useState({})

    const [mainCat, setMainCat] = useState("");
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCat, setSelectedSubCat] = useState(""); // State for selected subcategory

    const [buttonState, setButtonState] = useState({ disable: true, loader: false });
    const [fileList, setFileList] = useState([...initialFileListState]);
    const [addExistProdFlag, setAddExistProdFlag] = useState(false);

    const [productList, setProductList] = useState([]);


    useEffect(() => {
        if (showModal) {
            setButtonState({ disable: true, loader: false });
            setSelectedSubCat('');
            setMainCat('');
            setSubCategories([]);
            setCurrentProduct({ ...intialProductState, productType: selProductType });
            fetchAllProductList();
        }
    }, [showModal]);

    const handleCloseModal = () => {
        // Reset the currentProduct state to its initial state
        setCurrentProduct({ ...intialProductState, productType: selProductType });

        // Reset the addExistProdFlag state to its initial state
        setAddExistProdFlag(false);


        // Call the original hideModal function
        hideModal();
    };


    useEffect(() => {
        if (selectedCategory) {
            setMainCat(selectedCategory);
            const selectedCategoryObj = categoryList.find(cat => cat.catAlias === selectedCategory);
            if (selectedCategoryObj) {
                setSubCategories(selectedCategoryObj.categories || []);
                setCurrentProduct(({
                    ...intialProductState,
                    categories: { id: selectedCategoryObj.categories && selectedCategoryObj.categories.length > 0 ? selectedCategoryObj.categories[0].id : '', },
                    productType: selProductType
                }));
                setSelectedSubCat(""); // Reset selected subcategory when main category changes
            } else {
                if (categoryList && categoryList.length > 0 && categoryList.length === 0) {
                    setSubCategories(categoryList[0].categories || []);
                    if (categoryList[0].categories && categoryList[0].categories.length > 0) {
                        setCurrentProduct({ ...intialProductState, categories: { 'id': categoryList[0].categories[0].id }, productType: selProductType })
                    }
                }
            }
        }
    }, [selectedCategory, categoryList, selProductType]);

    const onHandleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setCurrentProduct(prevState => ({
            ...prevState,
            [name]: type === "checkbox" ? (checked ? "Y" : "N") : value
        }));
        validateFields({ ...currentProduct, [name]: type === "checkbox" ? (checked ? "Y" : "N") : value })
    };

    const fetchAllProductList = async () => {
        await dispatch(getAllProducts())
            .then((response) => {
                if (response.payload && !response.payload.error) {
                    setProductList(response.payload);
                }
            })
    };

    const onCategorySelect = (event) => {
        const selectedCatAlias = event.target.value;
        const selectedCat = categoryList.find(cat => cat.catAlias === selectedCatAlias);
        if (selectedCat) {
            setCurrentProduct({ ...currentProduct, categories: { 'id': selectedCat.categories && selectedCat.categories.length > 0 ? selectedCat.categories[0].id : "" } })
            setMainCat(selectedCatAlias);
            setSubCategories(selectedCat.categories || []);
            setSelectedSubCat(selectedCat.categories && selectedCat.categories.length > 0 ? selectedCat.categories[0].id : ""); // Reset selected subcategory
            validateFields({ ...currentProduct, categories: { 'id': selectedCat.id } });
        }
    };


    const validateFields = (newProductdetais) => {
        let disableState = false;
        mandatoryFields.forEach(fields => {
            if (JSON.stringify(newProductdetais).includes(`"${fields}":""`)) {
                disableState = true
            }
        });
        setButtonState({ ...buttonState, disable: disableState });
    }

    const onSubCategorySelect = (event) => {
        const selectedSubCatId = event.target.value;
        setSelectedSubCat(selectedSubCatId); // Update selected subcategory
        setCurrentProduct(prevState => ({
            ...prevState,
            categories: { id: selectedSubCatId } // Only one subcategory ID
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {

            if (currentProduct?.onlineSwitch === "Y" && currentProduct?.videoUrl && !validateURL(currentProduct.videoUrl)) {
                handleToastManager({ visible: true, toastMessage: 'Invalid Youtube video url', variant: "Warning" })
                return;
            }

            setButtonState({ ...buttonState, loader: true });
            if (!addExistProdFlag) {
                const payload = {
                    ...currentProduct,
                    icon: "",
                };
                await dispatch(createProduct(payload)).then(async (response) => {
                    if ((response.payload && !response.payload.error)) {
                        if (currentProduct.uploadSwitch === 'Y') {
                            await uploadImageOnProduct(fileList, 0, response.payload)
                        }
                        else {
                            let newCreatedProduct = { ...response.payload };
                            if (currentProduct.fileSrc) {
                                const formData = new FormData();
                                formData.append("files", currentProduct.fileSrc);
                                formData.append("fileName", response?.payload.id + "_1");
                                await dispatch(changeProductImage(formData)).then(async (imgRes) => {
                                    if ((imgRes.payload && !response.payload.error)) {
                                        newCreatedProduct = { ...newCreatedProduct, icon: imgRes.payload.resourcePath };
                                    } else {
                                        handleToastManager({ visible: true, toastMessage: 'Error while uploading image.', variant: "Danger" });
                                    }
                                })
                            }
                            await dispatch(updateProduct({ ...newCreatedProduct })).then((updatedRes) => {
                                if ((updatedRes.payload && !updatedRes.payload.error)) {
                                    handleToastManager({ visible: true, toastMessage: 'Product created successfully.', variant: "Success" });
                                    setAddExistProdFlag(false)
                                    setTimeout(() => {
                                        hideModal(true);
                                        setFileList([...initialFileListState]);
                                    }, 500);
                                }
                            })
                        }
                        if (video.icon) {
                            if (!currentProduct.onlineSwitch || currentProduct.onlineSwitch === 'N') {
                                const formData = new FormData();
                                formData.append('fileName', response.payload.id + "_v");
                                formData.append('files', video.icon);
                                if (formData) {
                                    await dispatch(changeProductImage(formData)).then(async (uploadRes) => {
                                        if ((uploadRes.payload && !uploadRes.payload.error)) {
                                            await dispatch(updateProduct({ id: response.payload.id, videoUrl: "Y" })).then((response) => {
                                                if (response.payload && !response.payload.error) {
                                                    handleToastManager({ visible: true, toastMessage: 'Video uploaded.', variant: "Success" });
                                                } else {
                                                    handleToastManager({ visible: true, toastMessage: 'Failed to update product.', variant: "Danger" })
                                                }
                                            })
                                        }
                                    })
                                }
                            }
                        }

                    } else {
                        // Display the error message from the response
                        const errorMessage = response.payload?.error || "Error While Adding Product";
                        handleToastManager({ visible: true, toastMessage: errorMessage, variant: 'Danger' });
                    }
                    setButtonState({ ...buttonState, loader: false });
                })
            } else {
                if (selectedCategory && selectedCategory && selectedCategory.length > 0) {
                    const foundCat = categoryList && categoryList.find(e => e.catAlias === selectedCategory[0].value);
                    await dispatch(addExistingProduct({ productId: currentProduct.id, categoryId: foundCat.categories[0].id })).then((response) => {
                        if (response.payload && !response.payload.error) {
                            handleToastManager({ visible: true, toastMessage: 'Product created successfully.', variant: "Success" });
                            setAddExistProdFlag(false)
                            setTimeout(() => {
                                hideModal(true);
                            }, 500);
                        } else {
                            handleToastManager({ visible: true, toastMessage: 'Failed to create Product. Please try again.', variant: "Danger" })

                        }
                    })
                }
            }
        } catch (error) {
        } finally {
            setButtonState({ ...buttonState, save: false });
        }
    };

    const uploadImageOnProduct = async (fileArray = [], index = 0, newCreatedProduct) => {
        if (fileArray.length > 0 && fileArray[index]) {
            const fileChangePayload = { file: fileArray[index].files, fileName: newCreatedProduct?.id };
            if (fileChangePayload.file) {
                const formData = new FormData();
                formData.append("files", fileChangePayload.file);
                formData.append("fileName", (index + 1) === 1 ? fileChangePayload.fileName + "_1" : fileChangePayload.fileName + "_" + (index + 1));
                await dispatch(changeProductImage(formData))
                    .then(async (uploadResponse) => {
                        if ((uploadResponse.payload && !uploadResponse.payload.error)) {
                            let updateProductPayload = { id: newCreatedProduct?.id, [`image${index + 1}`]: 'Y', videoUrl: newCreatedProduct.videoUrl || null };
                            if ((index + 1) === 1) {
                                updateProductPayload.icon = uploadResponse.payload.resourcePath;
                            }
                            await dispatch(updateProduct({ ...updateProductPayload })).then((updatedResponse) => {
                                if ((updatedResponse.payload && !updatedResponse.payload.error)) {
                                    if (fileArray.length - 1 > index) {
                                        uploadImageOnProduct(fileArray, (index + 1), newCreatedProduct)
                                    } else {
                                        handleToastManager({ visible: true, toastMessage: 'Product created successfully', variant: "Success" })
                                        setTimeout(() => {
                                            hideModal(true);
                                            setFileList([...initialFileListState]);
                                        }, 500)
                                    }
                                } else {
                                    handleToastManager({ visible: true, toastMessage: 'Failed to update product.', variant: "Danger" })
                                }
                                setButtonState({ ...buttonState, loader: false });
                            })
                        } else {
                            handleToastManager({ visible: true, toastMessage: 'Failed to upload image.', variant: "Danger" });
                            setButtonState({ ...buttonState, loader: false });
                        }
                    })
            } else {
                if (fileArray.length - 1 > index) {
                    uploadImageOnProduct(fileArray, (index + 1), newCreatedProduct)
                } else {
                    handleToastManager({ visible: true, toastMessage: 'Product created successfully', variant: "Success" })
                    setTimeout(() => {
                        setFileList([...initialFileListState]);
                        hideModal(true);
                    }, 500)
                }
            }
        }
    }

    const openFileUploader = (fileType) => {
        document.getElementById(fileType).click()
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
        if (file) {
            if (currentProduct.uploadSwitch === 'Y') {
                let updatedFileList = [...fileList];

                // Find the first slot that is empty by checking for files property being empty, null, or undefined
                const firstAvailableIndex = updatedFileList.findIndex((fileItem) => fileItem.icon === null);


                if (firstAvailableIndex !== -1) {
                    // Update the file at the first available slot
                    updatedFileList[firstAvailableIndex] = { fileName: `${currentProduct.id}_${firstAvailableIndex + 1}`, files: file, icon: URL.createObjectURL(file), };

                    // Update the corresponding `currentProduct` property
                    setCurrentProduct({ ...currentProduct, [`image${firstAvailableIndex + 1}`]: 'Y', });

                    // Update the state for fileList
                    setFileList([...updatedFileList]);

                    // Enable the save button

                }
            } else {
                setCurrentProduct({ ...currentProduct, icon: URL.createObjectURL(file), iconSrc: file, image1: 'Y' })
            }
        }
    };

    const removeFile = (selIndx) => {
        let fileListClone = [...fileList];
        if (selIndx !== null && selIndx !== undefined) {
            if (fileListClone && fileListClone.length > 0 && fileListClone[selIndx]) {
                fileListClone[selIndx] = { ...fileListClone[selIndx], icon: null };
                setFileList([...fileListClone]);
            }
        } else {
            setCurrentProduct({ ...currentProduct, icon: null, image1: 'N' })
        }
    }

    const openVideoUploader = () => {
        document.getElementById('videoUploader').click();
    };

    const handleVideoChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            setVideo({ src: URL.createObjectURL(event.target.files[0]), icon: event.target.files[0], fileName: event.target.files[0].name })
        }
    };

    const handleOnlineVideo = (event) => {
        setCurrentProduct({ ...currentProduct, [event.target.name]: event.target.value })
    };

    return (
        <Modal size="lg" backdrop="static" keyboard={false} scrollable centered show={showModal} onHide={handleCloseModal}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Add Product</Modal.Title>
            </Modal.Header>
            <>
                <Modal.Body style={{ minHeight: "450px" }}>
                    <Row className="g-3">
                        <Col md={6} lg={6}>
                            <Form.Check
                                className=""
                                type="switch"
                                name="uploadSwitch"
                                id="upload-switch"
                                label="Advance upload image and video"
                                checked={currentProduct.uploadSwitch === "Y"}
                                onChange={onHandleChange}
                            />
                        </Col>
                        <Col md={6} lg={6}>
                            {Array.isArray(selectedCategory) && selectedCategory.length > 0 && selectedCategory.length === 1 ?
                                <Form.Check
                                    className=""
                                    type="switch"
                                    name="addExist"
                                    label="Add Existing Product."
                                    checked={addExistProdFlag}
                                    onChange={(event) => setAddExistProdFlag(event.target.checked)}
                                />
                                : null}
                        </Col>

                        {!addExistProdFlag ?
                            <>
                                <Col md={12} lg={currentProduct.uploadSwitch === "Y" ? 12 : 3} className={currentProduct.uploadSwitch === "Y" ? "d-flex flex-wrap align-items-start px-0" : ''}>
                                    {currentProduct.uploadSwitch === "Y" ?
                                        <>
                                            <div className="w-100 d-flex flex-wrap align-items-start">
                                                {fileList.map((file, idx) => (
                                                    <div key={file.uid} className="pro-main mx-auto mx-lg-2" >
                                                        <div className="pro-menu" onClick={() => { openFileUploader('newFile'); currentSelImg = idx }}>
                                                            <div className="pro-overlay">
                                                                <BiPencil className='pro-icon' />
                                                            </div>
                                                            <img className="pro-image" src={file.icon || proImg} />
                                                            <input type="file" accept=".jpg,.jpeg,.png" name="files" id="newFile" hidden={true} onChange={handleFileChange} />
                                                        </div>
                                                        {file.icon ?
                                                            <div className="pro-trash" onClick={() => removeFile(idx)}><IoIosCloseCircleOutline /></div> : null}
                                                    </div>
                                                ))}
                                            </div>
                                            {video.src ?
                                                <>
                                                    <div className="pro-main mx-auto mx-lg-2" >
                                                        <div className="pro-menu" onClick={() => setVideo({ ...video, previewLink: true })}>
                                                            <img className="pro-image" src={videoImg} />
                                                        </div>
                                                        <div className="pro-trash" onClick={() => setVideo({})}><IoIosCloseCircleOutline /></div>
                                                    </div>
                                                    {video.previewLink ?
                                                        <div
                                                            style={{ padding: '20px', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}
                                                            onClick={() => setVideo({ ...video, previewLink: false })}
                                                        >
                                                            <div style={{ padding: '20px' }}>
                                                                <Button className="btn-close ms-auto d-block bg-transparent mb-2 text-white"></Button>
                                                                <div className="" >
                                                                    <video className=""
                                                                        width="100%"
                                                                        controls
                                                                        style={{ objectFit: 'contain' }}
                                                                        height={400}
                                                                        src={video.src} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}

                                                </>
                                                : null}
                                        </>
                                        : <div className="pro-main mx-auto mx-lg-2" >
                                            <div className="pro-menu" onClick={() => { openFileUploader('singleImg'); currentSelImg = null }}>
                                                <div className="pro-overlay">
                                                    <BiPencil className='pro-icon' />
                                                </div>
                                                <img className="pro-image" src={currentProduct.icon || proImg} />
                                                <input type="file" name="files" accept=".jpg,.jpeg,.png" id="singleImg" hidden={true} onChange={handleFileChange} />
                                            </div>
                                            {currentProduct.icon ?
                                                <div className="pro-trash" onClick={() => removeFile(null)}><IoIosCloseCircleOutline /></div> : null}
                                        </div>}
                                </Col>
                                {currentProduct.uploadSwitch === "Y" ?
                                    <>
                                        <Col md={12} lg={12}>
                                            <Form.Check
                                                className=""
                                                type="switch"
                                                name="onlineSwitch"
                                                onChange={onHandleChange}
                                                label={currentProduct.onlineSwitch === "Y" ? "Online Link" : "Browse Video"}
                                                checked={currentProduct.onlineSwitch === "Y"}
                                            />
                                        </Col>
                                        {currentProduct.onlineSwitch === "Y" ?

                                            <Col md={12} lg={12}>
                                                <Form.Control
                                                    onChange={handleOnlineVideo}
                                                    style={{ height: '46px', lineHeight: '1.5' }}
                                                    placeholder="YouTube URL"
                                                    value={currentProduct.videoUrl || ""}
                                                    aria-label="YouTube URL"
                                                    name="videoUrl"
                                                    aria-describedby="basic-addon2"
                                                />
                                            </Col> :
                                            <Col md={12} lg={12}>
                                                <InputGroup >
                                                    <Form.Control
                                                        aria-describedby="basic-addon2"
                                                        value={video?.fileName || ""}
                                                        placeholder="Uploaded file name"
                                                        enabled={false}
                                                    />
                                                    <Button className='light-btn bg-light-grey ' variant="primary" id="button-addon2" onClick={openVideoUploader}>
                                                        Browser
                                                    </Button>
                                                </InputGroup>
                                                <input type="file" name="video" id="videoUploader" accept=".mp4" hidden={true} onChange={handleVideoChange} />
                                            </Col>
                                        }
                                        <hr />
                                    </>
                                    : null}
                                <Col md={12} lg={currentProduct.uploadSwitch === "Y" ? 12 : 9}>
                                    <Row className="g-3">
                                        <Col md={6}>
                                            <Form.Floating className="">
                                                <Form.Control type="text" autoComplete='off' name="prodAlias" id="floatingInputCustom" placeholder="Product Name" value={currentProduct.prodAlias} onChange={onHandleChange} />
                                                <Form.Label htmlFor="floatingInputCustom">Product Name<span className="text-danger">*</span></Form.Label>
                                            </Form.Floating>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Floating className="">
                                                <NumberField decimals={2} autoComplete='off' name="price" id="floatingInputCustom" placeholder="Product Price" value={currentProduct.price} onChange={onHandleChange} />
                                                <Form.Label htmlFor="floatingInputCustom">Product Price<span className="text-danger">*</span></Form.Label>
                                            </Form.Floating>
                                        </Col>

                                        <Col md={6} className="d-flex align-items-end">
                                            <Form.Floating className="w-100">
                                                <Form.Select aria-label="UOM Select" name="uom" value={currentProduct.uom} onChange={onHandleChange}>
                                                    <option value="" disabled>Unit of measure</option>
                                                    {uomData.map((uom, indx) => (
                                                        <option selected={uom.value === currentProduct.uom} value={uom.value} key={indx}>
                                                            {uom.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Label htmlFor="floatingInputCustom">UOM<span className="text-danger">*</span></Form.Label>
                                            </Form.Floating>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Check className="" type="switch" name="isPriceGstInc" id="custom-switch" label="Is GST Included?" checked={currentProduct.isPriceGstInc === "Y"} onChange={onHandleChange} />
                                            <Form.Floating className="">
                                                <NumberField decimals={0} autoComplete='off' name="perGst" id="floatingInputCustom" placeholder="GST %" value={currentProduct.perGst} onChange={onHandleChange} />
                                                <Form.Label htmlFor="floatingInputCustom">GST %<span className="text-danger">*</span></Form.Label>
                                            </Form.Floating>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={4} className="d-flex align-items-end">
                                    <Form.Floating className="w-100">
                                        <Form.Control type="text" autoComplete='off' name="hsn" id="floatingInputCustom" placeholder="HSN Code" value={currentProduct.hsn} onChange={onHandleChange} />
                                        <Form.Label htmlFor="floatingInputCustom">HSN Code</Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={4}>
                                    <Form.Floating className="">
                                        <Form.Control type="text" autoComplete='off' name="itemNumber" id="floatingInputCustom" placeholder="Item Number" value={currentProduct.itemNumber} onChange={onHandleChange} />
                                        <Form.Label htmlFor="floatingInputCustom">Item Number</Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={4}>
                                    <Form.Floating className="">
                                        <Form.Control type="text" autoComplete='off' name="shortCode" id="floatingInputCustom" placeholder="Item Number" value={currentProduct.shortCode} onChange={onHandleChange} />
                                        <Form.Label htmlFor="floatingInputCustom">Short Code</Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={12}>
                                    <Form.Floating className="">
                                        <Form.Control type="text" autoComplete='off' name="description" id="floatingInputCustom" placeholder="Description" value={currentProduct.description} onChange={onHandleChange} />
                                        <Form.Label htmlFor="floatingInputCustom">Description</Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={6}>
                                    <Form.Floating className="">
                                        <Form.Select aria-label="Category Select" name="categories" value={mainCat} onChange={onCategorySelect}>
                                            <option value="" disabled>Select Category</option> {/* Default option */}
                                            {categoryList && Array.isArray(categoryList) && categoryList.map(st => (
                                                <option selected={st.id === mainCat} key={st.id} value={st.catAlias} >
                                                    {st.catAlias}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label htmlFor="floatingInputCustom">Category<span className="text-danger">*</span></Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={6}>
                                    <Form.Floating className="">
                                        <Form.Select aria-label="Subcategory Select" name="subCategories" value={selectedSubCat} onChange={onSubCategorySelect}>
                                            <option value="" disabled>Select Sub Category</option> {/* Default option */}
                                            {subCategories && subCategories.map(subCat => (
                                                <option selected={subCat.id === selectedSubCat} key={subCat.id} value={subCat.id} >
                                                    {subCat.catAlias}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label htmlFor="floatingInputCustom">Sub Category<span className="text-danger">*</span></Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={6}>
                                    <Form.Floating className="">
                                        <NumberField autoComplete='off' name="displayOrder" id="floatingInputCustom" placeholder="Display Order" value={currentProduct.displayOrder} onChange={onHandleChange} />
                                        <Form.Label htmlFor="floatingInputCustom">Display Order</Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={6}>
                                    <Form.Floating>
                                        <Form.Select name="status" id="statusSelect" placeholder="Status" value={currentProduct.status} onChange={onHandleChange}>
                                            <option value="" disabled>Select Status</option>
                                            <option value="ACTIVE" selected={currentProduct.status === "ACTIVE"}>Active</option>
                                            <option value="INACTIVE" selected={currentProduct.status === "INACTIVE"}>Inactive</option>
                                        </Form.Select>
                                        <Form.Label htmlFor="statusSelect">Status</Form.Label>
                                    </Form.Floating>
                                </Col>
                            </> :
                            <Col md={12} lg={12} >
                                {selectedCategory !== 'AllCategories' ?
                                    <AutoCompleter name="prodAlias" data={productList} onInputChange={() => { }} extraLabel='itemNumber' placeholder="Search Product by name" onChange={(row) => { setCurrentProduct({ ...row.selItm }); setButtonState({ ...buttonState, disable: false }) }} />
                                    : null}
                            </Col>
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer className='border-0 pt-0'>
                    <Row className='g-3 w-100 justify-content-end'>
                        <Col md={3} lg={2}>
                            {buttonState.loader === false ?
                                <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={onSubmit} disabled={buttonState.disable}>Save</Button>
                                : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        size="sm"
                                        animation="border"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>}
                        </Col>
                    </Row>
                </Modal.Footer>
            </>
        </Modal>
    );
};

export default AddProduct;

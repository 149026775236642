import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App";
import * as serviceWorker from './serviceWorker';
import './index.css';
import ErrorBoundary from './ErrorBoundary';
import { WebSocketProvider } from './util/WebSocketContext';

ReactDOM.render(
  <ErrorBoundary>
    <WebSocketProvider>
      <App />
    </WebSocketProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
serviceWorker.unregister();
import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Table, Badge } from "react-bootstrap";
import { standardDateFormat } from "../../../../../../util/utility-helper";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { LuUser2 } from "react-icons/lu";
import CustomerDetailModal from "./CustomerDetailModal";
import { useDispatch } from "react-redux";
import ConfirmDialog from "../../../../../../components/common/App/ConfirmDialog";
import { changeNewOrderStatus } from "../../../../../../actions/billdesk/BillDeskActions";

const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const OnlineOrder = ({ ...props }) => {
    const dispatch = useDispatch();

    const [newOrders, setNewOrders] = useState([]);

    const [modalManage, setModalManage] = useState({ modalName: '', visible: false });

    const [isOrderAccept, setIsOrderAccept] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});

    useEffect(() => {
        if (props.showModal) {
            setNewOrders(props.newOrderList)
            // setCustomerDetails({ ...props.customerDetails });
        }
    }, [props.showModal, props.newOrderList]);

    const hideModal = () => {
        if (isOrderAccept) {
            setIsOrderAccept(false);
        } else {
            props.hideModal()
        }
    };

    const handleModalManager = (modalName, visible) => {
        setModalManage({ ...modalManage, modalName, visible })
    };

    const changeOrderStatus = async (stageOrder) => {
        await dispatch(changeNewOrderStatus({ orderId: orderDetails.id, status: stageOrder === 'ACCEPTED' ? 'OPEN' : "REJ", stageOrder: "WIP" })).then((response) => {
            if (response && response.payload) {
                hideModal();
                props.fetchNewOrderList();
            }
        })
    };

    const onConfirm = async (flag) => {
        if (flag === 'YES') {
            await dispatch(changeNewOrderStatus({ orderId: orderDetails.id, stageOrder: 'REJ', status: "CANCELLED" })).then((response) => {
                if (response && response.payload) {
                    handleModalManager('', false)
                    props.fetchNewOrderList();
                }
            })
        } else {
            handleModalManager('', false);
        }
    };

    const customerDetailModalProps = { showModal: (modalManage.modalName === "customerDetailModal" && modalManage.visible === true), hideModal: () => handleModalManager('customerDetailModal', false), customerDetails: { ...orderDetails.customer, orderDate:standardDateFormat(orderDetails.orderDate) } || {}, changeCustomerDetails: () => { }, isFieldDisabled: true };
    const confirmModalProps = { showModal: (modalManage.modalName === 'confirmModal' && modalManage.visible === true), hideModal: () => handleModalManager('', false), handleClick: onConfirm, message: "Are you sure you want to reject the order?" }

    return (
        <>
            {customerDetailModalProps.showModal ?
                <CustomerDetailModal  {...customerDetailModalProps} /> :
                confirmModalProps.showModal ?
                    <ConfirmDialog  {...confirmModalProps} /> :
                    <Modal size="lg" backdrop="static" keyboard={false} centered show={props.showModal} onHide={hideModal} scrollable>
                        <Modal.Header className='border-0 pb-0' closeButton>
                            <Row className='mx-0 g-3 w-100 align-items-center me-3'>
                                <Col md={12} lg={6} className='d-sm-flex d-md-flex flex-wrap d-block align-items-center pe-4'>
                                    <Modal.Title className='fs-5 fw-bold'>Online Orders</Modal.Title>
                                </Col>
                                {isOrderAccept ?
                                    <>
                                        <Col xs={6} md={6} lg={3}>
                                            <Button className='light-btn w-100 text-truncate h-56 text-uppercase  d-flex align-items-center justify-content-center' variant="primary" onClick={() => handleModalManager('customerDetailModal', true)}>
                                                <LuUser2 className='fs-16 me-1' />Customer
                                            </Button>
                                        </Col>
                                        <Col xs={6} md={6} lg={3}>
                                            <Button className='light-btn w-100 text-truncate h-56 text-uppercase  d-flex align-items-center justify-content-center' variant="primary" >
                                                {orderDetails.deliveryMethod}
                                            </Button>
                                        </Col>
                                    </> : null}
                            </Row>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="g-3">
                                {!isOrderAccept ?
                                    <Col xs={12}>
                                        <Table responsive className='inso-table'>
                                            <thead>
                                                <tr>
                                                    <th className='text-start' width="10%">#Invoice</th>
                                                    <th className='text-center' width="10%"> Date</th>
                                                    <th className='text-end' width="20%"><FaIndianRupeeSign />Order Amount</th>
                                                    <th className='text-center' width="10%">Order Type</th>
                                                    <th className='text-center' width="10%">Payment Status</th>
                                                    <th className='text-center' width="20%">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {newOrders.map((items, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-start">{items.tempInvoiceNo || "-"}</td>
                                                            <td className="text-center">{standardDateFormat(items.orderDate, dateFormat)}</td>
                                                            <td className="text-end">{items.orderTotal}</td>
                                                            <td className="text-center">{items.deliveryMethod}</td>
                                                            <td className="text-center">
                                                                {items.paymentStatus === "UNPAID" ? (
                                                                    <Badge bg="danger">Pending</Badge>
                                                                ) : items.paymentStatus === "PAID" ? (
                                                                    <Badge bg="success">Paid</Badge>
                                                                ) : items.paymentStatus === "PARTIAL" ? (
                                                                    <>
                                                                        <Badge bg="warning">Partial </Badge>{" "}
                                                                        <span>
                                                                            <FaIndianRupeeSign className="" />
                                                                            {(items.orderTotal - items.amountPaid).toFixed(2)}
                                                                        </span>
                                                                    </>
                                                                ) : null}
                                                            </td>
                                                            <td className='text-center'>
                                                                <Button style={{ borderRadius: '10px' }} className='py-1 fs-12 fw-semibold text-truncate bg-light-green text-white' variant="primary" onClick={() => { setOrderDetails(items); setIsOrderAccept(true) }}>
                                                                    Accept
                                                                </Button>
                                                                <Button style={{ borderRadius: '10px' }} className='py-1 fs-12 fw-semibold text-truncate bg-light-red text-white ms-3' variant="primary" onClick={() => { handleModalManager('confirmModal', true); setOrderDetails(items) }}>
                                                                    Reject
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                                {newOrders?.length === 0 || newOrders?.length === undefined ? (
                                                    <tr>
                                                        <td colSpan="12" className="text-center">
                                                            No order available
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </tbody>
                                        </Table>
                                    </Col> :
                                    <>
                                        <Col xs={12}>
                                            <Row className="g-2">
                                                <Col xs={6} sm={6} md={5} lg={3}>
                                                    <Form.Label htmlFor="floatingInputCustom" className='d-flex align-items-center'>
                                                        #Invoice<span className='ms-auto'>-</span>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={6} sm={6} md={6} lg={6}>
                                                    <Form.Label htmlFor="floatingInputCustom">
                                                        {orderDetails?.tempInvoiceNo || "N/A"}
                                                    </Form.Label>
                                                </Col>
                                            </Row>
                                            <Row className="g-2">
                                                <Col xs={6} sm={6} md={5} lg={3}>
                                                    <Form.Label htmlFor="floatingInputCustom" className='d-flex align-items-center'>
                                                        Customer Name<span className='ms-auto'>-</span>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={6} sm={6} md={6} lg={6}>
                                                    <Form.Label htmlFor="floatingInputCustom">
                                                        {orderDetails?.customer?.name || "N/A"}
                                                    </Form.Label>
                                                </Col>
                                            </Row>
                                            <Row className="g-2">
                                                <Col xs={6} sm={6} md={5} lg={3}>
                                                    <Form.Label htmlFor="floatingInputCustom" className='d-flex align-items-center'>
                                                        Mobile<span className='ms-auto'>-</span>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={6} sm={6} md={6} lg={6}>
                                                    <Form.Label htmlFor="floatingInputCustom">
                                                        {orderDetails?.customer?.mobileNumber || "N/A"}
                                                    </Form.Label>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12}>
                                            <Table responsive className='inso-table'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-start' width="60%">Item Name</th>
                                                        <th className='text-center' width="20%">Qty</th>
                                                        <th className='text-end' width="20%"><FaIndianRupeeSign /> Price</th>
                                                        <th className='text-end' width="20%">S-total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orderDetails.items?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className='text-start'>{item.prodAlias}</td>
                                                            <td className='text-center'>{item.qty}</td>
                                                            <td className='text-end'>{item.price}</td>
                                                            <td className='text-end'>{item.price * item.qty}</td>

                                                        </tr>
                                                    ))}
                                                    {/* {orderDetails?.length === 0 || orderDetails?.length === undefined ? (
                                                        <tr>
                                                            <td colSpan="12" className="text-center">
                                                                No order available
                                                            </td>
                                                        </tr>
                                                    ) : null} */}
                                                    <tr>
                                                        <td className='text-end' colSpan='4'>
                                                            <Row className="g-0 ">
                                                                <Col sm={12} md={6} lg={4} className='ms-auto'>
                                                                    <Row className="g-0 ">
                                                                        <Col xs={6} className='text-start'>
                                                                            <span className='fs-14'>Sub-Total</span>
                                                                        </Col>
                                                                        <Col xs={6} className=' text-end'>
                                                                            <span className='fs-14'>{parseFloat(orderDetails.orderTotal - orderDetails.totalGst).toFixed(2) || 0.00}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="g-0 ">
                                                                        <Col xs={6} className='text-start'>
                                                                            <span className='fs-14'>Tax</span>
                                                                        </Col>
                                                                        <Col xs={6} className=' text-end'>
                                                                            <span className='fs-14'>{parseFloat(orderDetails.totalGst).toFixed(2) || 0.00}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="g-0">
                                                                        <Col xs={6} className='text-start'>
                                                                            <span className='fw-bold fs-16'>Total</span>
                                                                        </Col>
                                                                        <Col xs={6} className=' text-end'>
                                                                            <span className='fw-bold fs-16'>{parseFloat(orderDetails.orderTotal).toFixed(2) || 0.00}</span>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </>
                                }
                            </Row>
                        </Modal.Body>
                        {isOrderAccept ?
                            <Modal.Footer className='border-0 pt-0'>
                                <Row className='g-3 w-100 justify-content-end'>
                                    <Col xs={6} sm={6} md={6} lg={2}>
                                        <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={() => changeOrderStatus("ACCEPTED")}>
                                            Confirm
                                        </Button>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={2}>
                                        <Button className='light-btn w-100 bg-light-grey' variant="primary" onClick={hideModal} >
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Modal.Footer> : null}
                    </Modal >
            }
        </>
    )
};

export default OnlineOrder;
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Row, Col, Container, Form, OverlayTrigger, Popover, Nav, } from 'react-bootstrap';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticated } from '../../../config/appSession';
import { deepCopy, standardDateFormat } from '../../../util/utility-helper';
import { getCompleteOrders } from '../../../actions/order/CompleteOrdersAction';
import { useNavigate } from 'react-router-dom';
import OrderTable from './widgets/OrderTable';
import SingleOrderDetailModal from './modals/SingleOrderDetailModal';
import TokenOrderDetailModal from './modals/TokenOrderDetailModal';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { getAllDeliveryMethods, getStoreDeliveryMethod } from '../../../actions/billdesk/BillDeskActions';
import ToastManager from '../../../components/common/App/NotificationManager';
import { changeOrderFilter } from '../../../slice/order/CompleteOrderSlice';
import Select from 'react-select';
import { fetchAllTerminals } from '../../../actions/admin/AdminActions';
import { LuSearch } from 'react-icons/lu';
import { FiFilter } from "react-icons/fi";
import OpenOrderTable from '../open-orders/widgets/Open-OrderTable';
import { getOpenOrders } from '../../../actions/order/OpenOrdersActions';
import { getBussinessStages } from '../../../actions/order/OrderManage';

let selectedOrder = null;

const initialFilterState = { startDate: "", endDate: "", searchText: '', orderTypes: [], orderStages: [] };
const pageSizeSelectOptions = [{ name: "10", value: 10 }, { name: "20", value: 20 }, { name: "50", value: 50 }];
const initialPageDetails = { currentPage: 1, pageSize: 10 };
const yesterday = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() - 1));
const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const initialToastState = { visible: false, toastMessage: '', variant: "" };
let openOrderList = [];
let completeOrdersClone = [];
const CompleteOrder = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const userRole = localStorage.getItem("USER_ROLE");

    /** redux state for filter order by status */
    const ordersFilter = useSelector((state) => state.completeOrder.orderFilter);

    /** component states */
    const [loading, setLoading] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({ ...initialFilterState });
    const [pageDetails, setPageDetails] = useState({ ...initialPageDetails });
    const [completedOrders, setCompletedOrders] = useState([]);
    const [orderToDisplay, setOrderToDisplay] = useState([]);
    const [modalManager, setModalManager] = useState({ modalName: '', visible: false });
    const [methodAndTerminalList, setMethodAndTerminalList] = useState({ terminals: [], methods: [] });
    const [toastMessage, setToastMessage] = useState({ ...initialToastState });
    const [currentTab, setCurrentTab] = useState('COMPLETEORDERS');
    const [showAdvanceDateFilter, setShowAdvanceDateFilter] = useState(false);
    const [showCancelSales, setShowCancelSales] = useState(false);
    const [openOrdersByType, setOpenOrdersByType] = useState([]);
    const [selectedLabel, setSelectedLabel] = useState("Today's");
    const [orderStages, setOrderStages] = useState([]);
    const [orderStagesALL, setOrderStagesALL] = useState([]); // orderStages for ALL
    const [tempFilter, setTempFilter] = useState({ startDate: "", endDate: "" });
    const [selectedOrderTypeOptions, setSelectedOrderTypeOptions] = useState([]);

    useEffect(() => {
        if (ordersFilter !== '') {
            filterOrderByStatus(ordersFilter);
            dispatch(changeOrderFilter(''));
        }
    }, [ordersFilter, pageDetails, completedOrders]);

    useEffect(() => {
        checkAuthentication();
    }, [userRole]);

    /**
     * checks user is authentication and fetch order type, terminal and complete order list
     */
    const checkAuthentication = async () => {
        if (isAuthenticated()) {
            if (userRole === 'BUSINESS_ADMIN') {
                let terminalList = [];
                let orderTypeMethodList = [];
                await dispatch(fetchAllTerminals()).then((response) => {
                    terminalList = response.payload;
                })
                await dispatch(getAllDeliveryMethods()).then((response) => {
                    orderTypeMethodList = response.payload;
                })
                // await getOrderStagesByApi('')
                setMethodAndTerminalList({ ...methodAndTerminalList, terminals: terminalList, methods: orderTypeMethodList });
                if (terminalList.length > 0 && orderTypeMethodList.length > 0) {
                    const terminalIds = terminalList.map((terminal) => String(terminal.id)); // Get all terminal IDs
                    const orderTypeMethodId = orderTypeMethodList.map((orderType) => String(orderType.methodCode)); // Get all orderTypeMethod IDs
                    await getCompleteOrderList({ ...initialFilterState, startDate: standardDateFormat(new Date(), dateFormat), endDate: standardDateFormat(new Date(), dateFormat), terminalIds: terminalIds, deliveryMethods: orderTypeMethodId }, currentTab);
                    await getOpenOrdersList(currentTab);
                }
            } else {
                let orderTypeMethodList = [];
                await dispatch(getStoreDeliveryMethod()).then((response) => {
                    if (response && response.payload) {
                        orderTypeMethodList = response.payload;
                    }
                })
                const orderTypeMethodId = await orderTypeMethodList.map((orderType) => String(orderType.methodCode)); // Get all orderTypeMethod IDs
                await getCompleteOrderList({ ...initialFilterState, startDate: standardDateFormat(new Date(), dateFormat), endDate: standardDateFormat(new Date(), dateFormat), deliveryMethods: orderTypeMethodId }, currentTab);
            }
        } else {
            navigate('/user/login');
        }
    };

    /**
     * fetch completed order list by filters
     * @param {object} filter 
     */
    const getCompleteOrderList = async (filter, currentTab) => {
        if (currentTab === 'COMPLETEORDERS') {
            setLoading(true);
            await dispatch(getCompleteOrders(filter))
                .then((response) => {
                    if (response.payload) {
                        let currentPage = pageDetails.currentPage;
                        const cancelSales = [];
                        completeOrdersClone = response.payload;
                        if (JSON.stringify(filter) !== JSON.stringify(currentFilter)) {
                            currentPage = 1;
                            setPageDetails({ ...pageDetails, currentPage: 1 });
                        }
                        if (showCancelSales) {
                            response.payload && response.payload.forEach(orders => {
                                if (orders.status === 'CANCELLED') {
                                    cancelSales.push(orders)
                                }
                            })
                        } else {
                            response.payload = response.payload.filter(e => e.status !== 'CANCELLED');
                        }
                        setCompletedOrders(userRole === 'BUSINESS_ADMIN' ? deepCopy(response.payload) : deepCopy(response.payload).splice(0, 10));
                        setCompletedOrders(userRole === 'BUSINESS_ADMIN' ? showCancelSales ? cancelSales : response.payload : showCancelSales ? deepCopy(cancelSales).splice(0, 10) : deepCopy(response.payload).splice(0, 10));
                        setCurrentFilter({ ...filter });
                        handlePaginator(showCancelSales ? cancelSales : response.payload, pageDetails.pageSize, currentPage);
                    }
                })
        }
    };

    const getOpenOrdersList = async (currentTab) => {
        if (currentTab === 'OPENORDERS') {
            setLoading(true);
            await dispatch(getOpenOrders()).then((response) => {
                if (response && response.payload) {
                    openOrderList = response.payload;
                    setLoading(false);
                    sortOpenOrdersByOrderType(response.payload, currentFilter.orderTypes, 1);
                }
            });
        }
    };

    const getOrderStagesByApi = async (currSelOrderType) => {
        await dispatch(getBussinessStages()).then((response) => {
            if (response.payload && !response.payload.error) {
                const orderStagesClone = [];
                response.payload.forEach((item) => {
                    orderStagesClone.push(item?.transitions || []);
                });

                // Flatten the array and remove duplicates
                const flattenedTransitions = orderStagesClone.flat(); // Flatten the array
                const uniqueTransitions = Array.from(
                    new Map(
                        flattenedTransitions.map((item) => [item.stageCode, item]) // Use Map to ensure uniqueness by `stageCode`
                    ).values()
                );
                // Set unique transitions as a single array
                setOrderStagesALL(uniqueTransitions);
                const stagesByMethods = response.payload.find(
                    (e) => e.method === currSelOrderType
                );
                setOrderStages((stagesByMethods && stagesByMethods?.transitions) || []);
            }
        });
    };

    const sortOpenOrdersByOrderType = async (orderList, orderTypes = [], currentPage, searchText) => {
        const openOrderList = [];
        if (orderTypes.find(e => e === 'DINE') || orderTypes.length === 0) {
            const tokenOrders = orderList.tokenOrders || [];
            await tokenOrders && tokenOrders.forEach(orderItm => {
                if (orderItm.orders && orderItm.orders.length >= 1) {
                    if (orderItm.orders.length > 1) {
                        let orderDate = '';
                        let orderTotal = 0;
                        let amountPaid = 0;
                        let paymentStatus = 'PAID';
                        let orderStage = 'WIP';
                        const sortedArrByDate = orderItm.orders.sort((a, b) => { return new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime() });
                        orderItm.orders.forEach(orders => {
                            if (orders.orderTotal) {
                                orderTotal = parseFloat(parseFloat(orderTotal) + parseFloat(orders.orderTotal)).toFixed(2);
                            }
                            if (orders.amountPaid) {
                                amountPaid = (parseFloat(orders.amountPaid) + parseFloat(amountPaid)).toFixed(2);
                            }
                            if (orders.paymentStatus === 'UNPAID' || orders.paymentStatus === 'PARTIAL') {
                                paymentStatus = orders.paymentStatus;
                            }
                            if (orders.orderStage !== 'WIP') {
                                orderStage = orders.orderStage
                            }
                        })
                        if (sortedArrByDate && sortedArrByDate.length > 0) {
                            orderDate = standardDateFormat(sortedArrByDate[0].orderDate || '', dateFormat + 'HH:mm:ss');
                        }
                        openOrderList.push({ id: orderItm.id, tag: orderItm.tag, orderDate: orderDate, orderStage: orderStage, deliveryMethod: "DINE", orderTotal: orderTotal, paymentStatus: paymentStatus, amountPaid: amountPaid });
                        return openOrderList;
                    } else {
                        openOrderList.push({ id: orderItm.id, tag: orderItm.tag, orderDate: standardDateFormat(orderItm.orders[0].orderDate || '', dateFormat + 'HH:mm:ss'), orderStage: orderItm.orders[0].orderStage, deliveryMethod: "DINE", orderTotal: parseFloat(orderItm.orders[0].orderTotal).toFixed(2), paymentStatus: orderItm.orders[0].paymentStatus, amountPaid: orderItm.orders[0].amountPaid });
                        return openOrderList;
                    }
                }
                return openOrderList;
            });

        } if (orderTypes.find(e => e !== 'DINE') || orderTypes.length === 0) {
            if (orderList.singleOrders) {
                await orderList.singleOrders.forEach(orderItm => {
                    if (
                        (orderTypes.length === 0 || orderTypes.find(e => e === orderItm.deliveryMethod))
                        &&
                        (
                            searchText ?
                                (orderItm.customer &&
                                    (
                                        (String(orderItm.customer.name) && String(orderItm.customer.name).toLocaleLowerCase().includes(String(searchText).toLocaleLowerCase())) ||
                                        (String(orderItm.customer.mobileNumber) && (String(orderItm.customer.mobileNumber).toLocaleLowerCase().includes(String(searchText).toLocaleLowerCase())))
                                    ))
                                : true
                        )
                    ) {
                        openOrderList.push({ ...orderItm, orderDate: standardDateFormat(orderItm.orderDate || '', dateFormat + 'HH:mm:ss'), orderTotal: parseFloat(orderItm.orderTotal).toFixed(2), amountPaid: parseFloat(orderItm.amountPaid).toFixed(2) });
                    }
                })
            }
        }
        setOpenOrdersByType(openOrderList)
        handlePaginator(openOrderList, pageDetails.pageSize, currentPage);
    };

    /**
     * handle days filters
     * @param {string} value 
     */
    const handleDaysFilter = (value) => {
        document.querySelector('input[name=startDate]').value = "";
        document.querySelector('input[name=endDate]').value = "";
        if (value === "TODAY") {
            setSelectedLabel("Today's");
            setTempFilter({
                startDate: standardDateFormat(new Date(), dateFormat),
                endDate: standardDateFormat(new Date(), dateFormat),
            });
        } else if (value === "YESTERDAY") {
            setSelectedLabel("Yesterday's");
            setTempFilter({
                startDate: standardDateFormat(yesterday, dateFormat),
                endDate: standardDateFormat(yesterday, dateFormat),
            });
        }
        setShowAdvanceDateFilter(false); // Close the popover
        getCompleteOrderList(
            value === "TODAY" ? {
                ...currentFilter, startDate: standardDateFormat(new Date(), dateFormat), endDate: standardDateFormat(new Date(), dateFormat),
            } : {
                ...currentFilter, startDate: standardDateFormat(yesterday, dateFormat), endDate: standardDateFormat(yesterday, dateFormat),
            },
            currentTab
        );
    };


    /**
     * create chunks functions
     * @param {Array} orders 
     * @param {object} pageSize 
     * @param {object} currentPage 
     */
    const handlePaginator = async (orders, pageSize, currentPage) => {
        let chunks = [];
        if (orders) {
            for (let i = 0; i < orders.length; i++) {
                if (userRole === 'BUSINESS_MANAGER' && i === 10) {
                    break;
                } else {
                    if (!chunks.length || chunks[chunks.length - 1].length == pageSize)
                        chunks.push([]);
                    chunks[chunks.length - 1].push(orders[i]);
                }
            }
            setLoading(false);

            // display the last possible page when there not enough records for existance of currentPage
            if (currentPage >= Math.ceil(orders.length / pageDetails.pageSize)) {
                currentPage = Math.ceil(orders.length / pageDetails.pageSize);
                currentPage = currentPage === 0 ? 1 : currentPage;
                setPageDetails(prev => ({ ...prev, currentPage }))
            } else {
                setPageDetails(prev => ({ ...prev, currentPage }))
            }

            setOrderToDisplay(chunks[currentPage - 1] === undefined ? [] : chunks[currentPage - 1]);
        }
    };

    /**
     * handle date filter
     * @param {event} event 
     */
    const handleDateFilter = (e) => {
        const { name, value } = e.target;
        // Update the tempFilter state as user selects dates
        setTempFilter({
            ...tempFilter,
            [name]: value
        });
    };

    /**
     * Handle terminal and order method filter and search accordingly 
     * @param {array} selectedOptions - Selected options from the dropdown
     * @param {string} filterType - Type of filter ('terminalIds' or 'deliveryMethods')
     */
    const handleTerminalAndOrderTypeFilter = (selectedOptions, filterType, searchText, openOrdersWithSearchTextFilter) => {
        const selectedValue = selectedOptions?.length
            ? selectedOptions.map(option => String(option.value))
            : methodAndTerminalList[filterType === 'deliveryMethods' ? 'methods' : 'terminals'].map(e => String(e[filterType === 'deliveryMethods' ? 'methodCode' : 'id'])); // No selection = show all
        setCurrentFilter({ ...currentFilter, [filterType]: selectedValue });
        // Manually filter open orders if currentTab is 'OPENORDERS'
        if (currentTab === 'OPENORDERS') {
            sortOpenOrdersByOrderType(openOrdersWithSearchTextFilter ? openOrdersWithSearchTextFilter : openOrderList, selectedValue, pageDetails.currentPage, searchText === undefined ? currentFilter?.searchText : searchText);
        } else if (currentTab === 'COMPLETEORDERS') {
            getCompleteOrderList({ ...currentFilter, [filterType]: selectedValue }, currentTab);
        }
    };

    /**
     * pagination handler for completed records
     * @param {string} pageType 
     */
    const handlePagination = (pageType) => {
        if (pageType === "NEXT") {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage + 1 });
            handlePaginator(currentTab === 'OPENORDERS' ? openOrdersByType : completedOrders, pageDetails.pageSize, pageDetails.currentPage + 1);
        } else {
            setPageDetails({ ...pageDetails, currentPage: pageDetails.currentPage - 1 });
            handlePaginator(currentTab === 'OPENORDERS' ? openOrdersByType : completedOrders, pageDetails.pageSize, pageDetails.currentPage - 1);
        }
    }

    /** 
     * page size change
     *  @param {event} pageType 
    */
    const handlePageSize = (event) => {
        setPageDetails({ ...pageDetails, pageSize: parseInt(event.target.value), currentPage: 1 });
        handlePaginator(currentTab === 'OPENORDERS' ? openOrdersByType : completedOrders, parseInt(event.target.value), 1);
    };

    /**
     * on row change function
     * @param {object} order 
     */
    const onRowSelect = (order) => {
        selectedOrder = order;
        if (order.deliveryMethod === 'DINE') {
            handleModalManager('tokenOrderDetailModal', true);
        } else {
            handleModalManager('singleOrderDetailModal', true);
        }
    };

    /**
     * common modal manager function
     * @param {string} modalName 
     * @param {boolean} visible 
     */
    const handleModalManager = (modalName, visible) => {
        setModalManager({ ...modalManager, modalName, visible })
    };

    const exportToExcel = () => {
        const excelData = [];
        completedOrders.forEach(orderItem => {
            const creditAmount = orderItem.discount ? 0 : parseFloat(orderItem.orderTotal || 0) - parseFloat(orderItem.amountPaid || 0); // no need to calculate credit if discount is applied on order
            excelData.push({ "Invoice": orderItem.invoiceNo, "Order Date": standardDateFormat(orderItem.orderDate, 'YYYY-MM-DD HH:mm:ss'), "Order Total": orderItem.orderTotal, "Order Type": orderItem.deliveryMethod, "Payment Method": orderItem.paymentMethod, "Amount Paid": orderItem.amountPaid, "Credit Amount": creditAmount, "Discount": orderItem.discount, "Total GST": parseFloat(orderItem.totalGst).toFixed(2) })
        })
        if (excelData.length === 0) {
            excelData.push({ "Invoice": "", "Order Date": "", "Order Total": "", "Order Type": "", "Payment Method": "", "Amount Paid": "", "Credit Amount": "", "Discount": "", "Total GST": "" });
        }
        const fileExtension = ".xlsx";
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `SaleReport_${currentFilter.startDate}_${currentFilter.endDate}` + fileExtension);
    };

    /**
     * common function for close dilaog and get completedOrder by API
     * @param {boolean} isDataChangeFlag 
     */
    const onHideModals = (isDataChangeFlag) => {
        if (isDataChangeFlag) {
            getCompleteOrderList({ ...currentFilter }, currentTab);
        }
        handleModalManager('', false);
    };

    /**
     * filter by total sale and cancel order
     * @param {string} filterStatus 
     */
    const filterOrderByStatus = (filterStatus) => {
        const emptyArr = [];
        setLoading(true);
        completedOrders.forEach(orders => {
            if (orders.status === filterStatus) {
                emptyArr.push(orders);
            }
        })
        setTimeout(() => {
            handlePaginator(emptyArr, pageDetails.pageSize, pageDetails.currentPage);
            setLoading(false);
        }, 1000);
    }

    /** modals props */
    const singleOrderDetailModalProps = { showModal: (modalManager.modalName === 'singleOrderDetailModal' && modalManager.visible === true), hideModal: onHideModals, selectedOrder: selectedOrder, handleToastManager: setToastMessage };
    const tokenOrderDetailModalProps = { showModal: (modalManager.modalName === 'tokenOrderDetailModal' && modalManager.visible === true), hideModal: onHideModals, selectedOrder: selectedOrder, handleToastManager: setToastMessage };

    const formatedSelectOptions = (list = []) => {
        const options = [];
        list.forEach((prod) => {
            if (prod) {
                options.push({ label: prod.name, value: prod.methodCode ? prod.methodCode : prod.id });
            }
        });
        return options;
    };

    const formatOrderStageOptions = (list = []) => {
        const options = [];
        list.forEach((prod) => {
            if (prod) {
                options.push({ label: prod.stageName, value: prod.stageCode ? prod.stageCode : prod.id });
            }
        });
        return options;
    }

    const filterRecordByOrderStage = (selectedValues) => {
        const emptyArr = [];

        // If no values are selected, display all data
        // if (selectedValues.length === 0) {
        //     setCurrentFilter({ ...initialFilterState, orderStages: [] });
        //     sortOpenOrdersByOrderType(openOrderList, , pageDetails.currentPage);
        //     return;
        // }

        // if (currentOrderType !== 'DINE') {
        //     openOrderList && openOrderList.singleOrders.forEach((orders) => {
        //         if (
        //             selectedValues.some((values) =>
        //                 String(orders.orderStage || "").toLocaleLowerCase().includes(String(values.value).toLocaleLowerCase())
        //             )
        //         ) {
        //             emptyArr.push(orders);
        //         }
        //     });
        // } else {
        //     openOrderList && openOrderList.tokenOrders.forEach((orders) => {
        //         if (orders.orders) {
        //             if (
        //                 selectedValues.some((values) =>
        //                     String(orders.orders[0].orderStage || "").toLocaleLowerCase().includes(String(values.value).toLocaleLowerCase())
        //                 )
        //             ) {
        //                 emptyArr.push(orders);
        //             }
        //         }
        //     });
        // }

        // let cloneOrders = deepCopy(openOrderList);
        // cloneOrders[currentFilter.orderTypes !== 'DINE' ? 'singleOrders' : 'tokenOrders'] = emptyArr;
        // sortOpenOrdersByOrderType(cloneOrders, currentFilter.orderTypes, pageDetails.currentPage);

        // setSearchFilter({ ...initialFilterState, orderStage: selectedValues });
    };

    const filterRecordByOrderStageALL = (selectedValues) => {
        // const tokenArr = [];
        // const singleOrderArr = [];

        // if (selectedValues.length === 0) {
        //     setSearchFilter({ ...initialFilterState, orderStage: [] });
        //     sortOpenOrdersByOrderType(openOrderList, currentFilter.orderTypes, pageDetails.currentPage);
        //     return;
        // }

        // openOrderList && openOrderList.singleOrders.forEach((orders) => {
        //     if (
        //         selectedValues.some((values) =>
        //             String(orders.orderStage || "").toLocaleLowerCase().includes(String(values.value).toLocaleLowerCase())
        //         )
        //     ) {
        //         singleOrderArr.push(orders);
        //     }
        // });

        // openOrderList && openOrderList.tokenOrders.forEach((orders) => {
        //     if (orders.orders) {
        //         if (
        //             selectedValues.some((values) =>
        //                 String(orders.orders[0].orderStage || "").toLocaleLowerCase().includes(String(values.value).toLocaleLowerCase())
        //             )
        //         ) {
        //             tokenArr.push(orders);
        //         }
        //     }
        // });

        // let cloneOrders = deepCopy(openOrderList);
        // // cloneOrders[currentOrderType !== 'DINE' ? 'singleOrders' : 'tokenOrders'] = emptyArr;
        // cloneOrders.tokenOrders = tokenArr;
        // cloneOrders.singleOrders = singleOrderArr;
        // sortOpenOrdersByOrderType(cloneOrders, currentFilter.orderTypes, pageDetails.currentPage);
        // setSearchFilter({ ...initialFilterState, orderStage: selectedValues })
    }


    const onChangeTab = async (currentTab) => {
        // Reset the filter states
        setCurrentFilter({ ...initialFilterState });
        setSelectedOrderTypeOptions([]);
        setSelectedLabel("Today's");


        if (currentTab === 'OPENORDERS') {
            getOpenOrdersList(currentTab);
        } else {
            if (methodAndTerminalList.terminals.length > 0 && methodAndTerminalList.methods.length > 0) {
                const terminalIds = methodAndTerminalList.terminals.map((terminal) => String(terminal.id)); // Get all terminal IDs
                const orderTypeMethodId = methodAndTerminalList.methods.map((orderType) => String(orderType.methodCode)); // Get all orderTypeMethod IDs
                await getCompleteOrderList({ ...initialFilterState, startDate: standardDateFormat(new Date(), dateFormat), endDate: standardDateFormat(new Date(), dateFormat), terminalIds: terminalIds, deliveryMethods: orderTypeMethodId }, currentTab);
                await getOpenOrdersList(currentTab);
            }
        }
        setPageDetails({ ...pageDetails, currentPage: 1 });
        setCurrentTab(currentTab);
    };

    const handleCancelSales = async (event) => {
        const { checked } = event.target;
        const sales = [];
        await Promise.all(completeOrdersClone.map(orders => {
            if (checked && orders.status === 'CANCELLED') {
                sales.push(orders);
            }
            return orders
        })).then(() => {
            setCurrentFilter({ ...currentFilter, searchText: "" });
            setCompletedOrders(checked ? sales : completeOrdersClone.filter(e => e.status !== 'CANCELLED'))
            handlePaginator(checked ? sales : completeOrdersClone.filter(e => e.status !== 'CANCELLED'), pageDetails.pageSize, pageDetails.currentPage);
            setShowCancelSales(checked);
        });
    };

    const handleCustomerSearch = (event) => {
        const { value } = event.target;
        let emptyArr = [];
        if (currentTab === 'COMPLETEORDERS') {
            if (value !== '') {
                completeOrdersClone && completeOrdersClone.forEach(order => {
                    if (order.customer && (String(order.customer.name).toLocaleLowerCase().includes(String(value).toLocaleLowerCase())) || (String(order.customer.mobileNumber).toLocaleLowerCase().includes(String(value).toLocaleLowerCase()))) {
                        emptyArr.push(order);
                    }
                });

                if (showCancelSales) {
                    emptyArr = emptyArr.filter(e => e.status === 'CANCELLED');
                } else {
                    emptyArr = emptyArr.filter(e => e.status !== 'CANCELLED')
                }
                setCompletedOrders(emptyArr)
            } else {
                // let orders = completeOrdersClone;
                // if (showCancelSales) {
                //     orders = orders.filter(e => e.status === 'CANCELLED')
                // } else {
                //     orders = orders.filter(e => e.status !== 'CANCELLED')
                // }
                // handlePaginator(orders, pageDetails.pageSize, pageDetails.currentPage);
                // setCompletedOrders(orders)
                getCompleteOrderList({ ...currentFilter, searchText: '' }, currentTab)
            }
        } else {
            const tokenArr = []; // Array for Token List
            const singleOrderArr = []; // Array for single order List
            openOrderList && openOrderList.tokenOrders.forEach(order => {
                if (String(order.tag).toLocaleLowerCase().includes(String(value).toLocaleLowerCase())) {
                    tokenArr.push(order);
                }
            })
            openOrderList && openOrderList.singleOrders.forEach(order => {
                if (order.customer && (String(order.customer.name).toLocaleLowerCase().includes(String(value).toLocaleLowerCase())) || (String(order.customer.mobileNumber).toLocaleLowerCase().includes(String(value).toLocaleLowerCase()))) {
                    singleOrderArr.push(order);
                }
            })
            let cloneOrders = deepCopy(openOrderList);
            cloneOrders.tokenOrders = tokenArr;
            cloneOrders.singleOrders = singleOrderArr;

            // filter by order types if selected
            if (selectedOrderTypeOptions.length > 0) {
                handleTerminalAndOrderTypeFilter(selectedOrderTypeOptions, 'deliveryMethods', value, cloneOrders)
            } else {
                sortOpenOrdersByOrderType(cloneOrders, currentFilter.orderTypes, pageDetails.currentPage);
            }
        }
        handlePaginator(emptyArr, pageDetails.pageSize, pageDetails.currentPage);
        setCurrentFilter(prev => ({ ...prev, searchText: value }))
    }

    const selectStyles = {
        menuList: (styles) => ({
            ...styles,
            background: "#fff",
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
                ? "#CAB05D"
                : isSelected
                    ? "hsla(291, 64%, 42%, 1)"
                    : undefined,
            zIndex: 1,
        }),
        control: (styles, { isFocused, isSelected }) => ({
            ...styles,
            minHeight: isFocused ? 58 : isSelected ? 58 : 58,
            zIndex: 1,
            borderRadius: 10,
        }),

        menu: (base) => ({
            ...base,
            zIndex: 100,
        }),
        valueContainer: (base) => ({
            ...base,
            zIndex: 100,
            maxHeight: 58,
            overflow: 'auto',
        }),
    };

    const handleClearFilter = () => {
        handleDaysFilter("TODAY");
    };

    const handleApplyFilter = () => {
        setShowAdvanceDateFilter(false); // Close the popover

        // Check if both startDate and endDate are available
        let updatedFilter = { ...currentFilter };

        if (tempFilter.startDate && tempFilter.endDate) {
            setSelectedLabel(`From ${tempFilter.startDate} to ${tempFilter.endDate}`);
            updatedFilter = {
                ...updatedFilter,
                startDate: tempFilter.startDate,
                endDate: tempFilter.endDate
            };
        } else if (tempFilter.startDate) {
            setSelectedLabel(`From ${tempFilter.startDate} To ${currentFilter.endDate}`);
            updatedFilter = {
                ...updatedFilter,
                startDate: tempFilter.startDate
            };
        } else if (tempFilter.endDate) {
            setSelectedLabel(`From ${currentFilter.startDate} To ${tempFilter.endDate}`);
            updatedFilter = {
                ...updatedFilter,
                endDate: tempFilter.endDate
            };
        }

        // Apply the updated filter
        setCurrentFilter(updatedFilter);
        getCompleteOrderList(updatedFilter, currentTab);

        // Reset tempFilter after applying
        setTempFilter({ startDate: "", endDate: "" });
    };

    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <Row className="g-3">
                    <Col xs={12}>
                        <Button
                            className={`light-btn w-100 text-truncate ${currentFilter.startDate === standardDateFormat(new Date(), dateFormat) &&
                                currentFilter.endDate === standardDateFormat(new Date(), dateFormat)
                                ? 'bg-light-yellow'
                                : 'text-secondary'
                                }`}
                            variant="primary"
                            type="button"
                            onClick={() => handleDaysFilter('TODAY')}
                        >
                            Today's
                        </Button>
                    </Col>
                    <Col xs={12}>
                        <Button
                            className={`light-btn w-100 text-truncate ${currentFilter.startDate === standardDateFormat(yesterday, dateFormat) &&
                                currentFilter.endDate === standardDateFormat(yesterday, dateFormat)
                                ? 'bg-light-yellow'
                                : 'text-secondary'
                                }`}
                            variant="primary"
                            type="button"
                            onClick={() => handleDaysFilter('YESTERDAY')}
                        >
                            Yesterday's
                        </Button>
                    </Col>
                    <Col xs={12}>
                        <label className="mb-1">Custom Range</label>
                        <Row className="g-3">
                            <Col xs={12}>
                                <Form.Floating>
                                    <Form.Control
                                        id="floatingInputCustom"
                                        type="date"
                                        autoComplete="off"
                                        name="startDate"
                                        placeholder="Start Date"
                                        value={tempFilter.startDate ? tempFilter.startDate : currentFilter.startDate}
                                        max={tempFilter.endDate || ""}
                                        onChange={handleDateFilter}
                                    />
                                    <span className="date-icon">
                                        <i className="far fa-calendar-alt"></i>
                                    </span>
                                    <Form.Label htmlFor="floatingInputCustom">From</Form.Label>
                                </Form.Floating>
                            </Col>
                            <Col xs={12}>
                                <Form.Floating>
                                    <Form.Control
                                        id="floatingInputCustom"
                                        type="date"
                                        autoComplete="off"
                                        name="endDate"
                                        placeholder="End Date"
                                        value={tempFilter.endDate ? tempFilter.endDate : currentFilter.endDate}
                                        min={tempFilter.startDate || ""}
                                        onChange={handleDateFilter}
                                    />
                                    <span className="date-icon">
                                        <i className="far fa-calendar-alt"></i>
                                    </span>
                                    <Form.Label htmlFor="floatingInputCustom">To</Form.Label>
                                </Form.Floating>
                            </Col>
                            <Col xs={12}>
                                <div className="d-flex justify-content-between">
                                    <Button
                                        variant="secondary"
                                        className="w-45"
                                        onClick={handleClearFilter}
                                    >
                                        Clear
                                    </Button>
                                    <Button
                                        variant="primary"
                                        className="w-45"
                                        onClick={handleApplyFilter}
                                    >
                                        OK
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Popover.Body>
        </Popover>
    );


    return (
        <>
            <ToastManager toastManager={toastMessage} setToastManager={setToastMessage} />
            <Container fluid className={userRole === 'BUSINESS_ADMIN' ? "p-0" : 'px-3'}>
                <Row className={userRole === 'BUSINESS_ADMIN' ? "g-0" : 'mt-4 g-0'}>
                    <Col md={12}>
                        <Row className="g-3">
                            {userRole === 'BUSINESS_ADMIN' ?
                                <>
                                    <Col md={12}>
                                        <Row className="g-3">
                                            <Col xs={6}>
                                                <Nav className="card-tabs">
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            active={currentTab === 'COMPLETEORDERS'} onClick={() => onChangeTab('COMPLETEORDERS')}
                                                        >Complete Orders</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link
                                                            active={currentTab === 'OPENORDERS'} onClick={() => onChangeTab('OPENORDERS')}
                                                        >Open Orders</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                            {currentTab === 'COMPLETEORDERS' ?
                                                <Col xs={6} className='d-flex justify-content-end'>
                                                    <Form.Check type='checkbox' label='Show Cancel Sales' checked={showCancelSales} onChange={handleCancelSales} />
                                                </Col> : null}
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row className="g-3">

                                            <Col md={5}>
                                                <Form.Floating className="search_bar">
                                                    <span class="input-group-text search_icon" ><LuSearch /></span>
                                                    <Form.Control className="search_input" id="productSearch" type="search" autoComplete='off' name="filterText" placeholder="Search By Name & Mobile Number" value={currentFilter.searchText} onChange={handleCustomerSearch} />
                                                    <Form.Label htmlFor="floatingInputCustom">{currentTab === 'OPENORDERS' ? <>Search by Name, Mobile Number & Token no</> : <>Search By Name & Mobile Number</>} </Form.Label>
                                                </Form.Floating>
                                            </Col>
                                            {/* {currentTab === 'OPENORDERS' ?
                                                <Col xs={6} sm={4} md={4} lg={2}>
                                                    <Form.Floating>
                                                        <Select
                                                            onChange={currentFilter.orderTypes.length > 0 ? filterRecordByOrderStage : filterRecordByOrderStageALL}
                                                            isMulti
                                                            name="orderStage"
                                                            options={formatOrderStageOptions(currentFilter.orderTypes.length > 0 ? orderStages : orderStagesALL)}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            styles={selectStyles}
                                                            placeholder="Search by order stage"
                                                        />
                                                    </Form.Floating>
                                                </Col> : null} */}
                                            {currentTab === 'COMPLETEORDERS' ?
                                                <Col xs={6} sm={4} md={4} lg={3}>
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        trigger="click"
                                                        show={showAdvanceDateFilter}
                                                        onToggle={(nextShow) => setShowAdvanceDateFilter(nextShow)} // Synchronize visibility
                                                        overlay={popover}
                                                    >
                                                        <Button className="light-btn h-56 w-100" variant="primary" type="button" onClick={() => setShowAdvanceDateFilter(!showAdvanceDateFilter)}>
                                                            <FiFilter className="me-1 fs-16" />
                                                            {selectedLabel}
                                                        </Button>
                                                    </OverlayTrigger>



                                                </Col> : null}
                                            {/* <Col xs={6} sm={4} md={4} lg={2}>
                                                <Button className={`light-btn w-100 text-truncate h-56 ${currentFilter.startDate === standardDateFormat(new Date(), dateFormat) && currentFilter.endDate === standardDateFormat(new Date(), dateFormat) ? "bg-light-yellow" : "text-secondary"}`} variant="primary" type="button" onClick={() => handleDaysFilter('TODAY')}>Today's</Button>
                                            </Col>
                                            <Col xs={6} sm={4} md={4} lg={2}>
                                                <Button className={`light-btn w-100 text-truncate h-56 ${currentFilter.startDate === standardDateFormat(yesterday, dateFormat) && currentFilter.endDate === standardDateFormat(yesterday, dateFormat) ? "bg-light-yellow" : "text-secondary"}`} variant="primary" type="button" onClick={() => handleDaysFilter('YESTERDAY')}>Yesterday's</Button>
                                            </Col>
                                            <Col xs={6} sm={4} md={4} lg={2}>
                                                <Form.Floating >
                                                    <Form.Control id="floatingInputCustom" type="date" autoComplete='off' name="startDate" placeholder="Start Date" max={standardDateFormat(currentFilter.endDate, dateFormat)} onChange={handleDateFilter} />
                                                    <span className='date-icon'><i className="far fa-calendar-alt"></i></span>
                                                    <Form.Label htmlFor="floatingInputCustom">Start Date</Form.Label>
                                                </Form.Floating>
                                            </Col>
                                            <Col xs={6} sm={4} md={4} lg={2}>
                                                <Form.Floating >
                                                    <Form.Control id="floatingInputCustom" type="date" autoComplete='off' name="endDate" placeholder="End Date" min={standardDateFormat(currentFilter.startDate, dateFormat)} onChange={handleDateFilter} />
                                                    <span className='date-icon' ><i className="far fa-calendar-alt"></i></span>
                                                    <Form.Label htmlFor="floatingInputCustom">End Date</Form.Label>
                                                </Form.Floating>
                                            </Col> */}
                                            <Col xs={6} sm={4} md={4} lg={2}>
                                                <Form.Floating>
                                                    <Select
                                                        value={selectedOrderTypeOptions}
                                                        onChange={(options) => {
                                                            setSelectedOrderTypeOptions(options);
                                                            handleTerminalAndOrderTypeFilter(options, 'deliveryMethods');
                                                        }}
                                                        isMulti
                                                        name="deliveryMethod"
                                                        options={formatedSelectOptions(methodAndTerminalList.methods)}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        styles={selectStyles}
                                                        placeholder="Select Delivery Methods"
                                                    />
                                                </Form.Floating>
                                            </Col>
                                            {currentTab === 'COMPLETEORDERS' ?
                                                <Col xs={6} sm={4} md={4} lg={2}>
                                                    <Form.Floating>
                                                        <Select
                                                            onChange={(selectedOptions) => handleTerminalAndOrderTypeFilter(selectedOptions, 'terminalIds')}
                                                            isMulti
                                                            name="terminal"
                                                            options={formatedSelectOptions(methodAndTerminalList.terminals)}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            styles={selectStyles}
                                                            placeholder="Select Terminals"
                                                        />
                                                    </Form.Floating>
                                                </Col> : null}
                                        </Row>

                                    </Col>
                                </> : null}
                            <Col md={12}>
                                {currentTab === 'COMPLETEORDERS' ?
                                    <OrderTable orderList={orderToDisplay} loading={loading} getSelectedRow={onRowSelect} exportToExcel={exportToExcel} />
                                    :
                                    <OpenOrderTable showOrderStage={false} currentOrderType={'ALL'} openOrders={orderToDisplay} loading={loading} handleModalManager={handleModalManager} setCurrentSelOrder={{}} orderStages={orderStages} orderStagesALL={orderStagesALL} onOrderStageChange={(orderDetails) => { handleModalManager('confirmModal', true); selectedOrder = orderDetails }} disableActions={true} />
                                }
                                <Row className='g-0'>
                                    <Col md={12} className='table-pagination flex-wrap d-flex align-items-center justify-content-end'>
                                        <div className='d-flex align-items-center mb-3'>
                                            <span className='mx-3'> Items per page:</span>
                                            <select className='form-select' aria-label="Floating label select example" name="productType" onChange={(e) => { handlePageSize(e) }}>
                                                {pageSizeSelectOptions.map((page, index) => {
                                                    return <option key={index} value={page.value}>{page.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center mb-3'>
                                            <span className='mx-3'> {pageDetails.currentPage + " - " + Math.ceil((currentTab === 'OPENORDERS' ? openOrdersByType?.length : completedOrders.length) / pageDetails.pageSize) + " of " + (currentTab === 'COMPLETEORDERS' ? completedOrders?.length : openOrdersByType.length)}</span>
                                            <Button className='light-btn px-3' variant="primary" disabled={pageDetails.currentPage === 1 ? true : false} onClick={() => handlePagination('PREV')}><IoChevronBackOutline className='fs-16' /></Button>
                                            <Button className='light-btn px-3 ms-3' variant="primary" disabled={((currentTab === 'OPENORDERS' ? openOrdersByType?.length : completedOrders.length) < pageDetails.pageSize || pageDetails.currentPage === Math.ceil((currentTab === 'OPENORDERS' ? openOrdersByType?.length : completedOrders.length) / pageDetails.pageSize)) ? true : false} onClick={() => handlePagination('NEXT')}><IoChevronForwardOutline className='fs-16' /></Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <SingleOrderDetailModal {...singleOrderDetailModalProps} />
            <TokenOrderDetailModal {...tokenOrderDetailModalProps} />
        </>
    )
};

export default CompleteOrder;
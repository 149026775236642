import React from "react";
import { Col, Row, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';

const CategoryView = ({ categoryList = [], handleClick }) => {
    return (
        categoryList && categoryList.map((ml, index) => {
            return (
                <>
                    {ml.length <= 2 ?
                        <Col md={5} key={index}>
                            <Row className="g-3">
                                <Col md={6} className="">
                                    <OverlayTrigger placement='top' overlay={<Tooltip>{ml[0].catAlias}</Tooltip>}>
                                        <Button name={ml[0].catAlias} className={`light-btn w-100 text-truncate h-56 ${ml[0].className === undefined ? "text-dark" : ml[0].className}`} onClick={() => handleClick(ml[0])} >{ml[0].catAlias} </Button>
                                    </OverlayTrigger>
                                </Col>
                                {ml.length > 1 ?
                                    <Col md={6} className="">
                                        <OverlayTrigger placement='top' overlay={<Tooltip>{ml[1].catAlias}</Tooltip>}>
                                            <Button name={ml[1].catAlias} className={`light-btn w-100 text-truncate h-56 ${ml[1].className === undefined ? "text-dark" : ml[1].className}`} onClick={() => handleClick(ml[1])}>{ml[1].catAlias} </Button>
                                        </OverlayTrigger>
                                    </Col> : null}
                            </Row>
                        </Col> : null}
                    {ml.length === 3 ?
                        <Col md={7} key={index}>
                            <Row className="g-3">
                                <Col md={4} className="">
                                    <OverlayTrigger placement='top' overlay={<Tooltip>{ml[0].catAlias}</Tooltip>}>
                                        <Button name={ml[0].catAlias} className={`light-btn w-100 text-truncate h-56 ${ml[0].className === undefined ? "text-dark" : ml[0].className}`} onClick={() => handleClick(ml[0])}>{ml[0].catAlias} </Button>
                                    </OverlayTrigger>
                                </Col>
                                <Col md={4} className="">
                                    <OverlayTrigger placement='top' overlay={<Tooltip>{ml[1].catAlias}</Tooltip>}>
                                        <Button name={ml[1].catAlias} className={`light-btn w-100 text-truncate h-56 ${ml[1].className === undefined ? "text-dark" : ml[1].className}`} onClick={() => handleClick(ml[1])}>{ml[1].catAlias} </Button>
                                    </OverlayTrigger>
                                </Col>
                                <Col md={4} className="">
                                    <OverlayTrigger placement='top' overlay={<Tooltip>{ml[2].catAlias}</Tooltip>}>
                                        <Button name={ml[2].catAlias} className={`light-btn w-100 text-truncate h-56 ${ml[2].className === undefined ? "text-dark" : ml[2].className}`} onClick={() => handleClick(ml[2])}>{ml[2].catAlias} </Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Col> : null}
                </>
            )
        }
        )
    )
}

export default CategoryView;
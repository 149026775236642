import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import NumberField from "../../../../../../components/common/App/NumberField";
import { states } from "../../../../../../util/constants";
import { useDispatch } from "react-redux";
import { searchCustomer } from "../../../../../../actions/billdesk/BillDeskActions";
import AutoCompleter from "../../../../../../components/common/App/AutoCompleter";
import { standardDateFormat } from "../../../../../../util/utility-helper";

const dateFormat = process.env.REACT_APP_DATE_FORMAT;
const CustomerDetailModal = ({ ...props }) => {
    const dispatch = useDispatch();

    const [customerDetails, setCustomerDetails] = useState({});
    const [searchBarList, setSearchBarList] = useState([]);
    const [clearSearch, setClearSearch] = useState(false);

    useEffect(() => {
        if (props.showModal) {
            setCustomerDetails({ ...props.customerDetails });
        }
    }, [props.showModal, props.customerDetails]);

    const onSubmit = () => {
        props.changeCustomerDetails(customerDetails);
        props.hideModal();
    };

    const handleChange = (event) => {
        setCustomerDetails({ ...customerDetails, [event.target.name]: event.target.value });
    };

    const handleCustomerSearch = async (searchVal) => {
        await dispatch(searchCustomer({ 'searchFilter': searchVal }))
            .then((response) => {
                if (response.payload) {
                    setSearchBarList(response.payload.splice(1, 5));
                }
            })
    };

    const onCustomerSelect = (details) => {
        if (details.selItm) {
            setCustomerDetails({ ...details.selItm, orderDate: props.customerDetails.orderDate })
        }
    };


    return (
        <Modal size="lg" backdrop="static" keyboard={false} centered show={props.showModal} onHide={props.hideModal} >
            <Modal.Header className='border-0 pb-0' closeButton>
                <Row className='mx-0 g-3 w-100 align-items-center me-3'>
                    <Col md={12} lg={6} className='d-sm-flex d-md-flex flex-wrap d-block align-items-center pe-4'>
                        <Modal.Title className='fs-5 fw-bold'>Customer Details</Modal.Title>
                    </Col>
                    <Col xs={6} md={6} lg={3}></Col>
                    {/* <Col xs={6} md={6} lg={3}>
                        <Button className='light-btn w-100 text-truncate h-56 text-uppercase  d-flex align-items-center justify-content-center' variant="primary" onClick={() => { setCustomerDetails({ ...props.customerDetails }); setClearSearch(true) }}>
                            <FaRotateLeft className='fs-16 me-2' /> Clear
                        </Button>
                    </Col> */}
                </Row>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col md={12} className=''>
                        <AutoCompleter disabled={props.isFieldDisabled} clearSearch={clearSearch} setClearSearch={setClearSearch} name="name" data={searchBarList} onChange={onCustomerSelect} placeholder="Search by customer name and mobile number" onInputChange={handleCustomerSearch} extraLabel="mobileNumber" />
                    </Col>
                    <Col md={6}>
                        <Form.Floating className="">
                            <Form.Control disabled={props.isFieldDisabled} type="text" autoComplete='off' name="name" id="floatingInputCustom" placeholder="Product Name" value={customerDetails.name || ""} defaultValue={customerDetails.name} onChange={handleChange} />
                            <Form.Label htmlFor="floatingInputCustom">Customer Name</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={6}>
                        <Form.Floating className="">
                            <Form.Control disabled type="date" autoComplete='off' name="orderDate" id="floatingInputCustom" placeholder="Product Name" value={standardDateFormat(customerDetails.orderDate, dateFormat)} defaultValue={standardDateFormat(customerDetails.orderDate, dateFormat)} onChange={handleChange} />
                            <Form.Label htmlFor="floatingInputCustom">Order Date</Form.Label>
                        </Form.Floating>
                    </Col>
                    {/* <Col md={6}>
                        <Row className="g-3"> */}
                    <Col md={6}>
                        <Form.Floating className="">
                            <Form.Control disabled={props.isFieldDisabled} type="text" name="gstNumber" value={customerDetails.gstNumber || ''} defaultValue={customerDetails.gstNumber || ''} onChange={handleChange} placeholder="GST Number" />
                            <Form.Label htmlFor="floatingInputCustom">GST Number</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={6}>
                        <Form.Floating className="">
                            <NumberField disabled={props.isFieldDisabled} maxLength={10} name="mobileNumber" value={customerDetails.mobileNumber} defaultValue={customerDetails.mobileNumber || ""} onChange={handleChange} placeholder="Phone Number" />
                            <Form.Label htmlFor="floatingInputCustom">Phone Number</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={6}>
                        <Form.Floating className="">
                            <Form.Control disabled={props.isFieldDisabled} as="textarea" rows={5} type="text" autoComplete='off' name="line1" id="floatingInputCustom" placeholder="Product Price" value={customerDetails.line1 || ""} defaultValue={customerDetails.line1} onChange={handleChange} />
                            <Form.Label htmlFor="floatingInputCustom">Address</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={6}>
                        <Form.Floating controlId="floatingSelect">
                            <Form.Select disabled={props.isFieldDisabled} aria-label="Floating label select example" name="stateCode" onChange={handleChange}>
                                <option disabled selected>Select State</option>
                                {states.map((st) => {
                                    return (
                                        <option value={st.code} selected={st.code === customerDetails.stateCode}>{st.name}</option>
                                    )
                                })}
                            </Form.Select>
                            <Form.Label htmlFor="floatingInputCustom">Select State</Form.Label>
                        </Form.Floating>
                    </Col>
                    {/* </Row>
                    </Col> */}

                    <Col md={6}>
                        <Form.Floating className="">
                            <Form.Control disabled={props.isFieldDisabled} rows={5} type="text" autoComplete='off' name="city" id="floatingInputCustom" placeholder="Town / City / District" value={customerDetails.city || ""} defaultValue={customerDetails.city} onChange={handleChange} />
                            <Form.Label htmlFor="floatingInputCustom">Town / City / District</Form.Label>
                        </Form.Floating>
                    </Col>
                    <Col md={6}>
                        <Form.Floating className="">
                            <NumberField disabled={props.isFieldDisabled} maxLength={6} autoComplete='off' name="zipCode" id="floatingInputCustom" placeholder="PIN CODE" value={customerDetails.zipCode || ""} defaultValue={customerDetails.zipCode} onChange={handleChange} />
                            <Form.Label htmlFor="floatingInputCustom">PIN Code</Form.Label>
                        </Form.Floating>
                    </Col>
                </Row>
            </Modal.Body>
            {!props.isFieldDisabled ?
                <Modal.Footer className='border-0 pt-0'>
                    <Row className='g-3 w-100 justify-content-end'>
                        <Col md={3} lg={2} className='m-0'>
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={onSubmit} >Save</Button>
                        </Col>
                    </Row>
                </Modal.Footer> : null}
        </Modal >
    )
};

export default CustomerDetailModal;
import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner, InputGroup } from "react-bootstrap";
import { BiPencil } from "react-icons/bi";
import { useDispatch } from 'react-redux';
import { updateProduct, changeProductImage, getProductById } from "../../../../../actions/billdesk/BillDeskActions";
import proImg from '../../../../../assets/images/upload.jpg';
import { IoIosCloseCircleOutline } from "react-icons/io";
import videoImg from '../../../../../assets/images/play-button.png'
import NumberField from "../../../../../components/common/App/NumberField";
import { uomData } from "../../../../../util/constants";
import { validateFields, validateURL } from "../../../../../util/utility-helper";

const endPoint = process.env.REACT_APP_API_BASE_ENDPOINT;
let isDataChangeFlag = false;
const initialButtonState = { save: { loading: false, disable: true }, delete: { loading: false, disable: false } };
const mandatoryFields = ['prodAlias', 'price', 'uom', 'perGst', 'categoryId']

const EditProduct = ({ showModal, hideModal, selectedProduct, productList, handleToastManager }) => {
    // useDispatch hook for dispatching actions
    const dispatch = useDispatch();
    // current added product.
    const [currentProduct, setCurrentProduct] = useState(selectedProduct || []);
    const [mainCat, setMainCat] = useState("");
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCat, setSelectedSubCat] = useState(""); // State for selected subcategory
    const [fileList, setFileList] = useState([]);
    const [video, setVideo] = useState({});

    const [buttonState, setButtonState] = useState({ ...initialButtonState });

    const curretSelImageRef = useRef(null)

    useEffect(() => {
        if (selectedProduct && selectedProduct.id) {
            getOrderDetailsById(selectedProduct.id);
        }
    }, [selectedProduct]);

    const handleCloseModal = () => {
        // Reset currentProduct to the original selectedProduct
        setCurrentProduct(selectedProduct || []);
        hideModal(isDataChangeFlag);
    };

    const getOrderDetailsById = async (prodId) => {
        await dispatch(getProductById(prodId))
            .then((response) => {
                if (response.payload) {
                    if (response.payload.videoUrl && response.payload.videoUrl !== '' && response.payload.videoUrl !== 'N') {
                        if (response.payload.videoUrl === 'Y') {
                            setVideo({ src: endPoint + String(response?.payload?.icon || "").replace("_1.jpg", "_v.mp4"), fileName: endPoint + String(response?.payload?.icon || "").replace("_1.jpg", "_v.mp4").split('/').pop() })
                        }
                    } else {
                        setVideo({});
                    }
                    productList && productList.forEach(cat => {
                        if (cat && cat.categories) {
                            cat.categories.forEach(subCat => {
                                if (subCat.id === response.payload.categoryId) {
                                    setMainCat(cat.id);
                                    setSelectedSubCat(subCat.id);
                                    setSubCategories(cat.categories)
                                }
                            })
                        }
                    });
                    setButtonState({ ...initialButtonState })
                    setCurrentProduct({
                        ...response.payload, icon: response.payload.icon ? endPoint + response.payload.icon + "?" + Math.random() : null, onlineSwitch: response.payload.videoUrl ? (response.payload.videoUrl !== 'Y' && response.payload.videoUrl !== 'N') ? "Y" : "N" : "N", categories: [{ id: response.payload.categoryId }]
                    });
                }
            })
    };

    useEffect(() => {
        const selectedCategoryObj = productList.find(cat => cat.catAlias === mainCat);
        if (selectedCategoryObj) {
            setSubCategories(selectedCategoryObj.categories || []);
        }
    }, [mainCat, productList]);

    const onHandleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setCurrentProduct(prevState => ({
            ...prevState,
            [name]: type === "checkbox" ? (checked ? "Y" : "N") : value
        }));
        if (name === "uploadSwitch" && checked) {
            const newFileList = [];
            for (let i = 1; i <= 5; i++) {
                if (currentProduct[`image${i}`] === 'Y') {
                    newFileList.push({ icon: currentProduct?.icon ? currentProduct.icon?.replace("_1", `_${i}`) + "?" + Math.random() : null, fileName: (currentProduct.id + "_" + i), files: currentProduct.iconSrc || "" });
                } else {
                    newFileList.push({ icon: null })
                }
            }
            setFileList([...newFileList])
        } else {
            if (name === 'uploadSwitch' && !checked)
                setFileList([])
        }
        setButtonState({ ...buttonState, save: { ...buttonState.save, disable: validateFields(mandatoryFields, { ...currentProduct, [name]: type === "checkbox" ? (checked ? "Y" : "N") : value }) } });
    };

    const onCategorySelect = (event) => {
        const selectedCatId = event.target.value;
        const selectedCat = productList.find(cat => parseInt(cat.id) === parseInt(selectedCatId));
        if (selectedCat) {
            setMainCat(selectedCatId);
            setSubCategories(selectedCat.categories || []);
            // setCurrentProduct(prevState => ({
            //     ...prevState,
            //     categories: [{ id: selectedCat.id }],
            //     categoryId:
            // }));
            if (selectedCat.categories && selectedCat.categories.length > 0) {
                setSelectedSubCat(selectedCat.categories[0].id);
                setCurrentProduct({ ...currentProduct, categoryId: selectedCat.categories[0].id });
                setButtonState({ ...buttonState, save: { ...buttonState.save, disable: validateFields(mandatoryFields, { ...currentProduct, categoryId: selectedCat.categories[0].id }) } });
            } else {
                setSelectedSubCat('');
                setCurrentProduct({ ...currentProduct, categoryId: null });
                setButtonState({ ...buttonState, save: { ...buttonState.save, disable: true } })
            }
        }
    };

    const onSubCategorySelect = (event) => {
        const selectedSubCatId = event.target.value;
        setSelectedSubCat(selectedSubCatId); // Update selected subcategory
        setCurrentProduct(prevState => ({
            ...prevState,
            categories: [{ id: selectedSubCatId }] // Only one subcategory ID
        }));
        setButtonState({ ...buttonState, save: { ...buttonState.save, disable: validateFields(mandatoryFields, { ...currentProduct, categories: [{ id: selectedSubCatId }], categoryId: selectedSubCatId === 'Select Sub Category' ? null : currentProduct.categoryId }) } });
    };


    // Handle the update button click
    const handleProductUpdate = async () => {
        setButtonState({ ...buttonState, save: { ...buttonState.save, loading: true }, delete: { ...buttonState.delete, disable: true } });

        if (currentProduct?.onlineSwitch === "Y" && currentProduct?.videoUrl && !validateURL(currentProduct.videoUrl)) {
            handleToastManager({ visible: true, toastMessage: 'Invalid Youtube video url', variant: "Warning" })
            setButtonState({ ...buttonState, save: { ...buttonState.save, loading: false }, delete: { ...buttonState.delete, disable: false } });
            return;
        }

        if (currentProduct.uploadSwitch === 'Y') {
            if (fileList && fileList.length > 0) {
                if (fileList.find(e => e.file !== null)) {
                    await updateProductImage(fileList, 0, currentProduct);
                } else {
                    handleToastManager({ visible: true, toastMessage: 'Atleast one image need to be upload', variant: "Danger" })
                }
                if (video.icon) {
                    if (!currentProduct.onlineSwitch || currentProduct.onlineSwitch === 'N') {
                        const formData = new FormData();
                        formData.append('fileName', currentProduct.id + "_v");
                        formData.append('files', video.icon);
                        await dispatch(changeProductImage(formData)).then(async (uploadRes) => {
                            if ((uploadRes.payload && !uploadRes.payload.error)) {
                                await dispatch(updateProduct({ id: currentProduct.id, videoUrl: currentProduct.videoUrl, videoUrl: "Y", icon: fileList.find(e => e.icon !== null) === undefined ? String(uploadRes.payload?.resourcePath || "").replace("_v.mp4", "_1.jpg") : undefined })).then((response) => {
                                    if (response.payload && !response.payload.error) {
                                        handleToastManager({ visible: true, toastMessage: 'Video uploaded.', variant: "Success" });
                                        isDataChangeFlag = true;
                                        setButtonState({ ...initialButtonState })
                                        getOrderDetailsById(selectedProduct.id);
                                    } else {
                                        handleToastManager({ visible: true, toastMessage: 'Failed to update product.', variant: "Danger" })
                                    }
                                })
                            }
                        })
                    }
                }
            } else {
                handleToastManager({ visible: true, toastMessage: 'Please upload atleast one image', variant: "Warning" })
                return;
            }
        } else {
            let newProductDetails = { ...currentProduct };
            if (currentProduct.icon && (endPoint + selectedProduct.icon) !== currentProduct.icon) {
                const formData = new FormData();
                formData.append('fileName', currentProduct.id + "_1");
                formData.append('files', currentProduct.iconSrc);
                await dispatch(changeProductImage(formData)).then(async (response) => {
                    if ((response.payload && !response.payload.error)) {
                        newProductDetails.icon = response.payload.resourcePath;
                    }
                })
            } else {
                newProductDetails.icon = selectedProduct.icon
            }
            await dispatch(updateProduct({ ...newProductDetails })).then((response) => {
                if ((response.payload && !response.payload.error)) {
                    handleToastManager({ visible: true, toastMessage: 'Product updated successfully', variant: "Success" })
                    isDataChangeFlag = true;
                    setButtonState({ ...initialButtonState });
                    getOrderDetailsById(selectedProduct.id);
                } else {
                    setButtonState({ ...initialButtonState });
                    handleToastManager({ visible: true, toastMessage: 'Failed to updated Product. Please try again.', variant: "Danger" })
                }
            })
        }
    };

    const updateProductImage = async (fileArray = [], index = 0, productDetails, codeBreaker) => {
        if (fileArray.length > 0 && fileArray[index]) {
            const fileChangePayload = { fileSrc: fileArray[index].icon, file: fileArray[index].files, fileName: fileArray[index].fileName };
            if (fileChangePayload.file) {
                const formData = new FormData();
                formData.append("files", fileChangePayload.file);
                formData.append("fileName", fileChangePayload.fileName);
                await dispatch(changeProductImage(formData))
                    .then(async (fileUploadRes) => {
                        if ((fileUploadRes.payload && !fileUploadRes.payload.error)) {
                            if ((index + 1) === 1) {
                                productDetails.icon = fileUploadRes.payload.resourcePath;
                            }
                            await dispatch(updateProduct({ ...productDetails }))
                                .then((updatedRes) => {
                                    if ((updatedRes.payload && !updatedRes.payload.error)) {
                                        if (fileArray.length - 1 > index) {
                                            updateProductImage(fileArray, index + 1, productDetails)
                                        } else {
                                            isDataChangeFlag = true;
                                            setButtonState({ ...initialButtonState })
                                            getOrderDetailsById(productDetails.id);
                                            handleToastManager({ visible: true, toastMessage: 'Product updated successfully', variant: "Success" });
                                        }
                                    } else {
                                        setButtonState({ ...initialButtonState })
                                        handleToastManager({ visible: true, toastMessage: 'Failed to updated Product. Please try again.', variant: "Danger" })
                                    }
                                })
                        } else {
                            handleToastManager({ visible: true, toastMessage: 'Error while uploading image', variant: "Danger" })
                        }
                    })
            } else {
                if (fileArray.length - 1 > index) {
                    if (!codeBreaker) {
                        if (productDetails.icon && (endPoint + String(selectedProduct.icon).split("?")[0]) === String(productDetails.icon).split('?')[0]) {
                            productDetails.icon = selectedProduct.icon
                        }
                        await dispatch(updateProduct({ ...productDetails }))
                            .then((response) => {
                                if ((response.payload && !response.payload.error)) {
                                    updateProductImage(fileArray, index + 1, productDetails, true)
                                } else {
                                    setButtonState({ ...initialButtonState })
                                    handleToastManager({ visible: true, toastMessage: 'Failed to updated Product. Please try again.', variant: "Danger" })
                                }
                            })
                    } else {
                        updateProductImage(fileArray, index + 1, productDetails, true)
                    }
                } else {
                    isDataChangeFlag = true;
                    setButtonState({ ...initialButtonState })
                    getOrderDetailsById(productDetails.id);
                    handleToastManager({ visible: true, toastMessage: 'Product updated successfully', variant: "Success" });
                }
            }
        }
    };

    const openFileUploader = (fileType) => {
        document.getElementById(fileType).click()
    };
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
        if (file) {
            let productState = { ...currentProduct };
            if (productState.uploadSwitch === 'Y') {
                let updatedFileList = [...fileList];

                // Find the first slot that is empty by checking for files property being empty, null, or undefined
                const firstAvailableIndex = curretSelImageRef.current !== null ? curretSelImageRef.current : updatedFileList.findIndex((fileItem) => fileItem.icon === null);


                if (firstAvailableIndex !== -1) {
                    // Update the file at the first available slot
                    updatedFileList[firstAvailableIndex] = { fileName: `${currentProduct.id}_${firstAvailableIndex + 1}`, files: file, icon: URL.createObjectURL(file) };

                    // Update the corresponding `currentProduct` property
                    productState = { ...productState, [`image${firstAvailableIndex + 1}`]: 'Y', };
                    setCurrentProduct({ ...productState });

                    // Update the state for fileList
                    setFileList([...updatedFileList]);

                    // Enable the save button

                }
            } else {
                productState = { ...productState, icon: URL.createObjectURL(file) + "?" + Math.random(), iconSrc: file, image1: 'Y' }
                setCurrentProduct({ ...productState, })
            }
            setButtonState({
                ...buttonState,
                save: { ...buttonState.save, disable: validateFields(mandatoryFields, { ...productState }) },
            });
        }
    };



    const openVideoUploader = () => {
        document.getElementById('videoUploader').click();
    }

    const handleVideoChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            setVideo({ src: URL.createObjectURL(event.target.files[0]), icon: event.target.files[0], fileName: event.target.files[0].name })
        }
    };

    const removeFile = (selIndx) => {
        let fileListClone = [...fileList];
        let productState = { ...currentProduct };
        if (selIndx !== undefined && selIndx !== null) {
            if (fileListClone && fileListClone.length > 0 && fileListClone[selIndx]) {
                fileListClone[selIndx] = { ...fileListClone[selIndx], icon: null };
                setFileList([...fileListClone]);
            }
            productState = { ...productState, [`image${selIndx + 1}`]: 'N' };
            setCurrentProduct({ ...productState });
        } else {
            productState = { ...productState, icon: null, iconSrc: null, image1: 'N' };
            setCurrentProduct({ ...productState, })
        }
        setButtonState({ ...buttonState, save: { ...buttonState.save, disable: validateFields(mandatoryFields, { ...productState }) } })
    };

    const handleOnlineVideo = (event) => {
        setCurrentProduct({ ...currentProduct, [event.target.name]: event.target.value })
    };

    return (
        <Modal size="lg" backdrop="static" keyboard={false} scrollable centered show={showModal} onHide={handleCloseModal}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Edit Product</Modal.Title>
            </Modal.Header>
            <>
                <Modal.Body>
                    <Row className="g-3">
                        <Col md={12} lg={12}>
                            <Form.Check
                                className=""
                                type="switch"
                                name="uploadSwitch"
                                id="upload-switch"
                                label="Advance upload image and video"
                                checked={currentProduct.uploadSwitch === "Y"}
                                onChange={onHandleChange}
                            />
                        </Col>
                        <Col md={12} lg={currentProduct.uploadSwitch === "Y" ? 12 : 3} className={currentProduct.uploadSwitch === "Y" ? "d-flex flex-wrap align-items-start px-0" : ''}>

                            {currentProduct.uploadSwitch === "Y" ?
                                <>
                                    <div className="w-100 d-flex flex-wrap align-items-start">
                                        {fileList.map((file, idx) => (
                                            <div key={file.uid} className="pro-main mx-auto mx-lg-2" >
                                                <div className="pro-menu" onClick={() => { openFileUploader('newFile'); curretSelImageRef.current = file.icon ? idx : null }}>
                                                    <div className="pro-overlay">
                                                        <BiPencil className='pro-icon' />
                                                    </div>
                                                    <img className="pro-image" key={Date.now()} src={file.icon || proImg} />
                                                    <input type="file" accept=".jpg,.jpeg,.png" name="files" id="newFile" hidden={true} onChange={(e) => handleFileChange(e, idx)} />
                                                </div>
                                                {file.icon ?
                                                    <div className="pro-trash" key={Date.now()} onClick={() => removeFile(idx)}><IoIosCloseCircleOutline /></div> : null}
                                            </div>
                                        ))}
                                    </div>
                                    {video.src ?
                                        <>
                                            <div className="pro-main mx-auto mx-lg-2" >
                                                <div className="pro-menu" onClick={() => setVideo({ ...video, previewLink: true })}>
                                                    <img className="pro-image" src={videoImg} />
                                                </div>
                                                <div className="pro-trash" onClick={() => { setVideo({}); setCurrentProduct({ ...currentProduct, videoUrl: "N" }) }}><IoIosCloseCircleOutline /></div>
                                            </div>
                                            {video.previewLink ?
                                                <div
                                                    style={{ padding: '20px', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}
                                                    onClick={() => setVideo({ ...video, previewLink: false })}
                                                >
                                                    <div style={{ padding: '20px' }}>
                                                        <Button className="btn-close ms-auto d-block bg-transparent mb-2 text-white"></Button>
                                                        <div className="" >
                                                            <video className=""
                                                                width="100%"
                                                                controls
                                                                style={{ objectFit: 'contain' }}
                                                                height={400}
                                                                src={video.src} />
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}

                                        </>
                                        : null}
                                </>
                                : <div className="pro-main mx-auto mx-lg-2" >
                                    <div className="pro-menu" onClick={() => { openFileUploader('singleImg'); curretSelImageRef.current = null }}>
                                        <div className="pro-overlay">
                                            <BiPencil className='pro-icon' />
                                        </div>
                                        <img className="pro-image" key={Date.now()} src={currentProduct.icon || proImg} />
                                        <input type="file" name="files" accept=".jpg,.jpeg,.png" id="singleImg" hidden={true} onChange={handleFileChange} />
                                    </div>
                                    {currentProduct.icon ?
                                        <div className="pro-trash" onClick={() => removeFile(null)}><IoIosCloseCircleOutline /></div> : null}
                                </div>}
                        </Col>

                        {currentProduct.uploadSwitch === "Y" ?
                            <>
                                <Col md={12} lg={12}>
                                    <Form.Check
                                        className=""
                                        type="switch"
                                        name="onlineSwitch"
                                        onChange={onHandleChange}
                                        label={currentProduct.onlineSwitch === "Y" ? "Online Link" : "Browse Video"}
                                        checked={currentProduct.onlineSwitch === "Y"}
                                    />
                                </Col>
                                {currentProduct.onlineSwitch === "Y" ?

                                    <Col md={12} lg={12}>
                                        <Form.Control
                                            onChange={handleOnlineVideo}
                                            style={{ height: '46px', lineHeight: '1.5' }}
                                            placeholder="YouTube URL"
                                            value={currentProduct.videoUrl || ""}
                                            aria-label="YouTube URL"
                                            name="videoUrl"
                                            aria-describedby="basic-addon2"
                                        />
                                    </Col> :
                                    <>
                                        <Col md={12} lg={12}>
                                            <InputGroup >
                                                <Form.Control
                                                    aria-describedby="basic-addon2"
                                                    value={video?.fileName || ""}
                                                    enabled={false}
                                                />
                                                <Button className='light-btn bg-light-grey ' variant="primary" id="button-addon2" onClick={openVideoUploader}>
                                                    Browser
                                                </Button>
                                            </InputGroup>
                                            <input type="file" name="video" id="videoUploader" accept=".mp4" hidden={true} onChange={handleVideoChange} />
                                        </Col>
                                    </>
                                }
                                <hr />
                            </>
                            : null}
                        <Col md={12} lg={currentProduct.uploadSwitch === "Y" ? 12 : 9}>
                            <Row className="g-3">
                                <Col md={6}>
                                    <Form.Floating className="">
                                        <Form.Control type="text" autoComplete='off' name="prodAlias" id="floatingInputCustom" placeholder="Product Name" value={currentProduct.prodAlias} defaultValue={currentProduct.prodAlias} onChange={(e) => { onHandleChange(e) }} />
                                        <Form.Label htmlFor="floatingInputCustom">Product Name<span className="text-danger">*</span></Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={6}>
                                    <Form.Floating className="">
                                        <NumberField decimals={2} type="text" autoComplete='off' name="price" id="floatingInputCustom" placeholder="Product Price" value={currentProduct.price} defaultValue={currentProduct.price} onChange={(e) => { onHandleChange(e) }} />
                                        <Form.Label htmlFor="floatingInputCustom">Product Price<span className="text-danger">*</span></Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={6} className="d-flex align-items-end">
                                    <Form.Floating className="w-100">
                                        <Form.Select aria-label="Category Select" name="uom" value={currentProduct.c} onChange={onHandleChange}>
                                            <option selected disabled>Unit of measure</option>                                            {uomData.map((uom, indx) => (
                                                <option selected={uom.value === currentProduct.uom} key={indx} value={uom.value} >
                                                    {uom.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label htmlFor="floatingInputCustom">UOM<span className="text-danger">*</span></Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={6}>
                                    <Form.Check className="" type="switch" name="isPriceGstInc" id="custom-switch" checked={currentProduct.isPriceGstInc === 'Y'} label="is GST Included?" onChange={(e) => { onHandleChange(e) }} />
                                    <Form.Floating className="">
                                        <NumberField decimals={0} autoComplete='off' name="perGst" id="floatingInputCustom" placeholder="GST %" value={currentProduct.perGst} defaultValue={currentProduct.perGst} onChange={(e) => { onHandleChange(e) }} />
                                        <Form.Label htmlFor="floatingInputCustom">GST %<span className="text-danger">*</span></Form.Label>
                                    </Form.Floating>
                                </Col>
                            </Row>
                        </Col>


                        <Col md={4} className="d-flex align-items-end">
                            <Form.Floating className="w-100" >
                                <Form.Control type="text" autoComplete='off' name="hsn" id="floatingInputCustom" placeholder="Product Name" value={currentProduct.hsn} defaultValue={currentProduct.hsn} onChange={(e) => { onHandleChange(e) }} />
                                <Form.Label htmlFor="floatingInputCustom">HSN Code</Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={4}>
                            <Form.Floating className="">
                                <Form.Control
                                    type="text"
                                    autoComplete='off'
                                    name="itemNumber"
                                    id="floatingInputCustom"
                                    placeholder="Item Number"
                                    value={currentProduct.itemNumber}
                                    onChange={onHandleChange}
                                />
                                <Form.Label htmlFor="floatingInputCustom">Item Number</Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={4}>
                            <Form.Floating className="">
                                <Form.Control
                                    type="text"
                                    autoComplete='off'
                                    name="shortCode"
                                    id="floatingInputCustom"
                                    placeholder="Item Number"
                                    value={currentProduct.shortCode}
                                    onChange={onHandleChange}
                                />
                                <Form.Label htmlFor="floatingInputCustom">Short Code</Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={12}>
                            <Form.Floating className="">
                                <Form.Control type="text" autoComplete='off' name="description" id="floatingInputCustom" placeholder="Description" value={currentProduct.description} onChange={onHandleChange} />
                                <Form.Label htmlFor="floatingInputCustom">Description</Form.Label>
                            </Form.Floating>
                        </Col>
                        {/* <Col md={6}>
                            <Form.Floating className="">
                                <Form.Select  aria-label="Floating label select example" name="tags" onChange={(e) => { onHandleChange(e) }}>
                                    <option disabled hidden={!currentProduct ? true : false} selected>Select Type</option> :
                                    <option selected={currentProduct.tags !== undefined ? currentProduct.tags === "VEG" ? true : false : false} value="VEG" >Veg</option> :
                                    <option selected={currentProduct.tags !== undefined ? currentProduct.tags === "NONVEG" ? true : false : false} value="NONVEG">Non-Veg</option> :
                                </Form.Select>
                                <Form.Label htmlFor="floatingInputCustom">Product Type</Form.Label>
                            </Form.Floating>
                        </Col> */}
                        <Col md={12}>
                            <h5 className="fs-6 mb-0 mt-3">Move Product to another category</h5>
                        </Col>
                        <Col md={6}>
                            <Form.Floating controlId="floatingSelect">
                                <Form.Select aria-label="Floating label select example"
                                    name="mainCat"
                                    onChange={onCategorySelect}>
                                    <option>Select Category</option>
                                    {productList && Array.isArray(productList) && productList.map(st => (
                                        <option key={st.id} value={st.id} selected={st.id === mainCat} >
                                            {st.catAlias}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Label htmlFor="floatingInputCustom">Select Category<span className="text-danger">*</span></Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={6}>
                            <Form.Floating controlId="floatingSelect">
                                <Form.Select aria-label="Floating label select example"
                                    name="subCat"
                                    onChange={onSubCategorySelect}>
                                    <option value={null}>Select Sub Category</option>
                                    {subCategories && Array.isArray(subCategories) && subCategories.map(subCat => (
                                        <option key={subCat.id} value={subCat.id} selected={subCat.id === selectedSubCat} >
                                            {subCat.catAlias}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Label htmlFor="floatingInputCustom">Select Sub Category<span className="text-danger">*</span></Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={6}>
                            <Form.Floating className="">
                                <NumberField autoComplete='off' name="displayOrder" id="floatingInputCustom" placeholder="Display Order" value={currentProduct.displayOrder} onChange={onHandleChange} />
                                <Form.Label htmlFor="floatingInputCustom">Display Order</Form.Label>
                            </Form.Floating>
                        </Col>
                        <Col md={6}>
                            <Form.Floating>
                                <Form.Select name="status" id="statusSelect" placeholder="Status" value={currentProduct.status} onChange={onHandleChange}>
                                    <option value="" disabled>Select Status</option>
                                    <option value="ACTIVE" selected={currentProduct.status === "ACTIVE"}>Active</option>
                                    <option value="INACTIVE" selected={currentProduct.status === "INACTIVE"}>Inactive</option>
                                </Form.Select>
                                <Form.Label htmlFor="statusSelect">Status</Form.Label>
                            </Form.Floating>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='border-0 pt-0'>
                    <Row className='g-3 w-100 justify-content-end'>
                        <Col md={3} lg={2} >
                            {!buttonState.save.loading ?
                                <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={handleProductUpdate} disabled={buttonState.save.disable}>Save</Button>
                                : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        size="sm"
                                        animation="border"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>}
                        </Col>
                    </Row>
                </Modal.Footer>
            </>
        </Modal >
    )
}

export default EditProduct;
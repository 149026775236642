import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addStore, updateStore, uploadResource } from "../../../../../actions/admin/AdminActions";
import { validateFields } from "../../../../../util/utility-helper";
import { getAllDeliveryMethods, getPaymentMethods } from "../../../../../actions/billdesk/BillDeskActions";
import BasicAddStorePane from "./widgets/BasicAddStorePane";
import SettingAddStorePane from "./widgets/SettingAddStorePane";

const intialAddStoreState = {
    storeType: "", storeKey: "", name: "", isHQ: "N", locationLat: "", locationLong: "", coverageRadius: "", address: "", location: "",
    pinCode: "", phoneNumber: "", storeDeliveryMethods: "", storePaymentMethods: "", storeLogo: "", aboutStore: "", status: "ACTIVE", storeDNS: "", rootStoreId: null,
    displayProductImages: "N", displayProductDetails: "N", instantSupply: "N",
};

const mandotoryFields = ['storeType', 'name', 'coverageRadius', 'address', 'location', 'pinCode', 'phoneNumber', 'storeDeliveryMethods', 'storePaymentMethods'];

const initalButtonState = { loading: false, disable: true };
let isStoreLogoChange = false;
const AddStoreModal = ({ showModal, hideModal, handleToastManager, storeList, fetchStoreList }) => {
    const dispatch = useDispatch();
    const [addStoreData, setAddStoreData] = useState({ ...intialAddStoreState });
    const [buttonState, setButtonState] = useState({ ...initalButtonState });
    const [deliveryMethods, setDeliveryMethods] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [activeTab, setActiveTab] = useState('Basic');

    const handleTabSelect = (key) => {
        setActiveTab(key);
    };

    useEffect(() => {
        if (showModal) {
            setAddStoreData({ ...intialAddStoreState })
            setButtonState({ ...initalButtonState });
            setActiveTab('Basic');
            getDeliveryMethods();
            fetchPaymentMethods();
        }
    }, [showModal]);

    const getDeliveryMethods = async () => {
        await dispatch(getAllDeliveryMethods())
            .then((response) => {
                if (response.payload) {
                    setDeliveryMethods(response.payload);
                }
            });
    };

    const fetchPaymentMethods = async () => {
        await dispatch(getPaymentMethods()).then((response) => {
            if (response.payload && !response.payload.error) {
                setPaymentMethods(response.payload);
            }
        })
    }

    const onSubmit = async () => {
        setButtonState({ ...buttonState, loading: true });
        await dispatch(addStore({ ...addStoreData }))
            .then(async (response) => {
                if (response.payload && !response.payload.error) {
                    if (isStoreLogoChange) {
                        const formData = new FormData();
                        formData.append('files', addStoreData.logo);
                        formData.append('fileName', response.payload.id + 'store' + "_" + addStoreData.storeKey)
                        await dispatch(uploadResource({ formData: formData, mediaType: "storeLogo" })).then(async (uploadRes) => {
                            if (uploadRes.payload && uploadRes.payload) {
                                await dispatch(updateStore({ ...response.payload, logo: uploadRes.payload.resourcePath }))
                                    .then((updateRes) => {
                                        if (updateRes.payload && !updateRes.payload.error) {
                                            fetchStoreList();
                                            hideModal(true);
                                            handleToastManager({ visible: true, toastMessage: 'Store Updated.', variant: "Success" });
                                        } else {
                                            handleToastManager({ visible: true, toastMessage: updateRes.payload.error, variant: "Danger" });
                                        }
                                        setButtonState({ ...buttonState, loading: false });
                                    })
                            }
                        })
                    } else {
                        fetchStoreList();
                        hideModal();
                        handleToastManager({ visible: true, toastMessage: 'New Store Created.', variant: "Success" });
                    }
                } else {
                    handleToastManager({ visible: true, toastMessage: response.payload.error, variant: "Danger" });
                }
                setButtonState({ ...buttonState, loading: false });
            })
    };

    // const handleChange = (event) => {
    //     const { name, value } = event.target;
    //     setAddStoreData((prevState) => ({
    //         ...prevState,
    //         [name]: value,
    //         storeKey: name === "storeType"
    //             ? value === "ASSEMBLY"
    //                 ? ""
    //                 : prevState.name.substring(0, 3).toUpperCase() // Generate new storeKey if not ASSEMBLY
    //             : prevState.storeKey,
    //     }));

    //     // Determine mandatory fields based on storeType
    //     const currentMandatoryFields = (name === 'storeType' ? value : addStoreData.storeType) === 'ASSEMBLY'
    //         ? mandotoryFields.filter((field) => field !== 'storeDeliveryMethods' && field !== 'storePaymentMethods')
    //         : mandotoryFields;

    //     setButtonState((prevState) => ({
    //         ...prevState,
    //         disable: validateFields(currentMandatoryFields, { ...addStoreData, [name]: value }),
    //     }));
    // };

    const handleChange = (event) => {
        const { name, value } = event.target;
    
        setAddStoreData((prevState) => {
            const isStoreTypeChange = name === "storeType";
            const updatedStoreKey =
                isStoreTypeChange && value === "ASSEMBLY"
                    ? "" // Clear storeKey if ASSEMBLY
                    : name === "storeKey"
                    ? value.toUpperCase // Update storeKey directly if the field is edited
                    : prevState.storeKey;
    
            const newState = {
                ...prevState,
                [name]: value,
                storeKey: updatedStoreKey,
            };
    
            return newState;
        });
    
        // Log the mandatory fields logic
        const currentStoreType = name === "storeType" ? value : addStoreData.storeType;
    
        const currentMandatoryFields =
            currentStoreType === "ASSEMBLY"
                ? mandotoryFields.filter(
                      (field) =>
                          field !== "storeDeliveryMethods" &&
                          field !== "storePaymentMethods"
                  )
                : mandotoryFields;
    
        // Log validation results
        const isButtonDisabled = validateFields(currentMandatoryFields, {
            ...addStoreData,
            [name]: value,
        });
        
        // Update button state
        setButtonState((prevState) => ({
            ...prevState,
            disable: isButtonDisabled,
        }));
    };    

    const handleMethodChange = (event) => {
        const { name, value } = event.target;
        let newStoreData = { ...addStoreData };
        if (String(newStoreData[name]).includes(value)) {
            newStoreData[name] = String(newStoreData[name]).replace(String(newStoreData[name]).includes('-' + value) ? '-' + value : value, '')
        } else {
            if (newStoreData[name] !== '') {
                newStoreData[name] = String(newStoreData[name]).concat('-' + value);
            } else {
                newStoreData[name] = value;
            }
        }
        setAddStoreData({ ...newStoreData });
        setButtonState({ ...buttonState, disable: validateFields(mandotoryFields, { ...newStoreData }) });
    };

    const generateStoreKey = () => {
        setAddStoreData({ ...addStoreData, storeKey: addStoreData.storeType !== 'ASSEMBLY' ? String(addStoreData.name).substring(0, 3).toUpperCase() : null })
    };

    const handleStoreLogo = (event, action) => {
        if (action === 'CHANGE') {
            const file = event.target.files[0];
            if (file) {
                isStoreLogoChange = true;
                setAddStoreData({ ...addStoreData, storeLogo: URL.createObjectURL(file), logo: file });
            }
        } else {
            setAddStoreData({ ...addStoreData, storeLogo: null, logo: null });
        }
        setButtonState({ ...buttonState, disable: false })
    };

    return (
        <Modal size="lg" centered backdrop="static" keyboard={false} show={showModal} onHide={hideModal}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Add Store</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3" >
                    <Col md={12}>
                        <Nav className="card-tabs" activeKey={activeTab} onSelect={handleTabSelect}>
                            <Nav.Item>
                                <Nav.Link eventKey="Basic" >Basic</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="Setting" disabled={addStoreData.storeType === ''}>Setting</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    {activeTab === 'Basic' && (
                        <BasicAddStorePane addStoreData={addStoreData} handleChange={handleChange} onStoreNameChange={generateStoreKey} handleStoreLogo={handleStoreLogo} />
                    )}
                    {activeTab === 'Setting' && (
                        <SettingAddStorePane deliveryMethods={deliveryMethods} paymentMethods={paymentMethods} addStoreData={addStoreData} storeList={storeList} handleChange={handleChange} handleMethodChange={handleMethodChange} />
                    )}
                </Row>
            </Modal.Body>
            <Modal.Footer className="border-0 pt-0">
                <Row className="g-3 w-100 justify-content-end">
                    <Col md={3} lg={2} className="m-0">
                        {!buttonState.loading ?
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={onSubmit} disabled={buttonState.disable}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>}
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    )
}

export default AddStoreModal;
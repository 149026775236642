import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { HEADERS } from "../../config/appHeaders";

const apiEndPoint = process.env.REACT_APP_API_BASE_ENDPOINT;

export const createNewMember = createAsyncThunk(
    'createNewMember',
    async (payload) => {
        const response = await axios.post(`${apiEndPoint}/business/store/user`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const deleteMember = createAsyncThunk(
    'deleteMember',
    async (payload) => {
        const response = await axios.delete(`${apiEndPoint}/business/store/user/${payload.id}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const deleteTerminal = createAsyncThunk(
    'deleteTerminal',
    async (payload) => {
        const response = await axios.delete(`${apiEndPoint}/business/store/terminal/${payload.id}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const updateUserData = createAsyncThunk(
    'updateUserData',
    async (payload) => {
        const response = await axios.put(`${apiEndPoint}/business/store/user/${payload.id}`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const fetchTerminalData = createAsyncThunk(
    'fetchTerminalData',
    async (storeId) => {
        const response = await axios.get(`${apiEndPoint}/business/store/terminal/${storeId}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const fetchAllTerminals = createAsyncThunk(
    'businessTerminals',
    async () => {
        const response = await axios.get(`${apiEndPoint}/business/terminals`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)


export const fetchUserData = createAsyncThunk(
    'fetchUserData',
    async () => {
        const response = await axios.get(`${apiEndPoint}/business/store/users`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const getUserById = createAsyncThunk(
    'userById',
    async (userId) => {
        const response = await axios.get(`${apiEndPoint}/business/store/user/${userId}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const createNewTerminal = createAsyncThunk(
    'createNewTerminal',
    async (payload) => {
        const response = await axios.post(`${apiEndPoint}/business/store/terminal`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)
export const updateTerminalData = createAsyncThunk(
    'updateTerminalData',
    async (payload) => {
        const response = await axios.put(`${apiEndPoint}/business/store/terminal/${payload.id}`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const updateUserPassword = createAsyncThunk(
    'updateUserPassword',
    async (payload) => {
        const response = await axios.put(`${apiEndPoint}/business/store/user/${payload.id}`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const checkEcomConnection = createAsyncThunk(
    'onlineStatus',
    async () => {
        const response = await axios.get(`${apiEndPoint}/operation/e-store/status`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const getCustomerVerificatonList = createAsyncThunk(
    'CustomerVerificaton',
    async (storeId) => {
        const response = await axios.get(`${apiEndPoint}/business/store/${storeId}/customer-otps`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const getConfigureProductsList = createAsyncThunk(
    'configureProductsList',
    async (prodId) => {
        const response = await axios.get(`${apiEndPoint}/business/product-assembly/${prodId}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const createProductAssembly = createAsyncThunk(
    'createProductAssembly',
    async (payload) => {
        const response = await axios.post(`${apiEndPoint}/business/product-assembly`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const updateProductAssembly = createAsyncThunk(
    'updateProductAssembly',
    async (payload) => {
        const { productId, body } = payload;
        const response = await axios.put(`${apiEndPoint}/business/product-assembly/${productId}`, body, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const getStoreMediaByStoreId = createAsyncThunk(
    'storeMediaByStoreId',
    async (storeId) => {
        const response = await axios.get(`${apiEndPoint}/business/store/${storeId}/media`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const updateMedia = createAsyncThunk(
    'updateMedia',
    async (payload) => {
        const { storeId, endPath, mediaType } = payload
        const response = await axios.put(`${apiEndPoint}/business/store/${storeId}/media/${mediaType}/${endPath}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const deleteMedia = createAsyncThunk(
    'deleteMedia',
    async (payload) => {
        const { storeId, endPath, mediaType } = payload
        const response = await axios.delete(`${apiEndPoint}/business/store/${storeId}/media/${mediaType}/${endPath}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const addStore = createAsyncThunk(
    'addStore',
    async (payload) => {
        const response = await axios.post(`${apiEndPoint}/business/store`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const updateStore = createAsyncThunk(
    'updateStore',
    async (payload) => {
        const response = await axios.put(`${apiEndPoint}/business/store/${payload.id}`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)

export const deleteStore = createAsyncThunk(
    'deleteStore',
    async (payload) => {
        const response = await axios.delete(`${apiEndPoint}/business/store/${payload.id}`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)


export const activateStore = createAsyncThunk(
    'activateStore',
    async (payload) => {
        const response = await axios.put(`${apiEndPoint}/business/store/${payload.id}/activate`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)
export const uploadResource = createAsyncThunk(
    'uploadResource',
    async (payload) => {
        const { formData, mediaType } = payload;
        const response = await axios.post(`${apiEndPoint}/operation/resource/upload/${mediaType}`, formData, {
            headers: HEADERS.FILE(),
        })
        return response.data;
    }
)

export const getStoresByDomainName = createAsyncThunk(
    'storesByDomainName',
    async (payload) => {
        const response = await axios.get(`${apiEndPoint}/business/stores/${payload}`, {
            // headers: HEADERS.FILE(),
        })
        return response.data;
    }
)

export const switchStoreAndTerminal = createAsyncThunk(
    'switchStoreAndTerminal',
    async (payload) => {
        const response = await axios.post(`${apiEndPoint}/security/switchStoreOrTerminal`, payload, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
);

export const getLoginBusiness = createAsyncThunk(
    'getLoginBusiness',
    async () => {
        const response = await axios.get(`${apiEndPoint}/business`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
);



export const getUserStoresAnTerminals = createAsyncThunk(
    'userStoresAnTerminals',
    async () => {
        const response = await axios.get(`${apiEndPoint}/business/getUserStoresAndTerminals`, {
            headers: HEADERS.AUTHENTIC(),
        })
        return response.data;
    }
)
import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col, Spinner, Image, Card } from "react-bootstrap"
import { useDispatch } from "react-redux";
import scannerImg from '../../../../assets/images/QR_Code.jpg';

let isDataChangeFlag = false;
const AuditScannerModal = ({ ...props }) => {
    const dispatch = useDispatch();

    const [loaderState, setLoaderState] = useState({ updateState: false, });
    const [assemblyProduct, setAssemblyProduct] = useState({});
    const [initialProduct, setInitialProduct] = useState({});

    useEffect(() => {
    }, []);



    const updateProductAssemblyDetails = async () => {
    };

    const isDataChanged = () => {
    };

    return (
        <Modal size="md" centered backdrop="static" keyboard={false} show={props.showModal} onHide={() => props.hideModal(isDataChangeFlag)}>
            <Modal.Header className='border-0 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-bold'>Audit Scanner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col md={12} className="text-center">
                        <Card className="border shadow-0 overflow-hidden d-inline-block" style={{ borderRadius: '10px' }}>
                            <Card.Body className="p-0">
                                <Image width='200px' height='200px' className="object-fit-cover" src={scannerImg} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Modal.Body>
            {/* <Modal.Footer className='border-0 pt-0'>
                <Row className='g-3 w-100 justify-content-end'>
                    <Col md={3} lg={3}>
                        {loaderState.updateState === false ?
                            <Button className='light-btn w-100 bg-light-grey ' variant="primary" name="Save" onClick={updateProductAssemblyDetails} disabled={!isDataChanged()}>Save</Button>
                            : <Button className='light-btn w-100 bg-light-grey ' variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>}
                    </Col>

                </Row>
            </Modal.Footer> */}
        </Modal >
    )
}

export default AuditScannerModal;